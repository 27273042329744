import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Toolkit,
  ToolkitCreateInput,
  ToolkitFilters,
  ToolkitIncludes,
  ToolkitSorts,
  ToolkitUpdateInput,
} from '../Types/Resources/Toolkit';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IToolkitProps
  extends HookParams<
    typeof ToolkitIncludes,
    typeof ToolkitFilters,
    typeof ToolkitSorts
  > {
  toolkit?: UUID;
}

export function useToolkit(
  { toolkit, ...queryParameters }: IToolkitProps = {},
  settings?: IUseQueryOptions<DataDocument<Toolkit>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(toolkit || defaultToolkit),
      invalidate: ['toolkits', 'toolkit'],
      baseName: 'v1.toolkits',
      baseUri: '/v1/toolkits',
      uri: `/${toolkit || defaultToolkit}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Toolkit, ToolkitCreateInput, ToolkitUpdateInput>(
    request,
    settings
  );
}

export function useToolkits(
  { ...queryParameters }: Omit<IToolkitProps, 'toolkit'> = {},
  settings?: IUseQueryOptions<DataDocument<Toolkit[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.toolkits',
      baseUri: '/v1/toolkits',
      invalidate: ['toolkits', 'toolkit'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Toolkit, ToolkitCreateInput>(request, settings);
}
