import { UseMutationOptions } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { ContentFeedbackCreateInput } from '../Types/Resources/ContentFeedback';

type Settings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  ContentFeedbackCreateInput
>;

export function useCreateContentFeedback(settings?: Settings) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.contentFeedback',
      baseUri: '/v1/content_feedback',
      invalidate: ['content_feedback'],
    },
    context
  );
  return useCreate<undefined, ContentFeedbackCreateInput>(request, settings);
}
