import React, { useMemo } from 'react';
import { useCourseEventCalendars } from '@brainstud/academy-api/Hooks/useCourseEventCalendars';
import { EventCalendarItem } from '@brainstud/academy-api/Types/Resources/EventCalendarItem';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { Callout } from '@brainstud/ui/Static/Callout';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { Content, withBase } from '../../Layouts';
import { EventsTable } from './EventsTable';
import styles from './EventsView.module.css';

const cx = classNames.bind(styles);

export const EventsView = withBase(() => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  const [{ data: calendars }, { isLoading }] = useCourseEventCalendars({
    course: courseId,
  });

  const tableItems: EventCalendarItem[] = useMemo(
    () =>
      calendars?.flatMap((calendar) => calendar.items?.()).filter(isPresent),
    [calendars]
  );
  const futureItems = useMemo(
    () =>
      tableItems
        .filter((item) => new Date(item?.endDate) > new Date())
        .sort((a, b) => (a?.startDate > b?.startDate ? 1 : -1)),
    [tableItems]
  );
  const historyItems = useMemo(
    () =>
      tableItems
        .filter((item) => new Date(item?.endDate) <= new Date())
        .sort((a, b) => (a?.startDate > b?.startDate ? 1 : -1)),
    [tableItems]
  );

  if (isLoading) return <Loading />;

  if (!futureItems.length && !historyItems.length) {
    return (
      <Container>
        <Callout info style={{ margin: '4rem 0' }}>
          {t('views.event.no_events.at_all')}
        </Callout>
      </Container>
    );
  }

  return (
    <Container>
      <Content className={cx('base')}>
        <h2>{t('views.event.title.comming_events')}</h2>
        {futureItems.length ? (
          <EventsTable calendar={calendars[0]} events={futureItems} />
        ) : (
          <Callout info style={{ margin: '4rem 0' }}>
            {t('views.event.no_events.comming')}
          </Callout>
        )}
        {historyItems.length ? (
          <Accordion
            heading
            quiet
            title={t('views.event.title.finished_events')}
            style={{ margin: '4rem 0' }}
          >
            <EventsTable calendar={calendars[0]} events={historyItems} />
          </Accordion>
        ) : (
          <Callout info style={{ margin: '4rem 0' }}>
            {t('views.event.no_events.finished')}
          </Callout>
        )}
      </Content>
    </Container>
  );
});
