import React from 'react';
import { Link } from 'Components/Link';
import { useProgressCalculator } from 'Hooks/ProgressCalculator/useProgressCalculator';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import {
  ILearningObjectWithResources,
  ILearningSubjectWithResources,
} from 'Providers/LearningRouteProvider/LearningRouteContext';
import { LearningCard, TProgressState } from '../LearningCard';
import { ReactComponent as FallbackImage } from './fallback-image.svg';
import { SubjectCardSubHeader } from './Subheader';
import styles from './SubjectCard.module.css';

export type SubjectCardProps = {
  subject: ILearningSubjectWithResources;
  parentHasCondition?: boolean;
};

const filterLearningObjects = (
  object: ILearningSubjectWithResources | ILearningObjectWithResources
): object is ILearningObjectWithResources =>
  object.resourceType === 'learning_objects';

const filterImage = (image: string | undefined): image is string => !!image;

/**
 * SubjectCard.
 *
 * Shows a card for Learning Subject data
 */
export const SubjectCard = ({
  subject,
  parentHasCondition,
}: SubjectCardProps) => {
  const { id, title, poster, thumbnail } = subject;
  const router = useRouter();
  const { learningRouteId, courseId } = router.query as {
    learningRouteId: string;
    courseId: string;
  };
  const learningObjects = subject.scheme.filter(filterLearningObjects);
  const images = learningObjects
    .map(
      (learningObject) =>
        learningObject.metadata?.media?.posterUrl || learningObject.poster?.src
    )
    .filter(filterImage);
  const { madeObjects, totalObjects } = useProgressCalculator(subject);
  const { isStudentEnvironment, environment } = useEnvironmentProvider();
  const status: TProgressState = {
    state: parentHasCondition ? 'LOCKED' : 'NONE',
    label: parentHasCondition ? 'components.card_object.locked_on' : '',
  };

  const subjectUrl =
    {
      coach: router.asPath.replace(
        `/${learningRouteId}`,
        `/content/subjects/${id}`
      ),
      admin: router.asPath.replace('/edit', `/subjects/${id}`),
    }[environment as 'coach' | 'admin'] ||
    `/courses/${courseId}/subjects/${id}`;

  return (
    <Link
      href={subjectUrl}
      if={!parentHasCondition && isStudentEnvironment}
      className={styles.base}
    >
      <LearningCard
        status={status}
        title={title}
        posterUrl={thumbnail?.src || poster?.src}
        fallbackImage={<FallbackImage />}
        subheader={
          <SubjectCardSubHeader
            imageUrls={images}
            madeObjects={madeObjects}
            totalObjects={totalObjects}
          />
        }
      />
    </Link>
  );
};
