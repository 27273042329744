import React, { CSSProperties } from 'react';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';

type Props = {
  className?: string;
  style?: CSSProperties;
};

/**
 * VarietySelector.
 *
 * With this component you can select a different variety of a LearningObject.
 * LearningObjectProvider is required to make this component work as it should.
 */
export const VarietySelector = ({ className, style }: Props) => {
  const { variety, varieties, dispatch } = useLearningObjectProvider();
  const handleVarietyChange = (varietyId?: string) => {
    if (varietyId) {
      dispatch({
        type: 'SET_VARIETY',
        payload: {
          id: varietyId,
        },
      });
    }
  };

  return (
    <div>
      <Dropdown
        style={style}
        className={className}
        value={variety?.id}
        disabled={false}
        onChange={(value) => handleVarietyChange(value)}
      >
        {varieties?.map((item, idx) => (
          <Dropdown.Option disabled={false} key={item.id} value={item.id}>
            {`Variant ${idx + 1}`}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};
