import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLearningSubject } from '@brainstud/academy-api/Hooks/useLearningSubjects';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { SubjectEditorContext } from './SubjectEditorContext';

type ProviderProps = {
  learningSubject: ILearningSubjectWithResources;
};

/**
 * Provides method for saving the scheme of a learning subject
 */
export const SubjectEditorProvider: FC<PropsWithChildren<ProviderProps>> = ({
  learningSubject,
  children,
}) => {
  const [preview, setPreview] = useState<boolean>(false);
  const [{ update }] = useLearningSubject(
    {
      learningSubject: learningSubject.id,
    },
    { enabled: false }
  );

  const [nestedItems, setNestedItems] = useState(learningSubject.scheme);
  useEffect(() => {
    setNestedItems(learningSubject.scheme);
  }, [learningSubject]);

  const handleSave = useCallback(() => {
    update.mutateAsync({
      _method: 'PATCH',
      scheme: nestedItems.map((nestedItem) => ({
        id: nestedItem.id,
        type: nestedItem.resourceType,
      })),
    });
  }, [nestedItems, update]);

  const isLoadingUpdate = update.isPending;

  const context = useMemo(
    () => ({
      learningSubject,
      nestedItems,
      isLoading: isLoadingUpdate,
      setNestedItems,
      handleSave,
      preview,
      setPreview,
    }),
    [learningSubject, nestedItems, isLoadingUpdate, handleSave, preview]
  );

  return (
    <SubjectEditorContext.Provider value={context}>
      {children}
    </SubjectEditorContext.Provider>
  );
};
