import React, { useCallback } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useLearningRouteNode } from '@brainstud/academy-api/Hooks/useLearningRouteNodes';
import { DuplicateModal } from '@brainstud/coach-panel/Modals/DuplicateModal';
import { ActionMenu } from '@brainstud/ui/Buttons/ActionMenu';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { Callout } from '@brainstud/ui/Static/Callout';
import {
  Add,
  DeleteForever,
  DragIndicator,
  Edit,
  Lock,
  Settings,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { AUTHOR_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { ILearningRouteEditorContext } from 'Modules/course-editor/LearningRouteEditor/Provider';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ContentSelectionModal } from 'Views/Courses/CollectionEditView/LearningRouteEditorView/ContentSelectionModal';
import { LearningRouteNodeModal } from 'Views/Courses/CollectionEditView/LearningRouteEditorView/LearningRouteNodeModal';
import { useLearningRouteEditorProvider } from '../../../../LearningRouteEditor/Provider/useLearningRouteEditorProvider';
import { isObject } from '../../../../Support/is';
import { AssignmentConditionsModal } from '../../../AssignmentConditionsModal';
import { LearningObjectEditRow } from '../LearningObject/LearningObjectEditRow';
import { LearningSubjectEditRow } from '../LearningSubject/LearningSubjectEditRow';
import styles from './DraggableGroup.module.css';

const cx = classNames.bind(styles);

interface IGroupProps {
  item: ILearningRouteEditorContext['nodes'][0];
  index: number;
}

/**
 * Renders a draggable `subject` or `group`, which might render its children using components `LearningObject` or another `Group`
 */
export const DraggableGroup = ({ item, index }: IGroupProps) => {
  const { openModal, closeModal } = useModals();
  const [t] = useTranslator();

  const { nodes, isLoading } = useLearningRouteEditorProvider();
  const { isAdminEnvironment } = useEnvironmentProvider();
  const {
    learningRoute,
    isCustomCollection,
    layout: routeLayout,
  } = useLearningRouteProvider();
  const handleEditChapter = useCallback(
    (node: ILearningRouteEditorContext['nodes'][0]) => {
      openModal(LearningRouteNodeModal, { learningRoute, node });
    },
    [learningRoute, openModal]
  );

  const router = useRouter();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };

  const [{ destroy }] = useLearningRouteNode(
    {
      learningRoute: learningRoute?.id,
      node: item.id,
    },
    { enabled: false }
  );

  const handleDelete = useCallback(async () => {
    openModal(ConfirmationModal, {
      question: t(
        'course-editor.learning_route_editor.confirmation.question_delete'
      ),
      yes: t('course-editor.learning_route_editor.confirmation.confirm_delete'),
      no: t('course-editor.learning_route_editor.confirmation.cancel'),
      handleConfirm: async () => {
        destroy.mutateAsync();
        closeModal();
      },
    });
  }, [openModal, destroy, closeModal, t]);

  const hasCondition = item?.conditions?.();
  const isAuthor = useHasAnyRole(AUTHOR_ROLES);

  return destroy.isPending || isLoading ? (
    <Loading />
  ) : (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={cx(
            styles.base,
            snapshot.isDragging ? styles.dragging : undefined
          )}
          ref={provided.innerRef}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...provided.draggableProps}
        >
          <div className={cx(styles.heading)}>
            <h2>{item.title}</h2>
            <Lock className={cx(!hasCondition && styles['hide-lock'])} />
            <ActionMenu
              id={`action-menu-${index}`}
              icon={<Settings />}
              className={cx(styles['action-menu'])}
            >
              <button
                role="menuitem"
                type="button"
                onClick={() =>
                  openModal(AssignmentConditionsModal, {
                    baseObject: item,
                    nodes,
                    learningRouteId: learningRoute?.id,
                  })
                }
              >
                <Lock />
                <span>
                  {t(
                    `course-editor.learning_route_editor.action_menu.assignment_conditions.${hasCondition ? 'edit' : 'add'}`
                  )}
                </span>
              </button>
              <button
                role="menuitem"
                type="button"
                onClick={() =>
                  isCustomCollection || isAdminEnvironment
                    ? handleEditChapter(item)
                    : openModal(DuplicateModal, { collectionId, courseId })
                }
              >
                <Edit />
                <span>
                  {t('course-editor.learning_route_editor.chapter.edit')}
                </span>
              </button>
              {item.scheme.length === 0 && (isCustomCollection || isAuthor) && (
                <button
                  role="menuitem"
                  type="button"
                  className={cx(styles.destroy)}
                  onClick={handleDelete}
                >
                  <DeleteForever />
                  <span>
                    {t('course-editor.learning_route_editor.chapter.delete')}
                  </span>
                </button>
              )}
            </ActionMenu>
            <span
              className="phxIcon phxIcon-drag-row mx-2"
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...provided.dragHandleProps}
            >
              <DragIndicator />
            </span>
          </div>

          {item?.introduction &&
            typeof item?.introduction !== 'string' &&
            item.introduction.title !== null &&
            item.introduction.content !== null && (
              <Callout icon={false} className={styles.introduction}>
                <h4>
                  {item.introduction.title ||
                    t('components.card_introduction.default_title')}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(item.introduction.content),
                  }}
                />
              </Callout>
            )}
          <Droppable droppableId={item.id} type="droppableGroup">
            {(providedProps) => (
              <div
                ref={providedProps.innerRef}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...providedProps.droppableProps}
              >
                {item.scheme.map((child, idx) =>
                  isObject(child) ? (
                    <LearningObjectEditRow
                      item={child}
                      index={idx}
                      key={child.id}
                    />
                  ) : (
                    <LearningSubjectEditRow
                      key={child.id}
                      item={child}
                      index={idx}
                    />
                  )
                )}
                {providedProps.placeholder}
              </div>
            )}
          </Droppable>
          {(isCustomCollection || isAdminEnvironment) && (
            <div className={styles.addNewContent}>
              <Button
                type="button"
                quiet
                onClick={() =>
                  openModal(ContentSelectionModal, {
                    nodeId: item.id,
                    collectionId,
                    learningRouteId: learningRoute?.id,
                    routeLayout,
                  })
                }
              >
                <Add className={styles.addNewContentIcon} />
                <span>{t('course-editor.learning_route_editor.add_item')}</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};
