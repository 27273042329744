import React from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { TFeedbackFormProps } from './Types';
import styles from './FeedbackTypes.module.css';

const cx = classNames.bind(styles);

export const FivePointScale = ({ title, onAnswer }: TFeedbackFormProps) => {
  const [t] = useTranslator();
  return (
    <div className={cx('smiley')}>
      <p className={cx('title')}>{title}</p>
      <div className={cx('buttons-list', 'five-point-scale')}>
        <span>{t('components.feedback.extremely_negative')}</span>
        <Button link onClick={() => onAnswer(1)}>
          1
        </Button>
        <span>-</span>
        <Button link onClick={() => onAnswer(2)}>
          2
        </Button>
        <span>-</span>
        <Button link onClick={() => onAnswer(3)}>
          3
        </Button>
        <span>-</span>
        <Button link onClick={() => onAnswer(4)}>
          4
        </Button>
        <span>-</span>
        <Button link onClick={() => onAnswer(5)}>
          5
        </Button>
        <span>{t('components.feedback.extremely_positive')}</span>
      </div>
    </div>
  );
};
