import React, { ComponentProps, ReactNode } from 'react';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import classNames from 'classnames/bind';
import styles from './AccordionTitle.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  children: ReactNode;
};

export const AccordionTitle = ({
  children,
  className,
  ...props
}: Props & ComponentProps<typeof Accordion>) => (
  <>
    {props.title !== null ? (
      <Accordion
        quiet
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={cx(styles.base, className)}
      >
        {children}
      </Accordion>
    ) : (
      children
    )}
  </>
);
