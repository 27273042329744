import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  NfcTag,
  NfcTagCreate,
  NfcTagFilters,
  NfcTagIncludes,
  NfcTagSorts,
  NfcTagUpdate,
} from '../Types/Resources/NfcTag';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface INfcTagsProps
  extends HookParams<
    typeof NfcTagIncludes,
    typeof NfcTagFilters,
    typeof NfcTagSorts
  > {
  nfcTag?: UUID;
}

export function useNfcTag(
  { nfcTag, ...queryParameters }: INfcTagsProps = {},
  settings?: IUseQueryOptions<DataDocument<NfcTag>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { nfcTag: defaultNfcTag },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(nfcTag || defaultNfcTag),
      baseName: 'v1.nfcTags',
      baseUri: '/v1/nfc_tags',
      uri: `/${nfcTag || defaultNfcTag}`,
      invalidate: ['nfc_tag', 'nfc_tags'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<NfcTag, NfcTagCreate, NfcTagUpdate>(request, settings);
}

export function useNfcTags(
  { ...queryParameters }: Omit<INfcTagsProps, 'nfcTag'> = {},
  settings?: IUseQueryOptions<DataDocument<NfcTag[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.nfcTags',
      baseUri: '/v1/nfc_tags',
      invalidate: ['nfc_tag', 'nfc_tags'],
      queryParameters,
    },
    context
  );
  return useIndexCR<NfcTag, NfcTagCreate>(request, settings);
}
