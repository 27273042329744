import React from 'react';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, Th, Tr } from '@brainstud/ui/Static/Table';
import classNames from 'classnames/bind';
import { ILearningRouteNodeWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { CardIntroduction } from '../../../../Components/Cards/CardIntroduction';
import { TableViewRow } from '../../../../Components/TableViewRow/TableViewRow';
import styles from './TableView.module.css';

const cx = classNames.bind(styles);

type Props = {
  items: Array<ILearningRouteNodeWithResources>;
  searchTerm?: string;
};

export const TableView = ({ items = [], searchTerm }: Props) => {
  const [t] = useTranslator();

  if (items.length === 0) {
    return (
      <div
        className={cx('no-data-row')}
        dangerouslySetInnerHTML={{
          __html: sanitizer(t('views.collection.no_content')),
        }}
      />
    );
  }

  return (
    <>
      {items.map((item) =>
        searchTerm && item.scheme.length === 0 ? null : (
          <Accordion
            key={item.title}
            title={
              <h3
                className={cx(styles.title)}
              >{`${item.title} (${item.scheme.length})`}</h3>
            }
            className={cx(styles.base, 'flex-accordion')}
            open
            quiet
            heading
          >
            {item?.introduction && (
              <CardIntroduction introduction={item.introduction} />
            )}

            {item.scheme.length > 0 ? (
              <Panel>
                <Table className={cx(styles.table)}>
                  <Table.THead>
                    <Tr>
                      <Th>{t('name')}</Th>
                      <Th />
                      <Th>{t('status')}</Th>
                    </Tr>
                  </Table.THead>
                  <Table.TBody>
                    {item.scheme.map((assignment) => (
                      <TableViewRow key={assignment.id} object={assignment} />
                    ))}
                  </Table.TBody>
                </Table>
              </Panel>
            ) : (
              <Callout
                dangerouslySetInnerHTML={{
                  __html: sanitizer(t('views.collection.empty_chapter')),
                }}
              />
            )}
          </Accordion>
        )
      )}
    </>
  );
};
