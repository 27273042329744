import { IActivity } from '@brainstud/academy-api/Hooks/useEnrollmentLearningObjectActivity';
import { Answer } from '@brainstud/academy-api/Types/Resources/Answer';
import { AnswerFeedback } from '@brainstud/academy-api/Types/Resources/AnswerFeedback';
import { Comment } from '@brainstud/academy-api/Types/Resources/Comment';
import { Shareable } from '@brainstud/academy-api/Types/Resources/Shareable';
import { toCamelCase } from '@brainstud/academy-api/Utils/transformCase';

type TActivityObject = { [date: string]: TActivity[] };

type TActivity =
  | (Omit<Comment, 'resourceType'> & { resourceType: 'comments' })
  | (AnswerFeedback & { resourceType: 'answer_feedback' })
  | (Answer & { resourceType: 'answers' })
  | (Shareable & { resourceType: 'shareables' });

/**
 * The backend provides the activities timeline sorted on created at date.
 * We create a data structure to group the activities more easily with dates as keys.
 * This goes against the JSON API standards and thus is not implemented in the backend.
 */
export function groupByCreatedAt(activities?: IActivity[]): TActivityObject {
  const groupedComments: TActivityObject = {};

  if (!activities?.length) return groupedComments;

  const resourceIds: string[] = [];

  return activities.reduce((output, activity) => {
    const resource = activity[toCamelCase(activity.type)]?.();
    if (!resource) return output;

    if (!output[activity.activityAt]) output[activity.activityAt] = [];

    if (!resourceIds.includes(resource.id)) {
      output[activity.activityAt] = [...output[activity.activityAt], resource];
      resourceIds.push(resource.id);
    }

    return output;
  }, {});
}
