import { useCallback, useMemo } from 'react';
import { getAccounts } from '@brainstud/academy-api/Hooks/useAccounts';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import {
  AutocompleteInput,
  AutocompleteInputProps,
} from '@brainstud/ui/Form Input/AutocompleteInput';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './UserSearch.module.css';

const cx = classNames.bind(styles);

type TProps = AutocompleteInputProps & {
  placeholder: string;
  label?: string;
};

export const UserSearch = ({
  onChange,
  placeholder,
  label,
  className,
  name = 'account',
  ...restProps
}: TProps) => {
  const [me] = useMe();
  const [t] = useTranslator();
  const memoizedLabel = useMemo(
    () => label || t('components.user_search.label'),
    [label, t]
  );

  const handleSearch = useCallback(
    async (value: string) => {
      const { data } = await getAccounts({
        filter: {
          full_name: value,
          entity: me?.entity?.()?.id,
        },
      });

      return data.reduce(
        (list, item) => ({
          ...list,
          [item.id]: item.fullName,
        }),
        {}
      );
    },
    [me]
  );

  return (
    <AutocompleteInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      label={memoizedLabel}
      name={name}
      data={handleSearch}
      placeholder={placeholder}
      className={cx(styles.base, className)}
      onChange={onChange}
    />
  );
};
