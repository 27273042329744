import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLearningObject } from '@brainstud/academy-api/Hooks/useLearningObjects';
import { useLearningObjectVariety } from '@brainstud/academy-api/Hooks/useLearningObjectVarieties';
import { useObjectCondition } from '@brainstud/academy-api/Hooks/useObjectCondition';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import ErrorDocument from '@brainstud/academy-api/Support/Documents/ErrorDocument/ErrorDocument';
import { NfcTag } from '@brainstud/academy-api/Types/Resources/NfcTag';
import { toSnakeCase } from '@brainstud/academy-api/Utils/transformCase';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Checkbox } from '@brainstud/ui/Form Input/Checkbox';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { BADGES, OBJECT_META_TAG_ICONS } from 'Hooks/useObjectTags';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { normalizeLearningObjectMetadata } from 'Utils/normalizeLearningObjectMetadata';
import { sanitizer } from 'Utils/Sanitizer';
import { useActualObjectLayoutName } from 'Views/CollectionViews/ObjectViews/useActualObjectLayoutName';
import { LayoutSelector } from 'Views/Courses/CollectionEditView/LayoutSelector';
import { FormData } from '../../LearningObjectFormTypes';
import { NfcForm } from '../../NfcForm';
import { PreviewLearningObject } from '../../Previews';
import { TAssignmentModalData } from '../Types';
import styles from './AssignmentDetails.module.css';

const cx = classNames.bind(styles);

type Props = {
  setIsLoading: (value: boolean) => void;
};

export const AssignmentDetails = ({ setIsLoading }: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const apiConfig = useApi();
  const [setToast] = useToaster();
  const { learningObject, variety } = useLearningObjectProvider();
  const { modalData } = useModals();

  const { routeLayout, learningRouteId, nodeId, subjectId, isQuiz } =
    modalData as TAssignmentModalData;

  const { collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const [newLearningObjectId, setNewLearningObjectId] = useState<string>();
  const nfcTags = useMemo(
    () => learningObject?.nfcTags?.() || [],
    [learningObject]
  );
  const [createdNfcTag, setCreatedNfcTag] = useState<undefined | NfcTag>();
  // Select first nfcTag FIXME add multi nfc tag support
  const nfcTag = useMemo(
    () => createdNfcTag || nfcTags[0],
    [createdNfcTag, nfcTags]
  );
  const [nfcTagEnabled, setNfcTagEnabled] = useState<boolean>(!!nfcTag);
  const [learningObjectTitle, setLearningObjectTitle] = useState(
    learningObject?.title
  );
  const [fileUrl, setFileUrl] = useState(
    learningObject?.metadata?.media?.posterUrl
  );
  const [points, setPoints] = useState(variety?.points || 0);
  const isPanel =
    useActualObjectLayoutName(learningObject?.layout, routeLayout) === 'panel';
  const [objectLayout, setObjectLayout] = useState(
    isPanel && learningObject ? 'panel' : 'open'
  );
  const learningObjectId = learningObject?.id || newLearningObjectId;

  const snakeToCamel = (s: string) =>
    s.replace(/(_\w)/g, (k) => k[1].toUpperCase());
  const metadata = normalizeLearningObjectMetadata(learningObject);
  const titleInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (learningObject?.layout) {
      setObjectLayout(learningObject.layout);
    } else if (!isPanel) {
      setObjectLayout(routeLayout === 'chapter-cards' ? 'linear' : 'open');
    }
  }, [isPanel, learningObject?.layout, routeLayout]);

  const objectConditionId =
    learningObject?.resourceType === 'learning_objects' &&
    learningObject
      ?.conditions?.()
      .find((condition) => condition.resourceType !== 'object_condition')?.id;

  const [{ createOrUpdate: createOrUpdateLearningObject }] = useLearningObject(
    {
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  useEffect(() => {
    setIsLoading(createOrUpdateLearningObject.isPending);
  }, [createOrUpdateLearningObject.isPending, setIsLoading]);

  const [{ createOrUpdate: createOrUpdateNfcCondition }] = useObjectCondition(
    {
      objectType: 'learning_objects',
      objectIdentifier: learningObjectId,
      ...(objectConditionId && { condition: objectConditionId }),
    },
    { enabled: false }
  );
  // Create or update NFC condition
  const handleNfcCondition = useCallback(
    async () =>
      createOrUpdateNfcCondition.mutateAsync({
        target: nfcTag?.id,
        learning_route: learningRouteId,
        type: 'NFC_TAG_SCANNED',
      }),
    [createOrUpdateNfcCondition, learningRouteId, nfcTag?.id]
  );

  const [{ update: updateLearningObjectVariety }] = useLearningObjectVariety(
    {
      variety: variety?.id,
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  // Create or update a LearningObject
  const handleSubmitLearningObject = useCallback(
    async (formData: Partial<FormData>) => {
      // TODO make same as the SubjectModal
      const hasRelationships =
        ((!!nodeId || !!subjectId) && !!collectionId) || !!nfcTag?.id;
      // @ts-ignore FIXME relationships are not assignable to type
      await createOrUpdateLearningObject.mutateAsync(
        {
          base_type: 'assignment',
          title: formData.title,
          ...(!learningObject
            ? {
                status: 'CONCEPT',
              }
            : {
                method: '_patch',
                status: learningObject.status,
              }),
          layout: isQuiz ? 'question' : objectLayout,
          points,
          metadata: {
            labels: {
              group_size: parseInt(formData.group_size || '1', 10),
              desktop_required: !!formData.desktop_required,
              interim_final_assignment: !!formData.interim_final_assignment,
              remote_learning_suitable: !!formData.remote_learning_suitable,
              meetup: !!formData.meetup,
              workplace: !!formData.workplace,
              checkpoint: !!formData.checkpoint,
            },
            media: {
              poster_url: fileUrl,
            },
            configuration: {
              allow_external_rating: !!formData.allow_external_rating,
            },
          },
          ...(hasRelationships
            ? {
                relationships: {
                  learning_object_collection: collectionId,
                  learning_route_node: nodeId,
                  learning_subject: subjectId,
                  ...(nfcTagEnabled && nfcTag?.id
                    ? {
                        nfc_tags: [nfcTag.id],
                      }
                    : {}),
                },
              }
            : {}),
        },
        {
          onSuccess: (response) => {
            setNewLearningObjectId(response.data.id);
            if (nfcTagEnabled && nfcTag?.id) {
              handleNfcCondition();
            }
            if (
              !(
                updateLearningObjectVariety.isPending &&
                createOrUpdateLearningObject.isPending
              )
            ) {
              setToast(
                'views.courses.collection_edit.assignment_modal.form.update.success',
                'success'
              );
            }
          },
          onError: (data: ErrorDocument) => {
            setToast(
              data.errors.map((error) => error.detail).join('<br />'),
              'error'
            );
          },
        }
      );
      // eslint-disable-next-line max-len
    },
    [
      nodeId,
      subjectId,
      collectionId,
      nfcTag?.id,
      createOrUpdateLearningObject,
      learningObject,
      isQuiz,
      objectLayout,
      points,
      fileUrl,
      nfcTagEnabled,
      updateLearningObjectVariety.isPending,
      handleNfcCondition,
      setToast,
    ]
  );

  return (
    <Form<FormData>
      onSubmit={handleSubmitLearningObject}
      id="assignment-details"
    >
      {!isQuiz && (
        <NfcForm
          nfcTagEnabled={nfcTagEnabled}
          setNfcTagEnabled={setNfcTagEnabled}
          setCreatedNfcTag={setCreatedNfcTag}
          learningObjectTitle={learningObjectTitle}
          nfcTag={nfcTag}
        />
      )}
      <div className={cx(styles.wrapper)}>
        <Input
          label={t('views.courses.collection_edit.assignment_modal.form.title')}
          name="title"
          value={learningObjectTitle}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLearningObjectTitle(e.target.value)
          }
          ref={titleInputRef}
        />
        <div className={cx(styles.groupedInputs)}>
          {!isQuiz && (
            <Input
              label={t(
                'views.courses.collection_edit.assignment_modal.form.group'
              )}
              name="group_size"
              type="number"
              defaultValue={(metadata?.groupSize || 1).toString()}
            />
          )}
          <Input
            label={t('views.courses.collection_edit.assignment_modal.form.xp')}
            type="number"
            defaultValue="0"
            value={points?.toString()}
            onChange={(e) =>
              setPoints(parseFloat(e.target.value.replace(',', '.')))
            }
            className={cx(styles.pointsInput, { quiz: isQuiz })}
          />
        </div>
        {!isQuiz && (
          <>
            <Checkbox
              quiet
              small
              id="external_rating"
              name="allow_external_rating"
              label={t(
                'views.courses.collection_edit.assignment_modal.form.external_rating'
              )}
              defaultValue="true"
              defaultChecked={metadata?.externalRating}
            />
            <LayoutSelector
              layoutType="object"
              setSelectedLayout={setObjectLayout}
              selectedLayout={objectLayout}
              routeLayout={routeLayout}
            />

            <div className={cx(styles.badgeContainer)}>
              <strong>
                {t(
                  'views.courses.collection_edit.assignment_modal.form.attributes'
                )}
              </strong>
              {['desktopRequired', 'remoteLearningSuitable'].map(
                (attribute) => {
                  const Icon = OBJECT_META_TAG_ICONS[attribute];
                  return (
                    <Checkbox
                      className={cx(styles.checkbox)}
                      quiet
                      small
                      id={attribute}
                      key={attribute}
                      name={toSnakeCase(attribute)}
                      defaultValue="true"
                      defaultChecked={metadata?.[snakeToCamel(attribute)]}
                    >
                      <Icon />
                      {t(
                        `views.collection.object.metadata.${toSnakeCase(attribute)}.description`
                      )}
                    </Checkbox>
                  );
                }
              )}
              <strong>
                {t(
                  'views.courses.collection_edit.assignment_modal.form.badges'
                )}
              </strong>
              <div className={cx(styles.badges)}>
                {BADGES.map((badge) => {
                  const Icon = OBJECT_META_TAG_ICONS[badge];
                  return (
                    <Checkbox
                      className={cx(styles.checkbox)}
                      quiet
                      small
                      id={badge}
                      key={badge}
                      name={toSnakeCase(badge)}
                      defaultValue="true"
                      defaultChecked={metadata?.[badge]}
                    >
                      <div>
                        <Icon fill="currentColor" />
                        <strong>
                          {t(
                            `views.collection.object.metadata.${toSnakeCase(badge)}.description`
                          )}
                        </strong>
                      </div>
                    </Checkbox>
                  );
                })}
              </div>
            </div>

            <strong>
              {t('views.courses.collection_edit.assignment_modal.form.preview')}
            </strong>
            <PreviewLearningObject points={points} fileUrl={fileUrl} />
            <div className={cx(styles.uploadBox)}>
              <UploadBox
                url={`${apiConfig.baseUrl}/v1/services/media_upload`}
                headers={apiConfig.headers}
                paramName="files[0]"
                maxFiles={1}
                label={t('views.courses.collection_edit.form.image')}
                onAfterFileUpload={(
                  file: Dropzone.DropzoneFile,
                  response: any
                ) => {
                  setFileUrl(response?.data?.[0]?.attributes?.file_url);
                }}
              />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t('views.courses.collection_edit.form.image_instructions')
                ),
              }}
            />
          </>
        )}
      </div>
    </Form>
  );
};
