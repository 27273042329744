import React, { useMemo, useState } from 'react';
import { useGlossaryTerms } from '@brainstud/academy-api/Hooks/useGlossaryTerms';
import { GlossaryTerm } from '@brainstud/academy-api/Types/Resources/GlossaryTerm';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { sanitizer } from 'Utils/Sanitizer';
import { SearchSortAndToggle } from '../../Components/SearchSortAndToggle';
import { Content, withBase } from '../../Layouts';
import styles from './GlossaryView.module.css';

const cx = classNames.bind(styles);

type TLetterBlock = {
  startLetter: string;
  items: GlossaryTerm[];
};

export const GlossaryView = withBase(() => {
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  const [search, setSearch] = useState('');
  const safeSearch = search.replace(/[^0-9a-z\s]/g, '');
  const searchRegex = useMemo(() => new RegExp(safeSearch, 'i'), [safeSearch]);
  const [{ data: glossary }, { isLoading }] = useGlossaryTerms({
    course: courseId,
  });

  const organisedGlossary = useMemo(
    () =>
      glossary
        .filter((item) => searchRegex.test(item.term))
        .sort((a, b) => (a.term.toLowerCase() < b.term.toLowerCase() ? -1 : 1))
        .reduce<TLetterBlock[]>((p, item) => {
          const letterBlock = p.find((i) => i.startLetter === item.startLetter);
          if (letterBlock) {
            letterBlock.items.push(item);
          } else {
            p.push({
              startLetter: item.startLetter,
              items: [item],
            });
          }
          return p;
        }, []),
    [glossary, searchRegex]
  );

  return (
    <Container className={cx(styles.base)}>
      <Content>
        <SearchSortAndToggle onSearch={setSearch} />
        {isLoading ? (
          <Loading />
        ) : (
          organisedGlossary.map((group) => (
            <Accordion
              key={group.startLetter}
              title={group.startLetter}
              open={!!search || true}
              heading
              quiet
              className={styles.group}
            >
              {group.items.map((item) => (
                <Accordion
                  key={item.term}
                  title={item.term}
                  open={organisedGlossary.length < 3 && group.items.length < 3}
                >
                  <div
                    className={styles.definition}
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(item.definition),
                    }}
                  />
                </Accordion>
              ))}
            </Accordion>
          ))
        )}
      </Content>
    </Container>
  );
});
