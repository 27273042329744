import React, { useCallback } from 'react';
import { useAnswers } from '@brainstud/academy-api/Hooks/useAnswers';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { ActionMenu } from '@brainstud/ui/Buttons/ActionMenu';
import { Button } from '@brainstud/ui/Buttons/Button';
import { EmptyResourcePanel } from '@brainstud/ui/Static/EmptyResourcePanel';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Status } from '@brainstud/ui/Static/Status';
import { Table, Td, Th, Tr } from '@brainstud/ui/Static/Table';
import {
  GetApp,
  RateReviewOutlined as RateReview,
  Share,
} from '@mui/icons-material';
import { ReactComponent as EmptyPortfolio } from 'Assets/Icons/empty_portfolio.svg';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useUserData } from 'Hooks/UserData';
import { useRouter } from 'next/router';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { Content, withBase } from '../../../Layouts';
import { ExternalRatingModal } from '../../../Modals/ExternalRatingModal';
import { StatusColors } from '../../../Utils/StatusColors';
import { ExportModal } from './ExportModal';
import { PortfolioShareModal } from './PortfolioShareModal';
import { ShareModal } from './ShareModal';
import styles from './PortfolioView.module.css';

const cx = classNames.bind(styles);

export const PortfolioView = withBase(() => {
  const [{ data: enrollment }] = useEnrollment();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  const [{ id: accountId }] = useUserData();
  const shareAllLink = enrollment?.links?.portfolioExport?.href;
  const { openModal } = useModals();
  const [t, { ucf }] = useTranslator();

  const [{ data: answers }, { isLoading }] = useAnswers({
    filter: {
      course: courseId,
      account: accountId,
      without_answer_group: true,
    },
  });

  const handleAddExternalRating = useCallback(
    (answerId) => {
      openModal(ExternalRatingModal, { answerId });
    },
    [openModal]
  );

  if (isLoading) return <Loading />;

  return (
    <Container>
      <Content className={cx(styles.base)}>
        {answers.length === 0 ? (
          <EmptyResourcePanel
            icon={EmptyPortfolio}
            title="menu.no_assignments_made"
            subtitle="views.portfolio.no_assignments_made_description"
          />
        ) : (
          <>
            <Panel className={styles.table}>
              <Table>
                <Table.THead>
                  <Tr>
                    <Th>{t('menu.assignments')}</Th>
                    <Th>{t('views.portfolio.xp')}</Th>
                    <Th>{t('views.portfolio.status')}</Th>
                    <Th>{t('views.portfolio.turned_in_date')}</Th>
                    <Th>{t('views.portfolio.actions')}</Th>
                  </Tr>
                </Table.THead>
                <Table.TBody>
                  {answers.map((answer) => (
                    <Tr key={answer?.learningObject?.().id}>
                      <Td
                        to={`/courses/${courseId}/detail/${answer?.learningObject?.().id}`}
                      >
                        {answer?.learningObject?.().title}
                      </Td>
                      <Td className={styles.small}>
                        {`${answer.learningObjectVariety?.().points} ${t('views.portfolio.xp')}`}
                      </Td>
                      <Td className={styles.small}>
                        <Status scheme={StatusColors[answer.status]}>
                          {
                            {
                              OPEN: ucf(t('states.open')),
                              CONCEPT: ucf(t('states.concept')),
                              TURNED_IN: ucf(t('states.turned_in')),
                              REJECTED: ucf(t('states.rejected')),
                              ACCEPTED: ucf(t('states.accepted')),
                            }[answer.status || 'OPEN']
                          }
                        </Status>
                      </Td>
                      <Td className={styles.medium}>
                        {DateFormatter.toAbbreviatedDate(answer.createdAt)}
                      </Td>
                      <Td className={styles.small}>
                        <ActionMenu
                          id="portfolio-actions"
                          className={cx(styles.test)}
                        >
                          <button
                            role="menuitem"
                            type="button"
                            onClick={() =>
                              openModal(ShareModal, {
                                enrollmentId: answer?.enrollment?.().id,
                                learningObjectId: answer?.learningObject?.().id,
                              })
                            }
                          >
                            <Share />
                            <span>{t('views.portfolio.share_result')}</span>
                          </button>
                          <button
                            role="menuitem"
                            type="button"
                            onClick={() =>
                              openModal(ExportModal, {
                                id: answer?.learningObject?.().id,
                              })
                            }
                          >
                            <GetApp />
                            <span>{t('views.portfolio.export')}</span>
                          </button>
                          {answer?.learningObject?.().metadata?.configuration
                            ?.allowExternalRating && (
                            <button
                              role="menuitem"
                              type="button"
                              onClick={() => handleAddExternalRating(answer.id)}
                            >
                              <RateReview />
                              <span>
                                {t('views.object.request_external_rating')}
                              </span>
                            </button>
                          )}
                        </ActionMenu>
                      </Td>
                    </Tr>
                  ))}
                </Table.TBody>
              </Table>
            </Panel>

            <div className={cx('share-all')}>
              <Button quiet onClick={() => openModal(PortfolioShareModal)}>
                <span>{t('views.portfolio.share_progress')}</span>
                <Share fontSize="large" />
              </Button>
              {shareAllLink && (
                <Button quiet href={shareAllLink}>
                  <span>{t('views.portfolio.export')}</span>
                  <GetApp fontSize="large" />
                </Button>
              )}
            </div>
          </>
        )}
      </Content>
    </Container>
  );
});
