import React from 'react';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import classNames from 'classnames/bind';
import { TileSelector } from 'Components/TileSelector';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { AssignmentModal } from '../ContentModals/AssignmentModal';
import { TAssignmentModalData } from '../ContentModals/AssignmentModal/Types';
import { QuizModal } from '../ContentModals/QuizModal';
import { SubjectModal } from '../ContentModals/SubjectModal';
import { AssignmentIcon, QuizIcon, SubjectIcon, TheoryIcon } from './Icons';
import styles from './ContentSelectionModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodeId: UUID;
  collectionId: UUID;
  subjectId?: UUID;
  learningRouteId: UUID;
  routeLayout: string;
  quizSubjectId?: UUID;
};

export const ContentSelectionModal = ({
  nodeId,
  collectionId,
  learningRouteId,
  subjectId,
  routeLayout,
  quizSubjectId,
}: Props) => {
  const [t] = useTranslator();
  const { closeModal, openModal } = useModals();
  const assignmentModalData: TAssignmentModalData = {
    nodeId,
    collectionId,
    learningRouteId,
    subjectId,
    routeLayout,
    isQuiz: !!quizSubjectId,
  };
  const theoryAssignmentModalData: TAssignmentModalData = {
    ...assignmentModalData,
    isTheory: false,
  };

  return (
    <SidebarModal onClose={closeModal} onClickOutside size="medium">
      <SidebarModal.Header>
        <h3>
          {t('views.courses.collection_edit.content_selection_modal.title')}
        </h3>
      </SidebarModal.Header>
      <div className={cx(styles.tiles)}>
        <TileSelector
          className={cx(styles.tile)}
          onChange={() => openModal(AssignmentModal, assignmentModalData)}
          onClick={closeModal}
          name="type"
          quiet
        >
          <div className={cx(styles.tileContent)}>
            <AssignmentIcon />
            <strong>
              {t(
                'views.courses.collection_edit.content_selection_modal.assignment'
              )}
            </strong>
          </div>
        </TileSelector>
        <TileSelector
          name="type"
          className={cx(styles.tile)}
          onChange={() => openModal(AssignmentModal, theoryAssignmentModalData)}
          onClick={closeModal}
          quiet
        >
          <div className={cx(styles.tileContent)}>
            <TheoryIcon />
            <strong>
              {t(
                'views.courses.collection_edit.content_selection_modal.theory'
              )}
            </strong>
          </div>
        </TileSelector>

        {!quizSubjectId && (
          <>
            <TileSelector
              name="type"
              className={cx(styles.tile)}
              onChange={() =>
                openModal(SubjectModal, {
                  nodeId,
                  subjectId,
                  routeLayout,
                })
              }
              onClick={closeModal}
              quiet
            >
              <div className={cx(styles.tileContent)}>
                <SubjectIcon />
                <strong>
                  {t(
                    'views.courses.collection_edit.content_selection_modal.subject'
                  )}
                </strong>
              </div>
            </TileSelector>

            <TileSelector
              name="type"
              className={cx(styles.tile)}
              onChange={() =>
                openModal(QuizModal, {
                  nodeId,
                  routeLayout,
                  parentSubjectId: subjectId,
                })
              }
              onClick={closeModal}
              quiet
            >
              <div className={cx(styles.tileContent)}>
                <QuizIcon />
                <strong>
                  {t(
                    'views.courses.collection_edit.content_selection_modal.quiz'
                  )}
                </strong>
              </div>
            </TileSelector>
          </>
        )}
      </div>
    </SidebarModal>
  );
};
