import React, { useCallback, useMemo } from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { useLearningRouteEditorProvider } from '@brainstud/course-editor/LearningRouteEditor/Provider';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, Th, Tr } from '@brainstud/ui/Static/Table';
import { Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { AUTHOR_ROLES, COACH_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { CardIntroduction } from 'Modules/action-based-learning-panel/Components/Cards/CardIntroduction';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import {
  ILearningRouteNodeWithResources,
  ILearningSubjectWithResources,
} from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { LearningObjectRow } from './LearningObjectRow';
import { LearningSubjectRow } from './LearningSubjectRow';
import styles from './CollectionsTable.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodes: ILearningRouteNodeWithResources[];
  searchTerm?: string;
};

/**
 * CollectionsTable.
 *
 * This component will show the content of a collection in a table. Each RouteNote will be shown as an accordion the
 * contents of the accordion are the learningObjects and learningSubjects of each note.
 */
export const CollectionsTable = ({ nodes, searchTerm }: Props) => {
  const [t] = useTranslator();
  const { isStudentEnvironment } = useEnvironmentProvider();
  const isCoachOrAuthor = useHasAnyRole([...COACH_ROLES, ...AUTHOR_ROLES]);

  const { preview } = useLearningRouteEditorProvider(true) || {
    preview: false,
  };

  // Search
  const handleNestedObject = useCallback(
    (item: LearningObject | ILearningSubjectWithResources) => {
      if (item.resourceType === 'learning_subjects' && searchTerm) {
        return item.scheme.some((subITem) =>
          new RegExp(searchTerm, 'ig').test(subITem.title)
        );
      }
    },
    [searchTerm]
  );

  const sortedNodes = useMemo(() => {
    if (searchTerm) {
      // Title of the nodes
      return (
        nodes
          ?.filter(
            (learningRouteNode) =>
              new RegExp(searchTerm, 'ig').test(learningRouteNode.title) ||
              // Title of learningObject and Subjects
              learningRouteNode.scheme.some((schemeItem) =>
                new RegExp(searchTerm, 'ig').test(schemeItem.title)
              ) ||
              // Title of LearningObject inside Subjects
              learningRouteNode.scheme.some(handleNestedObject)
          )
          // actual filtering of all the learningObjects, learningSubjects and nested learningObjects
          .map(
            (node): ILearningRouteNodeWithResources => ({
              ...node,
              scheme: node.scheme
                .map((schemeItem) => {
                  if (schemeItem.resourceType === 'learning_subjects') {
                    return {
                      ...schemeItem,
                      scheme: schemeItem.scheme.filter((subjectObject) =>
                        new RegExp(searchTerm, 'ig').test(subjectObject.title)
                      ),
                    };
                  }
                  return schemeItem;
                })
                .filter((filterItem) => {
                  if (filterItem.resourceType === 'learning_objects') {
                    return new RegExp(searchTerm, 'ig').test(filterItem.title);
                  }
                  return filterItem;
                }),
            })
          )
      );
    }
    return nodes;
  }, [handleNestedObject, nodes, searchTerm]);

  if (sortedNodes?.length === 0) {
    return (
      <div
        className={cx('no-data-row')}
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            t(
              `components.collections_table.no_content.${searchTerm ? 'found' : 'available'}`
            )
          ),
        }}
      />
    );
  }

  return (
    <div className={cx(styles.root)}>
      {sortedNodes?.map((node) => {
        // TODO when condition.meta is filled this check should be changed
        const nodeCondition = node.conditions?.();
        const firstCondition = nodeCondition?.[0];
        const conditionType =
          firstCondition?.type === 'NODE_COMPLETED' ? 'node' : 'object';
        const hasUnCompletedConditions =
          !!nodeCondition &&
          !!firstCondition &&
          (isCoachOrAuthor || node.meta?.isLocked);
        return (
          <Accordion
            key={node.title}
            title={
              isStudentEnvironment ? (
                <h3 className={cx(styles.title)}>
                  {node.title}
                  {hasUnCompletedConditions && <Lock />}
                </h3>
              ) : (
                <>
                  {hasUnCompletedConditions ? (
                    <div className={cx(styles['condition-node'])}>
                      <span>{node.title}</span>
                      <Lock />
                    </div>
                  ) : (
                    node.title
                  )}
                </>
              )
            }
            className={cx(styles.base)}
            open
            quiet
            heading
          >
            {hasUnCompletedConditions && (
              <Callout
                info
                className={cx(styles['conditions-callout'])}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    t(
                      `components.collections_table.condition.${conditionType!}`,
                      {
                        object: node.title,
                        target: firstCondition.target?.().title,
                      }
                    )
                  ),
                }}
              />
            )}
            {(isStudentEnvironment || preview) && (
              <CardIntroduction
                className={cx(styles['card-introduction'])}
                introduction={node.introduction}
              />
            )}
            <Panel>
              <Table>
                <Table.THead>
                  <Tr>
                    <Th style={{ width: '100%' }}>
                      {t('components.collections_table.assignment')}
                    </Th>
                    {isStudentEnvironment || preview ? (
                      <>
                        <Th right className={cx(styles.right)}>
                          {t('components.collections_table.xp')}
                        </Th>
                        <Th right className={cx(styles.right)}>
                          {t('components.collections_table.status')}
                        </Th>
                        <Th />
                      </>
                    ) : (
                      <>
                        <Th />
                        <Th right className={cx(styles.right)}>
                          {t('components.collections_table.xp')}
                        </Th>
                      </>
                    )}
                  </Tr>
                </Table.THead>
                <Table.TBody>
                  {node.scheme.map((item) => (
                    <React.Fragment key={item.id}>
                      {item.resourceType === 'learning_objects' && (
                        <LearningObjectRow
                          object={item}
                          locked={!!nodeCondition}
                        />
                      )}
                      {item.resourceType === 'learning_subjects' && (
                        <LearningSubjectRow subject={item} />
                      )}
                    </React.Fragment>
                  ))}
                </Table.TBody>
              </Table>
            </Panel>
          </Accordion>
        );
      })}
    </div>
  );
};
