import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useUpdate } from '../Factories/useUpdate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningRoute,
  LearningRouteCreateInput,
  LearningRouteFilters,
  LearningRouteIncludes,
  LearningRouteSorts,
  LearningRouteUpdateInput,
} from '../Types/Resources/LearningRoute';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningRouteProps
  extends HookParams<
    typeof LearningRouteIncludes,
    typeof LearningRouteFilters,
    typeof LearningRouteSorts
  > {
  learningRoute?: UUID;
}

export function useLearningRoute(
  { learningRoute, ...queryParameters }: ILearningRouteProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningRoute>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { learningRoute: defaultLearningRoute },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningRoute || defaultLearningRoute),
      baseName: 'v1.learningRoutes',
      baseUri: '/v1/learning_routes',
      uri: `/${learningRoute || defaultLearningRoute}`,
      invalidate: ['learning_route', 'learning_routes'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<LearningRoute, LearningRouteUpdateInput>(
    request,
    settings
  );
}

export function useLearningRoutes(
  queryParameters: ILearningRouteProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningRoute[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningRoutes',
      baseUri: '/v1/learning_routes',
      invalidate: ['learning_route', 'learning_routes'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningRoute, LearningRouteCreateInput>(request, settings);
}

/**
 * Update a whole learning route with all nodes and objects.
 * @deprecated Use update method with PATCH on useLearningRoute instead
 * */
export function useLearningRouteFullUpdate({
  learningRoute,
  ...queryParameters
}: ILearningRouteProps = {}) {
  const context = useApi();
  const {
    parameters: { learningRoute: defaultLearningRoute },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningRoute || defaultLearningRoute),
      baseName: 'v1.learningRoutes',
      baseUri: '/v1/learning_routes',
      uri: `/${learningRoute || defaultLearningRoute}/full_update`,
      queryParameters,
    },
    context
  );
  return useUpdate<LearningRoute, LearningRouteUpdateInput>(request);
}
