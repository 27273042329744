import React from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { Td, Tr } from '@brainstud/ui/Static/Table';
import { AVAILABLE_TAGS, BADGES, useObjectTags } from 'Hooks/useObjectTags';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { CardTag } from '../Cards/CardTag';
import { ObjectStatus } from '../ObjectStatus/ObjectStatus';
import styles from './TableViewRow.module.css';

type TProps = {
  object: LearningObject | ILearningSubjectWithResources;
};

export const TableViewRow = ({ object }: TProps) => {
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  const set =
    object.resourceType === 'learning_subjects' ? 'subjects' : 'detail';
  const tags = useObjectTags(
    object.resourceType === 'learning_objects' ? object : undefined
  );
  const badgeTags = [...BADGES, ...AVAILABLE_TAGS];

  return (
    <Tr to={`/courses/${courseId}/${set}/${object.id}`} key={object.id}>
      <Td
        to={`/courses/${courseId}/${set}/${object.id}`}
        className={styles.maximize}
      >
        {object.title}
      </Td>
      <Td>
        <div className={styles.tags}>
          {tags
            .filter(
              (tag) =>
                badgeTags.includes(tag.name) &&
                !(tag.name === 'xp' && Math.ceil(tag.value as number) === 0)
            )
            .map((tag) => (
              <CardTag key={tag.name} type={tag.name} value={tag?.value} />
            ))}
        </div>
      </Td>
      <Td className={styles.status}>
        <ObjectStatus item={object} />
      </Td>
    </Tr>
  );
};
