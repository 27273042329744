import React, { useCallback, useMemo, useState } from 'react';
import { Search } from '@brainstud/ui/Form Input/Search';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { ContentPaste } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useSessionStorage } from 'Hooks/BrowserStorage/useSessionStorage';
import { useUserData } from 'Hooks/UserData/useUserData';
import { useTranslator } from 'Providers/Translator';
import { ITool, ToolArray } from '../../../../ToolArray';
import { CONTENT_CLIPBOARD } from '../../../Constants/ContentClipboard';
import { reIdentifyBlockTree } from '../../Provider/BlockActions';
import { UnknownBlock } from '../../Types';
import styles from './ToolModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  layoutName?: string;
  handleAddBlock: (
    type: string,
    containerId?: string,
    block?: UnknownBlock
  ) => void;
  closeModal: () => void;
  containerId?: string;
};

export const ToolModal = ({
  layoutName,
  handleAddBlock,
  closeModal,
  containerId,
}: TProps) => {
  const [t] = useTranslator();
  const [search, setSearch] = useState<string>('');
  const handleClick = useCallback(
    (type: string) => {
      handleAddBlock(type, containerId);
      closeModal();
    },
    [closeModal, containerId, handleAddBlock]
  );
  const isSearching = !!search.length;

  const [{ roles }] = useUserData();

  const hasAccess = useCallback(
    (tool: ITool) => {
      if (tool?.access?.create) {
        return !!roles?.some((role) => tool?.access?.create?.includes(role));
      }
      return true;
    },
    [roles]
  );
  const list = useMemo(
    () =>
      ToolArray.filter((tool) => {
        const keywords = t(
          `course-editor.content_editor.blocks.${tool.type}.keywords`
        ) as unknown;
        return (
          (!tool.hidden ||
            (Array.isArray(tool.hidden) &&
              layoutName &&
              !tool.hidden.includes(layoutName))) &&
          hasAccess(tool) &&
          (!isSearching ||
            (Array.isArray(keywords) &&
              keywords.some(
                (keyword: string) =>
                  keyword.search(new RegExp(`${search}`, 'i')) !== -1
              )))
        );
      }),
    [t, layoutName, hasAccess, isSearching, search]
  );

  const [clipboard] = useSessionStorage<UnknownBlock>(CONTENT_CLIPBOARD);
  const handlePaste = useCallback(() => {
    if (clipboard) {
      handleAddBlock('paste', containerId, reIdentifyBlockTree(clipboard));
      closeModal();
    }
  }, [clipboard, handleAddBlock, containerId, closeModal]);

  return (
    <ContentModal
      onClose={closeModal}
      onClickOutside
      className={cx(styles.base)}
    >
      <h2>{t('course-editor.content_editor.tool_modal.title')}</h2>
      <div>
        <Search
          autoFocus
          onChange={(event) => setSearch(event.target.value)}
          debounce={0}
        />
      </div>
      <div className={cx(styles.grid)}>
        {list.map((tool) => (
          <button
            type="button"
            key={tool.type}
            className={cx(styles.button)}
            onClick={() => handleClick(tool.type)}
          >
            {tool.icon}
            <span>
              {t(`course-editor.content_editor.blocks.${tool.type}.name`)}
            </span>
          </button>
        ))}
        {clipboard && (
          <button
            type="button"
            key="paste"
            className={cx(styles.button)}
            onClick={handlePaste}
          >
            <ContentPaste />
            <span>
              {t('course-editor.content_editor.clipboard.paste_action')}
            </span>
          </button>
        )}
      </div>
    </ContentModal>
  );
};
