import { IGroupContainerBlock } from '@brainstud/course-blocks/Blocks/Container/GroupContainer/Types';
import { IBlock } from '@brainstud/course-blocks/Blocks/Types';
import { v4 } from 'uuid';

export const createEmptyContainerBlock = (
  blocks: IBlock[] = []
): IGroupContainerBlock => ({
  id: v4(),
  type: 'group_container',
  blocks,
});
