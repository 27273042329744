import React from 'react';
import { ToolkitItem as TToolkitItem } from '@brainstud/academy-api/Types/Resources/ToolkitItem';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ToolkitItem } from './ToolkitItem';

type Props = {
  mediaItem: TToolkitItem;
  closeModal: () => void;
};

export const ToolkitItemModal = ({ mediaItem, closeModal }: Props) => {
  const url = mediaItem?.content?.url || '';
  const [t] = useTranslator();

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <ContentModal.Header>
        <h2>{mediaItem.title}</h2>
      </ContentModal.Header>

      <div
        dangerouslySetInnerHTML={{ __html: sanitizer(mediaItem.description) }}
      />

      <ToolkitItem
        itemType={mediaItem.itemType}
        title={mediaItem.title}
        url={url}
      />

      <ContentModal.Footer>
        <Button quiet onClick={() => closeModal()}>
          {t('close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
