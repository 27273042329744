import React from 'react';
import { ReactComponent as questionCorrect } from 'Assets/Icons/success.svg';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import { useRouter } from 'next/router';
import { ReactComponent as questionDefault } from './Icons/question-default.svg';
import { ReactComponent as questionIncorrect } from './Icons/question-incorrect.svg';
import styles from './QuestionResult.module.css';

const cx = classNames.bind(styles);

type Props = {
  id?: string;
  title: string;
  status?: 'OPEN' | 'CONCEPT' | 'TURNED_IN' | 'ACCEPTED' | 'REJECTED';
};

const Icons = {
  ACCEPTED: questionCorrect,
  REJECTED: questionIncorrect,
  TURNED_IN: questionDefault,
  OPEN: questionIncorrect,
  CONCEPT: questionDefault,
};

export const QuestionResult = ({ id, title, status }: Props) => {
  const Icon = status ? Icons[status] || questionDefault : questionDefault;
  const router = useRouter();
  const { courseId } = router.query as { courseId?: string };
  const isLink = courseId && id;
  const Element = isLink ? Link : 'div';
  return (
    <Element
      className={cx(styles.base, { [styles.isLink]: isLink })}
      href={
        (isLink ? `/courses/${courseId}/detail/${id}` : undefined) as string
      }
    >
      {status && <Icon className={cx(styles.icon)} />}
      <strong>{title}</strong>
    </Element>
  );
};
