import { useLearningObjectProvider } from 'Providers/LearningObjectProvider';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { Layouts } from '../Layouts';

/**
 * useActualObjectLayout.
 *
 * Determines based on the Layouts configuration object and the configured learning object
 * which layout should be shown. This prevents inappropriate combinations between learning route layout
 * and the learning object layout.
 */
export function useActualObjectLayoutName(
  learningObjectLayout?: string,
  learningRouteLayout?: string
) {
  const routeContext = useLearningRouteProvider(true);
  const routeLayout = learningRouteLayout || routeContext?.layout;

  const learningObjectContext = useLearningObjectProvider(true);
  const expectedLayout =
    learningObjectLayout ||
    learningObjectContext?.learningObject?.layout ||
    'panel';

  if (!routeLayout) {
    return undefined;
  }

  const {
    sublayouts: { object: possibleObjectLayouts },
    fallback: { object: fallbackObjectLayout },
  } = Layouts[routeLayout];

  return expectedLayout in possibleObjectLayouts
    ? expectedLayout
    : fallbackObjectLayout.name;
}
