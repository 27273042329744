import React, { useMemo, useState } from 'react';
import { EmptyResourcePanel } from '@brainstud/ui/Static/EmptyResourcePanel/EmptyResourcePanel';
import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { ReactComponent as EmptyToolkit } from 'Assets/Icons/empty_toolkit.svg';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useUserData } from 'Hooks/UserData/useUserData';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { SearchSortAndToggle } from '../../Components/SearchSortAndToggle';
import { ToolkitItems } from '../../Components/ToolkitItems';
import { Content, withBase } from '../../Layouts';
import styles from './ToolkitView.module.css';

const cx = classNames.bind(styles);

export const ToolkitView = withBase(() => {
  const [{ roles }] = useUserData();
  const { learningObjectCollection: collection, isLoading } =
    useLearningRouteProvider();
  const toolkits = useMemo(() => collection?.toolkits?.() || [], [collection]);
  const [t] = useTranslator();

  const scopedToolkits = toolkits.filter(
    (toolkit) => !toolkit.requiredRole || roles?.includes(toolkit.requiredRole)
  );

  const [filter, setFilter] = useState<string>();

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Content className={cx('base')}>
        <SearchSortAndToggle onSearch={setFilter} />
        {scopedToolkits.length ? (
          scopedToolkits.map((toolkit) => (
            <ErrorBoundary key={toolkit.id}>
              <div className={cx(styles.toolkit)}>
                <h3>
                  {toolkit.title ||
                    t(`views.toolkit.default_title.${toolkit.requiredRole}`)}
                  {toolkit.description && (
                    <Tooltip
                      className={cx('tooltip')}
                      title={
                        <div
                          style={{ fontFamily: 'Eina, sans-serif' }}
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(toolkit.description),
                          }}
                        />
                      }
                    >
                      <InfoOutlined className={cx('info-outlined')} />
                    </Tooltip>
                  )}
                </h3>
                <ToolkitItems id={toolkit.id} filter={filter} />
              </div>
            </ErrorBoundary>
          ))
        ) : (
          <EmptyResourcePanel icon={EmptyToolkit} title="views.toolkit.empty" />
        )}
      </Content>
    </Container>
  );
});
