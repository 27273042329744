import React, { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import { useDimensions } from '@brainstud/universal-components/Hooks/useDimensions';
import { KeyboardArrowDown, KeyboardArrowUp, Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { ConditionsModal } from 'Modals';
import { CardIntroduction } from 'Modules/action-based-learning-panel/Components/Cards/CardIntroduction';
import {
  ILearningRouteNodeWithResources,
  TNodeListItem,
} from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { RouteActionCardsGrid } from 'Views/CollectionViews/RouteViews/RouteActionCards/RouteActionCardsGrid/RouteActionCardsGrid';
import styles from './LearningRouteNode.module.css';

const cx = classNames.bind(styles);

type Props = {
  learningRouteNode: ILearningRouteNodeWithResources;
  filteredItems: TNodeListItem[];
  searchTerm?: string;
};

export const LearningRouteNode = ({
  learningRouteNode,
  filteredItems,
  searchTerm,
}: Props) => {
  const [t] = useTranslator();

  const maxHeight = 565;
  const [gridExpanded, setGridExpanded] = useState(false);
  const [node, dimensions] = useDimensions();
  const [showExpand, setShowExpand] = useState(false);
  const { openModal } = useModals();
  const [isUnlocked, setIsUnlocked] = useState(false);

  useEffect(() => {
    setShowExpand(!((dimensions.height || 0) < maxHeight));
  }, [dimensions]);

  const hasUnCompletedConditions = useMemo(
    () =>
      !!learningRouteNode.conditions?.() && learningRouteNode.meta?.isLocked,
    [learningRouteNode]
  );

  return (
    <Accordion
      title={
        <h3 className={cx(styles.title)}>
          {hasUnCompletedConditions && <Lock />}
          {`${learningRouteNode.title} (${learningRouteNode.scheme.length})`}
        </h3>
      }
      className={cx(styles.base, 'flex-accordion')}
      open
      quiet
      heading
    >
      {learningRouteNode?.introduction && (
        <CardIntroduction introduction={learningRouteNode.introduction} />
      )}
      <div className={cx('wrap-items', { gridExpanded })}>
        <AnimateHeight
          className={cx(styles.heightContainer)}
          height={!showExpand || gridExpanded ? 'auto' : maxHeight}
        >
          <div ref={node} className={cx(styles.grid)}>
            {filteredItems?.map((subItem: TNodeListItem) => (
              <RouteActionCardsGrid
                key={subItem.id}
                item={subItem}
                searchTerm={searchTerm}
                parentHasCondition={hasUnCompletedConditions}
              />
            ))}
          </div>
        </AnimateHeight>
        {showExpand && (
          <div className={cx('show-more')}>
            <Button
              type="button"
              onClick={() => setGridExpanded((expanded) => !expanded)}
            >
              {gridExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              {t(gridExpanded ? 'less' : 'more')}
            </Button>
          </div>
        )}
      </div>
      {hasUnCompletedConditions && !isUnlocked && (
        <div
          className={cx({ 'node-condition': hasUnCompletedConditions })}
          onClick={() =>
            openModal(ConditionsModal, {
              object: learningRouteNode,
              setIsUnlocked,
            })
          }
          aria-hidden="true"
        >
          <Lock />
        </div>
      )}
    </Accordion>
  );
};
