import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAnswerFeedback } from '@brainstud/academy-api/Hooks/useAnswerFeedback';
import { useAnswerGroup } from '@brainstud/academy-api/Hooks/useAnswerGroups';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import {
  AnswerFeedback,
  AnswerFeedbackStatus,
} from '@brainstud/academy-api/Types/Resources/AnswerFeedback';
import { Button } from '@brainstud/ui/Buttons/Button';
import { FileCard } from '@brainstud/ui/FileHandling/FileCard';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Radio } from '@brainstud/ui/Form Input/Radio';
import { Status } from '@brainstud/ui/Static/Status';
import { Form } from '@brainstud/universal-components/Components/Form';
import {
  ArrowForward,
  AttachFile,
  Check,
  Save,
  Undo,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { RichTextEditor } from 'Components/RichTextEditor';
import { TFormProps } from 'Modules/universal-components/Components/Form/Types';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider/useAnswerGroupProvider';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { useAnswerReview } from '../../../Providers/AnswerReviewProvider/useAnswerReview';
import styles from './AnswerReviewForm.module.css';

const cx = classNames.bind(styles);

export type TAnswerReviewItem = Omit<
  AnswerFeedback,
  'resourceType' | 'id' | 'createdAt' | 'updatedAt'
> &
  Partial<Pick<AnswerFeedback, 'id' | 'createdAt' | 'updatedAt'>>;

type TDefault = {
  feedback: string;
  status: AnswerFeedbackStatus;
};

type TDefaultResponse = ReturnType<
  ReturnType<typeof useAnswerFeedback>[0]['create']['mutateAsync']
>;

type Props<
  T extends {} = TDefault,
  OnFeedbackReturnType extends ReturnType<
    Exclude<TFormProps['onSubmit'], undefined>
  > = TDefaultResponse,
> = {
  onFeedbackSubmit?: (feedback: T, files: string[]) => OnFeedbackReturnType;
  baseUri?: string;
  feedback?: TAnswerReviewItem[];
  onSuccess?: () => void;
};

export function AnswerReviewForm<
  FormOutput extends { [key: string]: string } = TDefault,
  FormResponse extends ReturnType<
    Exclude<TFormProps['onSubmit'], undefined>
  > = TDefaultResponse,
>({
  onFeedbackSubmit,
  baseUri,
  feedback,
  onSuccess,
}: Props<FormOutput, FormResponse>) {
  const [t, { lc }] = useTranslator();
  const { baseUrl, headers: apiHeaders } = useApi();
  const { answer, answerId, answers, isLoading, nextItem, origin } =
    useAnswerReview() || {};
  const [setToast] = useToaster();
  const [redo, setRedo] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const isLatestAnswer = answers?.[0]?.id === answerId;
  const isLimitedFeedbackSet = !!feedback;
  const [previewContainer, setPreviewContainer] =
    useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setRedo(false);
  }, [answerId]);

  const feedbackIsRelationship = typeof answer?.feedback === 'function';
  const feedbackSet = useMemo(
    () =>
      feedback || (feedbackIsRelationship ? answer?.feedback?.() || [] : []),
    [answer, feedback, feedbackIsRelationship]
  );

  const isPreviousAnswer = !isLatestAnswer && !!answer?.status;
  const hasFeedback = isLimitedFeedbackSet
    ? feedbackSet.length > 0
    : (feedbackSet.length > 0 && !redo) ||
      (isLatestAnswer && answer?.status !== 'TURNED_IN') ||
      isPreviousAnswer;
  const lastFeedback =
    hasFeedback || redo ? feedbackSet[feedbackSet.length - 1] : undefined;
  const currentStatus =
    lastFeedback?.givenStatus ||
    (!isLimitedFeedbackSet ? answer?.status : 'TURNED_IN');

  const [{ data: latestFeedbackCoach, create }] = useAnswerFeedback({
    answer: answerId,
    answerFeedback: lastFeedback?.id,
  });

  const latestFeedbackFiles = useMemo(
    () => latestFeedbackCoach?.files?.() || [],
    [latestFeedbackCoach]
  );
  const hasWrittenFeedback = (lastFeedback?.givenFeedback || '').trim() !== '';

  const [givenFeedback, setGivenFeedback] = useState(
    lastFeedback?.givenFeedback
  );

  const isFeedbackFormVisible =
    (redo || !lastFeedback) &&
    isLatestAnswer &&
    (redo || currentStatus === 'TURNED_IN');

  type Handler = Exclude<TFormProps<FormOutput>['onSubmit'], undefined>;
  const handleFeedbackSubmission = useCallback<Handler>(
    (data) => {
      if (onFeedbackSubmit) {
        return onFeedbackSubmit({ ...data, feedback: givenFeedback }, files);
      }
      return create.mutateAsync({
        feedback: givenFeedback || '',
        status: (data.status as AnswerFeedbackStatus) || answer?.status,
        relationships: {
          files,
        },
      });
    },
    [onFeedbackSubmit, create, givenFeedback, answer?.status, files]
  );

  const handleReview = useCallback(
    async (data: FormOutput) => {
      try {
        setLoading(true);
        const response = await handleFeedbackSubmission(data);
        setGivenFeedback(undefined);
        setRedo(false);
        onSuccess?.();
        return response;
      } catch (error) {
        setToast('error', 'error');
      } finally {
        setLoading(false);
      }
    },
    [handleFeedbackSubmission, onSuccess, setToast]
  );

  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};
  const [hasSeenAnswerGroup, setHasSeenAnswerGroup] = useState(false);
  const [{ createOrUpdate }] = useAnswerGroup(
    { answerGroup: currentAnswerGroup?.id },
    { enabled: false }
  );

  useEffect(() => {
    if (
      !hasSeenAnswerGroup &&
      currentAnswerGroup
        ?.answers?.()
        .every((item) => item.status !== 'TURNED_IN') &&
      !createOrUpdate.isPending
    ) {
      setHasSeenAnswerGroup(true);
      createOrUpdate.mutateAsync({
        coach_viewed: true,
      });
    }
  }, [createOrUpdate, currentAnswerGroup, hasSeenAnswerGroup]);

  return isLoading ? null : (
    <div className={cx(styles.base)}>
      <Form<FormOutput> onSubmit={handleReview}>
        {({ values }) => (
          <>
            <div className={cx(styles.form)}>
              {isFeedbackFormVisible ? (
                <>
                  <div className={cx(styles.radio)}>
                    <Radio
                      id="reject_score"
                      name="status"
                      label={t('review.deny')}
                      defaultValue="REJECTED"
                      className={cx(styles.rejected)}
                      small
                    />
                    <Radio
                      id="accept_score"
                      name="status"
                      defaultValue="ACCEPTED"
                      label={t('review.approve')}
                      className={cx(styles.approve)}
                      small
                    />
                  </div>
                  {!currentAnswerGroup && (
                    <div>
                      <RichTextEditor
                        label={t('review.optionalFeedback')}
                        preset="minimal"
                        placeholder={t('review.feedbackPlaceholder')}
                        defaultValue={givenFeedback}
                        onChange={setGivenFeedback}
                      />
                    </div>
                  )}
                </>
              ) : (
                // Feedback is given & no redo
                <>
                  <div className={cx(styles.grade)}>
                    {currentStatus ? (
                      <Status
                        scheme={
                          currentStatus === 'ACCEPTED'
                            ? 'green'
                            : currentStatus === 'REJECTED'
                              ? 'red'
                              : 'yellow'
                        }
                      >
                        {t(`states.${lc(currentStatus)}`)}
                      </Status>
                    ) : (
                      <span className={cx(styles['no-rating-given'])}>
                        {t('review.no_rating_given')}
                      </span>
                    )}
                  </div>
                  <div>
                    <strong>{t('review.given_feedback')}</strong>
                    <div
                      className={cx(styles.feedback, {
                        noWrittenFeedback: !hasWrittenFeedback,
                      })}
                    >
                      {hasWrittenFeedback ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitizer(lastFeedback?.givenFeedback),
                          }}
                        />
                      ) : (
                        <p>{t('review.no_written_feedback')}</p>
                      )}
                    </div>
                    {latestFeedbackFiles?.map((file) => (
                      <FileCard
                        href={file.downloadUrl}
                        key={file.id}
                        thumbnail={file.downloadUrl}
                      >
                        {file.originalFileName}
                      </FileCard>
                    ))}
                  </div>
                </>
              )}
            </div>
            <footer className={cx(styles.footer)}>
              {isFeedbackFormVisible ? (
                <>
                  {!onFeedbackSubmit && previewContainer && (
                    <UploadBox
                      label=""
                      url={`${baseUrl}/v1/services/temporary_file_upload`}
                      headers={{
                        ...apiHeaders,
                        'Content-Type': undefined,
                      }}
                      paramName="file"
                      quiet
                      preview={previewContainer}
                      className={cx(styles.upload)}
                      onAfterFileUpload={(file, response) => {
                        if (response?.data?.id) {
                          setFiles((prevFiles) => [
                            ...prevFiles,
                            response?.data?.id as string,
                          ]);
                        }
                      }}
                    >
                      <AttachFile fontSize="large" />
                    </UploadBox>
                  )}
                  <div className={cx(styles.buttons)}>
                    {redo && (
                      <Button
                        type="button"
                        quiet
                        onClick={(event) => {
                          event.stopPropagation();
                          setRedo(false);
                        }}
                        small
                      >
                        {t('cancel')}
                      </Button>
                    )}
                  </div>
                  <Button
                    type="submit"
                    loading={loading}
                    disabled={values.status === undefined}
                    outline
                  >
                    <Save />
                    <span>{t('review.save')}</span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    onClick={() => setRedo(true)}
                    outline
                    small
                    disabled={!isLatestAnswer}
                  >
                    <Undo />
                    <span>{t('review.redo')}</span>
                  </Button>
                  {nextItem ? (
                    <Button
                      to={`${baseUri}/${nextItem.enrollmentId}/${nextItem.learningObjectId}/answer`}
                      type="button"
                      small
                    >
                      <span>{t('review.next')}</span>
                      <ArrowForward />
                    </Button>
                  ) : (
                    <Button to={origin} type="button" small>
                      <span>{t('review.finish')}</span>
                      <Check />
                    </Button>
                  )}
                </>
              )}
            </footer>

            {!onFeedbackSubmit && (
              <div className={cx(styles.preview)} ref={setPreviewContainer} />
            )}
          </>
        )}
      </Form>
    </div>
  );
}
