import { FC } from 'react';
import { pick } from 'Utils/pick';
import { LinearView } from './ObjectViews/LinearView/LinearView';
import { OpenView } from './ObjectViews/OpenView';
import { PanelView } from './ObjectViews/PanelView';
import { QuestionView } from './ObjectViews/QuestionView/QuestionView';
import { RouteActionCardsView } from './RouteViews/RouteActionCards';
import { RouteChapterCardsView } from './RouteViews/RouteChapterCards';
import { SubjectActionCardsView } from './SubjectViews/SubjectActionCardsView';
import { SubjectQuizView } from './SubjectViews/SubjectQuizView/SubjectQuizView';

export interface LayoutComponent<T extends {} = any> extends FC<T> {
  Header?: FC;
  Footer?: FC;
  deprecated?: boolean;
}

export type TLayoutConfig<T extends {} = any> = {
  view: LayoutComponent<T>;
};

export const HIDDEN_LAYOUT_NAME = 'none';

const ObjectLayouts = {
  linear: { name: 'linear', view: LinearView },
  panel: { name: 'panel', view: PanelView },
  question: { name: 'question', view: QuestionView },
  open: { name: 'open', view: OpenView },
};
const SubjectLayouts = {
  'action-cards': { view: SubjectActionCardsView },
  // list: {view: SubjectListView}, // FIXME When SubjectListView is added
  quiz: { view: SubjectQuizView },
};

export const Layouts = {
  'action-cards': {
    view: RouteActionCardsView,
    sublayouts: {
      subject: pick(SubjectLayouts, 'action-cards', 'quiz'),
      object: pick(ObjectLayouts, 'panel', 'linear', 'question', 'open'),
    },
    fallback: {
      subject: SubjectLayouts['action-cards'],
      object: ObjectLayouts.open,
    },
  },
  'chapter-cards': {
    view: RouteChapterCardsView,
    sublayouts: {
      subject: pick(SubjectLayouts, 'action-cards'),
      object: pick(ObjectLayouts, 'linear'),
    },
    fallback: {
      subject: SubjectLayouts['action-cards'], // FIXME When Subject List is added.
      object: ObjectLayouts.linear,
    },
  },
};
