import { UseMutationOptions } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { LearningObjectCollection } from '../Types/Resources/LearningObjectCollection';
import { UUID } from '../Types/Utils/UUID';

interface ILearningObjectCollectionDuplicateProps {
  learningObjectCollection?: UUID;
}

export function useLearningObjectCollectionDuplicate(
  {
    learningObjectCollection,
    ...queryParameters
  }: ILearningObjectCollectionDuplicateProps = {},
  settings?: UseMutationOptions<
    DataDocument<LearningObjectCollection>,
    ErrorDocument,
    { title: string }
  >
) {
  const context = useApi();
  const {
    parameters: { learningObjectCollection: defaultLearningObjectCollection },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningObjectCollection || defaultLearningObjectCollection),
      baseName: 'v1.learningObjectCollections.duplicate',
      baseUri: `/v1/learning_object_collections/${learningObjectCollection || defaultLearningObjectCollection}/duplicate`,
      invalidate: [
        'v1.learningObjectCollections',
        'learning_object_collection',
        'learning_object_collections',
      ],
      queryParameters,
    },
    context
  );
  return useCreate<LearningObjectCollection, { title: string; type?: string }>(
    request,
    settings
  );
}
