import { createContext } from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { LearningObjectCollection } from '@brainstud/academy-api/Types/Resources/LearningObjectCollection';
import { LearningRoute } from '@brainstud/academy-api/Types/Resources/LearningRoute';
import { LearningRouteNode } from '@brainstud/academy-api/Types/Resources/LearningRouteNode';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import {
  ILearningRouteContext,
  ILearningSubjectWithResources,
} from 'Providers/LearningRouteProvider/LearningRouteContext';

export type LearningRouteEditorLayouts = 'list' | 'grid';

export interface ILearningRouteGroup extends Omit<LearningRouteNode, 'scheme'> {
  scheme: Array<
    LearningObject | ILearningSubjectWithResources | LearningSubject
  >;
}

export interface ILearningRouteInput extends Omit<LearningRouteNode, 'scheme'> {
  scheme: Array<{
    id: string;
    [key: string]: unknown;
  }>;
}

export type TRouteLearningNode =
  | ILearningRouteGroup
  | LearningObject
  | LearningSubject
  | ILearningSubjectWithResources;

export interface ILearningRouteEditorContext {
  collection?: LearningObjectCollection;
  nodes: ILearningRouteContext['nodes'];
  setNodes(nodes: ILearningRouteContext['nodes']): void;
  updated: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  save(): Promise<void>;
  layout: LearningRouteEditorLayouts;
  setLayout(layout: LearningRouteEditorLayouts): void;
  setPreview(preview: boolean): void;
  preview: boolean;
  isCustomCollection?: boolean;
  isCustomRoute?: boolean;
  learningRoute?: LearningRoute;
}

export default createContext<null | ILearningRouteEditorContext>(null);
