import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  EventCalendarItem,
  EventCalendarItemCreateInput,
  EventCalendarItemFilters,
  EventCalendarItemIncludes,
  EventCalendarItemSorts,
  EventCalendarItemUpdateInput,
} from '../Types/Resources/EventCalendarItem';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IEventCalendarItemProps
  extends HookParams<
    typeof EventCalendarItemIncludes,
    typeof EventCalendarItemFilters,
    typeof EventCalendarItemSorts
  > {
  eventCalendar?: UUID;
  eventCalendarItem?: UUID;
}

export function useEventCalendarItem(
  {
    eventCalendar,
    eventCalendarItem,
    ...queryParameters
  }: IEventCalendarItemProps = {},
  settings?: IUseQueryOptions<DataDocument<EventCalendarItem>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      eventCalendarItem: defaultEventCalendarItem,
      eventCalendar: defaultEventCalendar,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(eventCalendarItem || defaultEventCalendarItem) &&
        !!(eventCalendar || defaultEventCalendar),
      baseName: 'v1.eventCalendars.eventCalendarItems',
      baseUri: `/v1/event_calendars/${eventCalendar || defaultEventCalendar}/items`,
      invalidate: ['event_calendar_items', 'event_calendars_item'],
      uri: `/${eventCalendarItem || defaultEventCalendarItem}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendarItem,
    EventCalendarItemCreateInput,
    EventCalendarItemUpdateInput
  >(request, settings);
}

export function useEventCalendarItems(
  {
    eventCalendar,
    ...queryParameters
  }: Omit<IEventCalendarItemProps, 'eventCalendarItem'> = {},
  settings?: IUseQueryOptions<DataDocument<EventCalendarItem[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { eventCalendar: defaultEventCalendar },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(eventCalendar || defaultEventCalendar),
      baseName: 'v1.eventCalendars.eventCalendarItems',
      baseUri: `/v1/event_calendars/${eventCalendar || defaultEventCalendar}/items`,
      invalidate: [
        'event_calendar_items',
        'event_calendars_item',
        'items',
        'item',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<EventCalendarItem, EventCalendarItemCreateInput>(
    request,
    settings
  );
}
