import { useCallback } from 'react';
import { useAnswerGroup } from '@brainstud/academy-api/Hooks/useAnswerGroups';
import { AnswerStatus } from '@brainstud/academy-api/Types/Resources/Answer';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { useForm } from '@brainstud/universal-components/Components/Form/useForm';
import { useRouter } from 'next/router';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider/useAnswerGroupProvider';
import { useLearningSubjectProvider } from 'Providers/LearningSubjectProvider/useLearningSubjectProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';

/**
 * useAnswerGroupTurnInConfirmation.
 *
 * With this hook you can safely submit an answer group. It will save the current answer on click, and will
 * set the answer group to turned in.
 */
export function useAnswerGroupTurnInConfirmation() {
  const [t] = useTranslator();
  const router = useRouter();
  const { openModal, closeModal } = useModals();

  const { learningSubject } = useLearningSubjectProvider();
  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};
  const { save, reset } = useForm();

  const [{ createOrUpdate }] = useAnswerGroup(
    {
      answerGroup: currentAnswerGroup?.id,
    },
    { enabled: false }
  );

  const [setToast] = useToaster();
  return useCallback(
    (currentItemId?: string, status: AnswerStatus = 'TURNED_IN') => {
      save(
        { status: 'CONCEPT' },
        {
          onSettled: () => {
            openModal(ConfirmationModal, {
              title: t('views.collection.quiz.submit_quiz_modal.title'),
              description: t(
                'views.collection.quiz.submit_quiz_modal.description'
              ),
              yes: t('views.collection.quiz.submit_quiz_modal.title'),
              no: t('cancel'),
              handleConfirm: () =>
                createOrUpdate.mutateAsync(
                  {
                    status,
                  },
                  {
                    onSuccess: () => {
                      reset();
                      closeModal();
                      router.push(
                        router.asPath?.replace(
                          `/detail/${currentItemId}`,
                          `/subjects/${learningSubject?.id}`
                        )
                      );
                    },
                    onError: (response) => {
                      setToast(
                        response?.errors?.[0]?.title ||
                          'Unknown error occurred.'
                      );
                    },
                  }
                ),
            });
          },
        }
      );
    },
    [
      save,
      openModal,
      t,
      createOrUpdate,
      reset,
      closeModal,
      router,
      learningSubject?.id,
      setToast,
    ]
  );
}
