import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  AnswerFeedback,
  AnswerFeedbackCreateInput,
  AnswerFeedbackFilters,
  AnswerFeedbackIncludes,
  AnswerFeedbackSorts,
  AnswerFeedbackUpdateInput,
} from '../Types/Resources/AnswerFeedback';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IAnswerFeedbackProps
  extends HookParams<
    typeof AnswerFeedbackIncludes,
    typeof AnswerFeedbackFilters,
    typeof AnswerFeedbackSorts
  > {
  answer?: UUID;
  answerFeedback?: UUID;
}

/**
 * Use this hook for saving feedback from a coach on an answer given by a student.
 */
export function useAnswerFeedback(
  { answer, answerFeedback, ...queryParameters }: IAnswerFeedbackProps = {},
  settings?: IUseQueryOptions<DataDocument<AnswerFeedback>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      answerFeedback: defaultAnswerFeedback,
      answer: defaultAnswer,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(answerFeedback || defaultAnswerFeedback) &&
        !!(answer || defaultAnswer),
      baseName: 'v1.answers.feedback',
      baseUri: `/v1/answers/${answer || defaultAnswer}/feedback`,
      uri: `/${answerFeedback || defaultAnswerFeedback}`,
      invalidate: [
        'answers',
        'answer',
        'feedback',
        'answers.feedback',
        'answer.feedback',
        'v1.answers',
        'v1.enrollments',
        'learning_objects',
        'learning_object',
        'v1.enrollment.learningObject.activities',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    AnswerFeedback,
    AnswerFeedbackCreateInput,
    AnswerFeedbackUpdateInput
  >(request, settings);
}
