import { useMemo } from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { Flag, House, LocationOn, Monitor, Star } from '@mui/icons-material';
import { ReactComponent as MeetupIcon } from 'Assets/Icons/meetup-icon.svg';
import { normalizeLearningObjectMetadata } from '../Utils/normalizeLearningObjectMetadata';

export type TTag = {
  name: string;
  type: string;
  value?: number | string | boolean | undefined;
};

export const BADGES = [
  'meetup',
  'workplace',
  'checkpoint',
  'interimFinalAssignment',
];
export const AVAILABLE_TAGS = [
  'groupSize',
  'theory',
  'desktopRequired',
  'remoteLearningSuitable',
  'xp',
  'nfcUnlocked',
];

export const OBJECT_META_TAG_ICONS = {
  desktopRequired: Monitor,
  remoteLearningSuitable: House,
  meetup: MeetupIcon,
  workplace: LocationOn,
  interimFinalAssignment: Star,
  checkpoint: Flag,
};

export const filterObjectTags = (tag: TTag) => {
  if (!AVAILABLE_TAGS.includes(tag.name)) return false;
  if (tag.type === 'groupSize' && parseFloat((tag.value || 0).toString()) > 1)
    return true;
  if (tag.type === 'xp' && Math.ceil(Number(tag.value)) === 0) return false;
  return true;
};

export const filterObjectBadges = (tag: TTag) => BADGES.includes(tag.name);

export const useObjectTags = (object?: LearningObject) => {
  const meta = normalizeLearningObjectMetadata(object);
  return useMemo(
    () =>
      Object.keys(meta).reduce((items, item) => {
        const value = meta[item as keyof typeof meta];
        if (value !== undefined && value !== false && value !== null) {
          return [
            ...items,
            {
              name: item,
              type: item,
              ...(typeof value !== 'boolean' ? { value } : {}),
            },
          ];
        }
        return items;
      }, [] as TTag[]),
    [meta]
  );
};
