import React from 'react';
import { Progress } from '@brainstud/ui/Static/Progress';
import classNames from 'classnames/bind';
import { useEnrollmentByCourseId } from 'Hooks/useEnrollmentByCourseId';
import { useTranslator } from 'Providers/Translator';
import { toDecimals } from 'Utils/toDecimals';
import styles from './ProgressSection.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
};

/**
 * Shows the bar containing the progress the participant has made with the current course
 */
export const ProgressSection = ({ title }: Props) => {
  const { enrollment, isLoading } = useEnrollmentByCourseId();
  const [t] = useTranslator();

  const xp = {
    done: enrollment?.progress?.xp?.totalReceived || 0,
    total: enrollment?.progress?.xp?.totalAvailable || 0,
  };
  const examBar = enrollment?.progress?.exam;
  const examPercentage =
    examBar?.type === 'xp'
      ? Math.round((examBar.amount / xp.total) * 100)
      : examBar?.amount;
  const xpRequirement = (
    Math.ceil(((examPercentage || 0) / 10) * xp.total) / 10
  )
    .toString()
    .replace('.', ',');
  const markers = examPercentage
    ? [
        {
          step: examPercentage,
          tooltip: `${t('layouts.progress_section.final_assignment')} (${xpRequirement}XP)`,
        },
      ]
    : [];

  return (
    <section className={cx(styles.base)}>
      <div className={cx(styles.container)}>
        <div className={cx(styles.title)}>
          <h1>{title}</h1>
        </div>
        {xp.total > 0 && (
          <div className={cx(styles.progress)}>
            <Progress
              value={(Number(xp.done) / xp.total) * 100}
              loading={isLoading}
              markers={markers}
              label={
                <span>
                  <strong>{toDecimals(xp.done, 1)}</strong>
                  {` / ${xp.total}XP`}
                </span>
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};
