import React from 'react';
import { Block } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { Tips } from 'Modules/action-based-learning-panel/Components/Tips';
import { BlockRenderer } from 'Modules/course-blocks/Components/BlockRenderer';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import styles from './OpenView.module.css';

const cx = classNames.bind(styles);

type Props = {
  blocks: Block[];
};

export const OpenView: LayoutComponent<Props> = ({ blocks }) => {
  const { pageTree } = useLearningRouteProvider();
  const { currentItem } = pageTree;
  const { variety } = useLearningObjectProvider();

  const tips = !Array.isArray(variety?.content)
    ? variety?.content?.tips
    : variety?.tips;

  return !currentItem ? (
    <Loading />
  ) : (
    <Container>
      {tips && (
        <div className={cx(styles.tipsWrapper)}>
          <Tips>{tips}</Tips>
        </div>
      )}
      <h1 className={cx(styles.title)}>{currentItem?.title}</h1>
      {blocks.map((block) => (
        <BlockRenderer key={block.id} id={currentItem?.id} block={block} />
      ))}
    </Container>
  );
};
