import React, { ReactNode } from 'react';
import { StatusColor } from '@brainstud/ui/Static/Status';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import styles from './RoundLink.module.css';

const cx = classNames.bind(styles);

type Props = {
  href: string;
  children: ReactNode;
  scheme?: StatusColor;
};

export const RoundLink = ({ href, children, scheme }: Props) => (
  <Link exact href={href} className={cx(styles.base, scheme)}>
    {children}
  </Link>
);
