import { lazy } from 'react';

export const ToolArrayForms = {
  AccordionForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Container/Accordion/AccordionForm'
    ).then((mod) => ({ default: mod.AccordionForm }))
  ),
  AudioForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/Audio/AudioForm').then(
      (mod) => ({ default: mod.AudioForm })
    )
  ),
  CalloutForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/Callout/CalloutForm').then(
      (mod) => ({ default: mod.CalloutForm })
    )
  ),
  CardsWidgetForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Input/CardsWidget/CardsWidgetForm'
    ).then((mod) => ({ default: mod.CardsWidgetForm }))
  ),
  ColumnForm: lazy(() =>
    import('Modules/course-editor/Blocks/Container/Column/ColumnForm').then(
      (mod) => ({ default: mod.ColumnForm })
    )
  ),
  DragAndDropForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Input/DragAndDrop/DragAndDropForm'
    ).then((mod) => ({ default: mod.DragAndDropForm }))
  ),
  DragToSortForm: lazy(() =>
    import('Modules/course-editor/Blocks/Input/DragToSort/DragToSortForm').then(
      (mod) => ({ default: mod.DragToSortForm })
    )
  ),
  FileUploadForm: lazy(() =>
    import('Modules/course-editor/Blocks/Input/FileUpload/FileUploadForm').then(
      (mod) => ({ default: mod.FileUploadForm })
    )
  ),
  FormulaForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/Formula/FormulaForm').then(
      (mod) => ({ default: mod.FormulaForm })
    )
  ),
  GroupForm: lazy(() =>
    import('Modules/course-editor/Blocks/Container/Group/GroupForm').then(
      (mod) => ({ default: mod.GroupForm })
    )
  ),
  HTMLCodeForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/HTMLCode/HTMLCodeForm').then(
      (mod) => ({ default: mod.HTMLCodeForm })
    )
  ),
  InputForm: lazy(() =>
    import('Modules/course-editor/Blocks/Input/Input/InputForm').then(
      (mod) => ({ default: mod.InputForm })
    )
  ),
  LtiResourceLinkBlockForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Static/LtiResourceLinkForm/LtiResourceLinkBlockForm'
    ).then((mod) => ({ default: mod.LtiResourceLinkBlockForm }))
  ),
  MultipleChoiceForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Input/MultipleChoice/MultipleChoiceForm'
    ).then((mod) => ({ default: mod.MultipleChoiceForm }))
  ),
  PanelForm: lazy(() =>
    import('Modules/course-editor/Blocks/Container/Panel/PanelForm').then(
      (mod) => ({ default: mod.PanelForm })
    )
  ),
  ReactWidgetForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Static/ReactWidget/ReactWidgetForm'
    ).then((mod) => ({ default: mod.ReactWidgetForm }))
  ),
  RichImageForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/RichImage/RichImageForm').then(
      (mod) => ({ default: mod.RichImageForm })
    )
  ),
  RichListForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/RichList/RichListForm').then(
      (mod) => ({ default: mod.RichListForm })
    )
  ),
  RichMediaForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/RichMedia/RichMediaForm').then(
      (mod) => ({ default: mod.RichMediaForm })
    )
  ),
  RichTitleForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/RichTitle/RichTitleForm').then(
      (mod) => ({ default: mod.RichTitleForm })
    )
  ),
  ScormForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/ScormForm').then((mod) => ({
      default: mod.ScormForm,
    }))
  ),
  SingleChoiceForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Input/SingleChoice/SingleChoiceForm'
    ).then((mod) => ({ default: mod.SingleChoiceForm }))
  ),
  SliderForm: lazy(() =>
    import('Modules/course-editor/Blocks/Container/Slider/SliderForm').then(
      (mod) => ({ default: mod.SliderForm })
    )
  ),
  SubtitleForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/Subtitle/SubtitleForm').then(
      (mod) => ({ default: mod.SubtitleForm })
    )
  ),
  TabbarContainerForm: lazy(() =>
    import(
      'Modules/course-editor/Blocks/Container/TabbarContainer/TabbarContainerForm'
    ).then((mod) => ({ default: mod.TabbarContainerForm }))
  ),
  TextareaForm: lazy(() =>
    import('Modules/course-editor/Blocks/Input/Textarea/TextAreaForm').then(
      (mod) => ({ default: mod.TextareaForm })
    )
  ),
  VideoForm: lazy(() =>
    import('Modules/course-editor/Blocks/Static/Video/VideoForm').then(
      (mod) => ({ default: mod.VideoForm })
    )
  ),
};
