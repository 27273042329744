import React, { useCallback, useMemo, useState } from 'react';
import { useEventCalendarItemEnrollment } from '@brainstud/academy-api/Hooks/useEventCalendarItemEnrollment';
import { useEventCalendarItem } from '@brainstud/academy-api/Hooks/useEventCalendarItems';
import { useEventCalendar } from '@brainstud/academy-api/Hooks/useEventCalendars';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Input } from '@brainstud/ui/Form Input/Input';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Form } from '@brainstud/universal-components/Components/Form';
import Grid from '@mui/material/Grid';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { ReactComponent as Check } from '../../Assets/svgs/check.svg';
import { BackButton } from '../../Components/BackButton';
import { withBase } from '../../Layouts';
import styles from './EventView.module.css';

const cx = classNames.bind(styles);

export const EventView = withBase(() => {
  const [t] = useTranslator();
  const [loading, setLoading] = useState(false);
  const [unenrolled, setUnenrolled] = useState(false);
  const router = useRouter();
  const { calendarId, eventId } = router.query as {
    calendarId: string;
    eventId: string;
  };
  const [{ data: event }] = useEventCalendarItem({
    eventCalendar: calendarId,
    eventCalendarItem: eventId,
    include: ['current_user_enrollment'],
  });
  const [{ data: calendar }] = useEventCalendar({ eventCalendar: calendarId });

  const enrollment = useMemo(
    () => event?.eventCalendarItemEnrollment?.(),
    [event]
  );

  const [{ create: enroll, destroy: unenroll }] =
    useEventCalendarItemEnrollment(
      {
        eventCalendarItem: event?.id,
        eventCalendarItemEnrollment: enrollment?.id,
      },
      { enabled: false }
    );

  const handleEnrollment = useCallback(
    async (email, phone) => {
      await enroll.mutateAsync({ email_address: email, phone_number: phone });
      setLoading(false);
    },
    [enroll]
  );

  const handleUnenrollment = useCallback(async () => {
    await unenroll.mutateAsync();
    setUnenrolled(true);
    setLoading(false);
  }, [unenroll]);

  const handleSubmit = useCallback(
    ({ email, phone }) => {
      setLoading(true);
      if (!enrollment) {
        handleEnrollment(email, phone);
      } else {
        handleUnenrollment();
      }
    },
    [handleEnrollment, handleUnenrollment, enrollment]
  );

  useBreadcrumbs(
    {
      ...(event && { [event.id]: event.title }),
      ...(calendar && { [calendar.id]: calendar.title }),
    },
    [event, calendar]
  );

  if (!event) return <Loading />;

  return (
    <Container className={cx('base')}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <h2>
            <BackButton />
            {event.title}
            <span>({event.category})</span>
          </h2>
          <p>{event.description}</p>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Panel>
            <Form className={cx('form')} onSubmit={handleSubmit} language="nl">
              <dl>
                <dt>{t('views.event.date')}</dt>
                <dd>
                  {DateFormatter.toPeriod(event.startDate, event.endDate)}
                </dd>
                {event.locationDetails && (
                  <>
                    <dt>{t('views.event.location')}</dt>
                    <dd>{event.locationDetails}</dd>
                  </>
                )}
              </dl>
              {(() => {
                if (loading) return <Loading />;
                if (
                  new Date().getTime() >
                    new Date(event.enrollmentCloseDate).getTime() &&
                  !enrollment
                ) {
                  return (
                    // late
                    <div>
                      <h3>{t('views.event.enrollment_closed.title')}</h3>
                      <p>{t('views.event.enrollment_closed.description')}</p>
                    </div>
                  );
                }
                if (!enrollment && !unenrolled) {
                  return (
                    // form to enroll
                    <div>
                      <h3>{t('views.event.enroll')}</h3>
                      <p className={cx('enrollTillText')}>
                        {t('views.event.open_until', {
                          date: DateFormatter.toDayDateTime(
                            event.enrollmentCloseDate
                          ),
                        })}
                      </p>
                      <Input
                        className={cx('modal-input')}
                        name="email"
                        label={t('email_address')}
                        type="email"
                        rules="is_email"
                      />
                      <Input
                        className={cx('modal-input')}
                        name="phone"
                        label={t('phone')}
                        type="tel"
                      />
                      <Button className={cx('enroll-button')} loading={loading}>
                        {!enrollment ? t('confirm') : t('unsubscribe')}
                      </Button>
                    </div>
                  );
                }
                if (enrollment) {
                  return (
                    // button to unenroll
                    <div>
                      <h3 className={cx('enrolled-title')}>
                        <Check />
                        <span>{t('views.event.enrolled')}</span>
                        <span>
                          {DateFormatter.toDateTime(
                            enrollment?.date,
                            "dd MMMM 'om' hh:mm"
                          )}
                        </span>
                      </h3>
                      <p style={{ margin: '3rem 0' }}>
                        {t('views.event.enroll_confirmation')}
                      </p>
                      <Button
                        outline
                        onClick={handleUnenrollment}
                        className={cx('unenroll-button')}
                      >
                        {t('views.event.unenroll')}
                      </Button>
                    </div>
                  );
                }
                if (!enrollment && unenrolled) {
                  return (
                    // Unenrolled a second ago
                    <div>
                      <h3 className={cx('unenrolled-title')}>
                        <Check />
                        <span>{t('views.event.unenrolled.title')}</span>
                      </h3>
                      <p style={{ margin: '3rem 0' }}>
                        {t('views.event.unenrolled.description')}
                      </p>
                    </div>
                  );
                }
              })()}
            </Form>
          </Panel>
        </Grid>
      </Grid>
    </Container>
  );
});
