import React from 'react';
import { Check } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames/bind';
import styles from './CircularProgressIndicator.module.css';

const cx = classNames.bind(styles);

type Props = {
  current: number;
  total: number;
  className?: string;
  size?: number;
  completed?: boolean;
  scheme?: 'red' | 'green';
  suffix?: string;
};

/**
 * A progress indicator in the form of a circle
 */
export const CircularProgressIndicator = ({
  current,
  total,
  className,
  size = 0,
  completed,
  scheme,
  suffix,
}: Props) => (
  <div className={cx(styles.base, className)}>
    <CircularProgress
      variant="determinate"
      value={100}
      size={size}
      className={styles.fullCircle}
    />
    <CircularProgress
      variant="determinate"
      value={(current / total) * 100}
      size={size}
      className={cx(styles.progressCircle, scheme)}
    />
    <span
      className={styles.innerText}
      /* @ts-ignore CSS Variables are not supported by TypeScript */
      style={{ '--circular_progress_indicator__size': `${size}px` }}
    >
      <span className={styles.current}>
        {Number.isInteger(current) ? current : current.toFixed(1)}
      </span>
      /{total}
      {suffix}
    </span>
    {completed && (
      <div className={cx(styles.completed)}>
        <Check />
      </div>
    )}
  </div>
);
