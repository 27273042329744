import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningRouteNode,
  LearningRouteNodeCreateInput,
  LearningRouteNodeFilters,
  LearningRouteNodeIncludes,
  LearningRouteNodeSorts,
  LearningRouteNodeUpdateInput,
} from '../Types/Resources/LearningRouteNode';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningRouteNodeProps
  extends HookParams<
    typeof LearningRouteNodeIncludes,
    typeof LearningRouteNodeFilters,
    typeof LearningRouteNodeSorts
  > {
  learningRoute?: UUID;
  node?: UUID;
}

export function useLearningRouteNode(
  { learningRoute, node, ...queryParameters }: ILearningRouteNodeProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningRouteNode>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningRoute: defaultLearningRoute,
      learningRouteNode: defaultLearningRouteNode,
    },
  } = context;

  const request = new ApiRequest(
    {
      enabled:
        !!(learningRoute || defaultLearningRoute) &&
        !!(node || defaultLearningRouteNode),
      baseName: 'v1.learningRoutes.nodes',
      baseUri: `/v1/learning_routes/${learningRoute || defaultLearningRoute}/nodes`,
      uri: `/${node || defaultLearningRouteNode}`,
      invalidate: [
        'learning_route_nodes',
        'learning_route_node',
        'learning_route.nodes',
        'learning_route.node',
        'learning_routes',
        'learning_route',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<LearningRouteNode, LearningRouteNodeUpdateInput>(
    request,
    settings
  );
}

export function useLearningRouteNodes(
  { learningRoute, ...queryParameters }: ILearningRouteNodeProps = {},
  settings?: IUseQueryOptions<DataDocument<LearningRouteNode[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { learningRoute: defaultLearningRoute },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(learningRoute || defaultLearningRoute),
      baseName: 'v1.learningRoutes.nodes',
      baseUri: `/v1/learning_routes/${learningRoute || defaultLearningRoute}/nodes`,
      invalidate: [
        'learning_route_nodes',
        'learning_route_node',
        'learning_route.nodes',
        'learning_route.node',
        'learning_routes',
        'learning_route',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningRouteNode, LearningRouteNodeCreateInput>(
    request,
    settings
  );
}
