/* eslint-disable class-methods-use-this */
import { IGroupContainerBlock } from '@brainstud/course-blocks/Blocks/Container/GroupContainer/Types';
import { TTab } from '@brainstud/course-blocks/Blocks/Container/TabbarContainer/Types';
import { v4 as uuid } from 'uuid';
import { UnknownBlock } from '../Types';
import { Migration } from './BaseMigration';

/**
 * This migration changes the TabbarContainer structure to
 * an actual container structure.
 */
export class TabbarContainerV1Migration extends Migration {
  protected beforeDate = new Date(2022, 2, 1);

  name = 'TabbarContainerV1Migration';

  isLegacy(block: UnknownBlock): boolean {
    return (
      block.type === 'tabbar_container' &&
      'tabs' in block &&
      !('blocks' in block)
    );
  }

  run() {
    return this.forEachLegacyBlock((input) => ({
      id: input.id,
      type: 'tabbar_container' as const,
      blocks: (input.tabs || []).map((item: TTab) => ({
        id: uuid(),
        type: 'tab_container' as const,
        title: item.title,
        blocks: ['group_container', 'block_container'].includes(item.block.type)
          ? (item.block as IGroupContainerBlock)?.blocks
          : [item?.block],
        style: (item.block as IGroupContainerBlock)?.style,
        className: (item.block as IGroupContainerBlock).className,
      })),
    }));
  }
}
