import React, { useCallback, useState } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Badge } from '@brainstud/ui/Static/Badge';
import { Panel } from '@brainstud/ui/Static/Panel';
import classNames from 'classnames/bind';
import { CircularProgressIndicator } from 'Components/CircularProgressIndicator';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useProgressCalculator } from 'Hooks/ProgressCalculator/useProgressCalculator';
import { BackButton } from 'Modules/action-based-learning-panel/Components/BackButton';
import { useRouter } from 'next/router';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider/useAnswerGroupProvider';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useRouteNavigation } from 'Providers/LearningRouteProvider/useRouteNavigation';
import { useLearningSubjectProvider } from 'Providers/LearningSubjectProvider/useLearningSubjectProvider';
import { useTranslator } from 'Providers/Translator';
import { getKeyWithTrue } from 'Utils/getKeyWithTrue';
import { sanitizer } from 'Utils/Sanitizer';
import { toDecimals } from 'Utils/toDecimals';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import { QuizResult } from './QuizResult';
import styles from './SubjectQuizView.module.css';

const cx = classNames.bind(styles);

type Props = {
  subject: ILearningSubjectWithResources;
};

export const SubjectQuizView: LayoutComponent<Props> = ({ subject }) => {
  const [t] = useTranslator();
  const router = useRouter();
  const instructionPoints = t(
    'views.collection.quiz.instruction.points_default'
  ) as string[];

  const { quiz, subnodes } = useLearningSubjectProvider();
  const { nextItem } = useRouteNavigation(subnodes, 'learning_subjects');
  const quizResult = useProgressCalculator(subject);

  const { currentAnswerGroup, answerGroups, createNewAnswerGroup } =
    useAnswerGroupProvider();
  const isAnswerGroupConcept = currentAnswerGroup?.status === 'CONCEPT';
  const isAccepted = currentAnswerGroup?.status === 'ACCEPTED';
  const { isStudentEnvironment } = useEnvironmentProvider();

  const [loading, setLoading] = useState(false);
  const handleStartQuiz = useCallback(() => {
    if (!isAnswerGroupConcept && isStudentEnvironment) {
      setLoading(true);
      createNewAnswerGroup().then(() => {
        router.push(
          router.asPath.replace(
            `/subjects/${subject.id}`,
            `/detail/${nextItem?.id}`
          )
        );
      });
    } else {
      router.push(
        router.asPath.replace(
          `/subjects/${subject.id}`,
          `/detail/${nextItem?.id}`
        )
      );
    }
  }, [
    isAnswerGroupConcept,
    nextItem?.id,
    createNewAnswerGroup,
    router,
    subject.id,
    isStudentEnvironment,
  ]);

  const startButtonText = getKeyWithTrue({
    start_quiz: !currentAnswerGroup || !isStudentEnvironment,
    continue_quiz: isAnswerGroupConcept && isStudentEnvironment,
    restart_quiz:
      !isAnswerGroupConcept && !!currentAnswerGroup && isStudentEnvironment,
  });

  const isInReview =
    isStudentEnvironment &&
    currentAnswerGroup &&
    currentAnswerGroup.status === 'TURNED_IN';

  return (
    <Container pad>
      <header className={cx(styles.quizHeader)}>
        <BackButton />
        <h2>{subject.title}</h2>
        {quizResult.xpAvailable !== 0 && (
          <Badge color="green">
            {toDecimals(quizResult.xpAvailable, 1)}
            {t('XP')}
          </Badge>
        )}
      </header>

      {loading ? (
        <Loading />
      ) : (
        <Panel className={cx(styles.base)}>
          <div className={cx(styles.inner)}>
            <div className={cx(styles.intro)}>
              {subject.introduction && (
                <div>
                  <h3>{t('views.collection.quiz.introduction')}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(subject?.introduction),
                    }}
                  />
                </div>
              )}
              <div>
                <h3>{t('views.collection.quiz.instruction.title')}</h3>
                <ul>
                  {instructionPoints.map((bulletPoint) => (
                    <li key={bulletPoint}>{bulletPoint}</li>
                  ))}
                </ul>
                <aside className={cx(styles.quizInfo)}>
                  <strong>
                    {t('views.collection.quiz.assignments', {
                      questions: quizResult.totalObjects || 0,
                    })}
                  </strong>
                  <strong>
                    {t('views.collection.quiz.minimum_score', {
                      minimumScore: quiz?.minimalScore || 0,
                    })}
                  </strong>
                </aside>
              </div>
              <Button
                type="button"
                loading={loading}
                onClick={handleStartQuiz}
                className={cx(styles.startButton)}
                disabled={isInReview}
              >
                {t(`views.collection.quiz.${startButtonText}`)}
              </Button>
            </div>
            {/* Score */}
            <div className={cx(styles.scoreWrapper)}>
              <div className={cx(styles.score)}>
                {!currentAnswerGroup ||
                currentAnswerGroup?.status === 'TURNED_IN' ? (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={styles['unknown-score-icon']}
                  >
                    <circle
                      opacity="0.15"
                      cx="14"
                      cy="14"
                      r="14"
                      fill="currentColor"
                    />
                    <path
                      d="M14.09 19.2273C14.3293 19.2273 14.5312 19.145 14.6957 18.9805C14.8603 18.816 14.9425 18.6141 14.9425 18.3747C14.9425 18.1354 14.8603 17.9335 14.6957 17.769C14.5312 17.6045 14.3293 17.5222 14.09 17.5222C13.8507 17.5222 13.6487 17.6045 13.4842 17.769C13.3197 17.9335 13.2374 18.1354 13.2374 18.3747C13.2374 18.6141 13.3197 18.816 13.4842 18.9805C13.6487 19.145 13.8507 19.2273 14.09 19.2273ZM13.3047 15.9517H14.6284C14.6284 15.5628 14.6771 15.2075 14.7743 14.886C14.8715 14.5644 15.1744 14.1942 15.6829 13.7754C16.1466 13.3865 16.4757 13.0051 16.6701 12.6312C16.8646 12.2572 16.9618 11.8459 16.9618 11.3972C16.9618 10.6045 16.7038 9.96877 16.1877 9.49013C15.6717 9.0115 14.9874 8.77219 14.1349 8.77219C13.4019 8.77219 12.755 8.95541 12.1941 9.32186C11.6333 9.68832 11.2257 10.1931 10.9714 10.8363L12.1605 11.285C12.325 10.8662 12.5718 10.5409 12.9009 10.309C13.2299 10.0772 13.6188 9.96129 14.0675 9.96129C14.5761 9.96129 14.9874 10.0996 15.3015 10.3764C15.6156 10.6531 15.7727 11.0083 15.7727 11.4421C15.7727 11.7711 15.6755 12.0815 15.481 12.3731C15.2866 12.6648 15.0024 12.9677 14.6284 13.2818C14.1797 13.6707 13.8469 14.0558 13.63 14.4372C13.4132 14.8187 13.3047 15.3235 13.3047 15.9517ZM14.0002 22.9741C12.7737 22.9741 11.6146 22.7385 10.5227 22.2674C9.43079 21.7962 8.47727 21.1531 7.6621 20.3379C6.84693 19.5227 6.20376 18.5692 5.73261 17.4773C5.26146 16.3854 5.02588 15.2262 5.02588 13.9997C5.02588 12.7583 5.26146 11.5916 5.73261 10.4997C6.20376 9.40787 6.84693 8.45808 7.6621 7.65039C8.47727 6.8427 9.43079 6.20327 10.5227 5.73212C11.6146 5.26097 12.7737 5.02539 14.0002 5.02539C15.2417 5.02539 16.4084 5.26097 17.5002 5.73212C18.5921 6.20327 19.5419 6.8427 20.3496 7.65039C21.1573 8.45808 21.7967 9.40787 22.2679 10.4997C22.739 11.5916 22.9746 12.7583 22.9746 13.9997C22.9746 15.2262 22.739 16.3854 22.2679 17.4773C21.7967 18.5692 21.1573 19.5227 20.3496 20.3379C19.5419 21.1531 18.5921 21.7962 17.5002 22.2674C16.4084 22.7385 15.2417 22.9741 14.0002 22.9741Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  <CircularProgressIndicator
                    scheme={isAccepted ? 'green' : 'red'}
                    suffix="%"
                    current={Number(currentAnswerGroup.score)}
                    total={100}
                    size={130}
                  />
                )}

                {currentAnswerGroup &&
                currentAnswerGroup.status !== 'TURNED_IN' ? (
                  isAccepted ? (
                    <strong>{t('views.collection.quiz.completed')}</strong>
                  ) : (
                    <strong>{t('views.collection.quiz.rejected')}</strong>
                  )
                ) : (
                  <strong>{t('views.collection.quiz.no_score')}</strong>
                )}
              </div>
            </div>
          </div>

          {answerGroups.length > 0 && (
            <section className={styles.results}>
              <h4>{t('attempts')}</h4>
              {!isAnswerGroupConcept &&
                answerGroups.map((answerGroup, idx) => (
                  <Panel key={answerGroup.id} className={styles.result}>
                    <QuizResult
                      subject={subject}
                      answerGroup={answerGroup}
                      latest={idx === 0}
                    />
                  </Panel>
                ))}
            </section>
          )}
        </Panel>
      )}
    </Container>
  );
};
