import React from 'react';
import { IconOverlay } from 'Components/Cards/LearningCard/IconOverlay';
import { OBJECT_META_TAG_ICONS, TTag } from 'Hooks/useObjectTags';
import { useTranslator } from 'Providers/Translator';
import styles from './IconTags.module.css';

type Props = {
  tags: TTag[];
  isLocked?: boolean;
  openOnHover?: boolean;
};

export const ObjectCardIconTags = ({
  tags,
  isLocked,
  openOnHover = true,
}: Props) => {
  const [t] = useTranslator();
  const hasCheckpoint = tags.some((tag) => tag.name === 'checkpoint');
  const hasWorkplace = tags.some((tag) => tag.name === 'workplace');
  const hasMeetup = tags.some((tag) => tag.name === 'meetup');
  const hasInterimFinalAssignment = tags.some(
    (tag) => tag.name === 'interimFinalAssignment'
  );

  return (
    <>
      {hasInterimFinalAssignment && (
        <IconOverlay
          icon={OBJECT_META_TAG_ICONS.interimFinalAssignment}
          className={styles.exam}
          label={t(
            'views.collection.object.metadata.interim_final_assignment.title'
          )}
          isLocked={isLocked}
          openOnHover={openOnHover}
        />
      )}
      {hasCheckpoint && (
        <IconOverlay
          icon={OBJECT_META_TAG_ICONS.checkpoint}
          className={styles.checkpoint}
          label={t('views.collection.object.metadata.checkpoint.title')}
          isLocked={isLocked}
          openOnHover={openOnHover}
        />
      )}
      {hasWorkplace && (
        <IconOverlay
          icon={OBJECT_META_TAG_ICONS.workplace}
          className={styles.location}
          label={t('views.collection.object.metadata.on_location.title')}
          isLocked={isLocked}
          openOnHover={openOnHover}
        />
      )}
      {hasMeetup && (
        <IconOverlay
          icon={OBJECT_META_TAG_ICONS.meetup}
          className={styles.meetup}
          label={t('views.collection.object.metadata.meetup.title')}
          isLocked={isLocked}
          openOnHover={openOnHover}
        />
      )}
    </>
  );
};
