import React from 'react';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { FooterNavigation } from '../../FooterNavigation';

export const LinearFooter = () => {
  const {
    pageTree: { nextItem, previousItem, currentItem },
  } = useLearningRouteProvider();

  return (
    <FooterNavigation
      nextItem={nextItem}
      previousItem={previousItem}
      currentItem={currentItem}
    />
  );
};
