import type { SvgIconTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import classNames from 'classnames/bind';
import styles from './IconOverlay.module.css';

const cx = classNames.bind(styles);

export type TIconOverlay = {
  label: string;
  icon:
    | (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName?: string })
    | null;
  className?: string;
  isLocked?: boolean;
  openOnHover?: boolean;
};

export const IconOverlay = ({
  icon: Icon,
  label,
  className,
  isLocked,
  openOnHover,
}: TIconOverlay) => (
  <span
    className={cx(styles.base, className, { isLocked, hover: !openOnHover })}
  >
    {Icon && <Icon />}
    {openOnHover && (
      <div className={styles.overlayInner}>
        <span>{label}</span>
      </div>
    )}
  </span>
);
