import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  ExamStepApproval,
  ExamStepApprovalCreate,
} from '../Types/Resources/ExamStepApproval';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IExamStepApprovalProps {
  examStepApproval?: UUID;
  exam?: UUID;
}

export function useExamStepApproval(
  { exam, examStepApproval, ...queryParameters }: IExamStepApprovalProps = {},
  settings?: IUseQueryOptions<DataDocument<ExamStepApproval>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      examStepApproval: defaultExamStepApproval,
      exam: defaultExam,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(examStepApproval || defaultExamStepApproval) &&
        !!(exam || defaultExam),
      baseName: 'v1.examStepApprovals',
      baseUri: `/v1/exams/${exam || defaultExam}/step_approvals`,
      invalidate: ['step_approvals', 'step_approval', 'exam', 'exams'],
      uri: `/${examStepApproval || defaultExamStepApproval}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<ExamStepApproval, ExamStepApprovalCreate, void>(
    request,
    settings
  );
}
