import React, { FC, PropsWithChildren } from 'react';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import classNames from 'classnames/bind';
import { useBreakpoints } from 'Hooks/useBreakpoints';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { BackButton } from '../../../Components/BackButton';
import VideoLink from '../../../Utils/VideoLink';
import styles from './SubjectView.module.css';

const cx = classNames.bind(styles);

type TProps = {
  subject: ILearningSubjectWithResources | LearningSubject;
  progress?: {
    total: number;
    done: number;
  };
};

/**
 * A generic component for loading the heading of a subject
 */
export const SubjectTopView: FC<PropsWithChildren<TProps>> = ({
  subject,
  progress,
}) => {
  const [t] = useTranslator();
  // @ts-ignore FIXME When poster is added
  const subjectImage = subject?.poster;

  useBreadcrumbs(
    {
      subjects: null,
      ...(subject && { [subject?.id]: subject.title }),
    },
    [subject]
  );

  const { xsDown, smUp } = useBreakpoints();

  return (
    <div style={{ marginTop: '2rem' }}>
      <BackButton />
      <h1>{subject.title}</h1>
      {progress && xsDown && (
        <p className={cx('assignments-done', 'title')}>
          {`${progress.done}/${progress.total} ${t('views.collection.subject.assignments_finished')}`}
        </p>
      )}
      <div className={cx('title-main')}>
        {smUp &&
          /* eslint-disable-next-line no-nested-ternary */
          (subjectImage?.type === 'embed' ? (
            <div className={cx('title-image')}>
              <iframe
                className={cx('yt-video')}
                src={new VideoLink(subjectImage.src).getEmbedLink()}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title={`Video preview ${subject.title}`}
              />
            </div>
          ) : (
            <img
              src={
                !subjectImage
                  ? '/media/abstract-thumbnail.jpg'
                  : subjectImage.src
              }
              alt="Placeholder"
              className={cx(styles.image)}
            />
          ))}
        <div
          className={styles.base}
          dangerouslySetInnerHTML={{ __html: sanitizer(subject?.introduction) }}
        />
      </div>
    </div>
  );
};
