import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnrollment } from '@brainstud/academy-api/Hooks/useEnrollments';
import { useExam } from '@brainstud/academy-api/Hooks/useExams';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { UploadedFile } from '@brainstud/course-blocks/Components/UploadedFile';
import { Button } from '@brainstud/ui/Buttons/Button';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Status } from '@brainstud/ui/Static/Status';
import {
  AccessTime,
  ArrowBack,
  ArrowForward,
  Check,
} from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers/BreadcrumbsProvider/useBreadcrumbs';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import {
  VerticalTab,
  VerticalTabList,
  VerticalTabTitle,
} from '../../../Components/VerticalTabList';
import { Content, withBase } from '../../../Layouts';
import { ConfirmModal } from './ConfirmModal/ConfirmModal';
import styles from './ExamView.module.css';

const cx = classNames.bind(styles);

const ExamViewWithoutBase = () => {
  const router = useRouter();
  const { examId } = router.query as { examId: string };
  const [{ data: enrollment }] = useEnrollment();
  const [{ data: exam }] = useExam({ exam: examId });
  const {
    baseUrl: apiBaseUrl,
    headers: apiHeaders,
    invalidateQueries,
  } = useApi();
  const [t] = useTranslator();
  const { openModal } = useModals();
  useBreadcrumbs(
    {
      [examId]: t('components.breadcrumbs.exam'),
    },
    [examId]
  );

  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const categories = useMemo(
    () =>
      (exam?.examContentVariety?.().content || []).filter(
        (item) =>
          (typeof item?.content?.[0] === 'string' &&
            item?.content?.[0] !== '') ||
          (typeof item?.content?.[0] !== 'string' &&
            item.content[0].content !== '')
      ),
    [exam]
  );

  const currentCategory: (typeof categories)[number] | undefined =
    categories[currentCategoryIndex];
  const steps = useMemo(
    () => currentCategory?.content || [],
    [currentCategory]
  );
  const currentStep: (typeof steps)[number] | undefined =
    steps[currentStepIndex];

  const isSummary = [
    'HANDED_IN',
    'COMPLETED_APPROVED',
    'COMPLETED_REJECTED',
  ].includes(exam?.currentStep || '');

  useEffect(() => {
    if (isSummary) {
      setCurrentStepIndex(steps.length - 1);
      setCurrentCategoryIndex(categories.length - 1);
    }
  }, [categories, steps, isSummary]);

  const handleNextStep = useCallback(() => {
    if (currentStepIndex === steps.length - 1) {
      setCurrentStepIndex(0);
      setCurrentCategoryIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentStepIndex((prevIndex) => prevIndex + 1);
    }
  }, [currentStepIndex, steps]);

  const handlePreviousStep = useCallback(() => {
    if (currentStepIndex === 0) {
      setCurrentStepIndex(
        categories[currentCategoryIndex - 1].content.length - 1
      );
      setCurrentCategoryIndex((prevIndex) => prevIndex - 1);
    } else {
      setCurrentStepIndex((prevIndex) => prevIndex - 1);
    }
  }, [currentCategoryIndex, currentStepIndex, categories]);

  const fileUploads = useMemo(() => exam?.uploads?.().reverse(), [exam]);

  const handleQueueComplete = useCallback(async () => {
    await invalidateQueries(['v1.exams.show']);
  }, [invalidateQueries]);

  return (
    <Container>
      <Content className={cx('base')}>
        <div className={cx('element-container', { toolbar: true })}>
          <h1>{`${t('views.collection.exam_block.exam')} ${enrollment?.course?.().title || ''}`}</h1>
          <div className={cx('element-container')}>
            <div>
              <Status className={cx('exam-tag')}>
                {t('views.collection.exam_block.exam')}
              </Status>
            </div>
          </div>
        </div>
        <Panel>
          {!exam ? (
            <Loading />
          ) : (
            <Grid container spacing={0}>
              <Grid item xs={4}>
                <div className={cx('navigation-side-panel')}>
                  <VerticalTabList>
                    {categories.map((category, catIndex) => (
                      <React.Fragment key={category.title}>
                        <VerticalTabTitle>{category.title}</VerticalTabTitle>
                        <div>
                          {Array.isArray(category.content) &&
                            category.content.map((step, stepIndex) => (
                              <VerticalTab
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${catIndex}-${stepIndex}`}
                                active={
                                  currentCategoryIndex === catIndex &&
                                  currentStepIndex === stepIndex
                                }
                                completed={
                                  currentCategoryIndex > catIndex ||
                                  (currentCategoryIndex >= catIndex &&
                                    currentStepIndex > stepIndex)
                                }
                                onClick={() => {
                                  setCurrentCategoryIndex(catIndex);
                                  setCurrentStepIndex(stepIndex);
                                }}
                              >
                                {typeof step === 'string' ? step : step.title}
                              </VerticalTab>
                            ))}
                        </div>
                      </React.Fragment>
                    ))}
                  </VerticalTabList>
                </div>
              </Grid>
              <Grid item xs={8}>
                {typeof currentStep === 'object' ? (
                  <div className={cx(styles.content)}>
                    {!!currentStep && (
                      <>
                        <div className={cx(styles.titles)}>
                          <h2>{currentStep.title}</h2>
                          {currentStep.duration && (
                            <div className={cx(styles.duration)}>
                              <Status scheme="blue">
                                <div className={cx(styles.status)}>
                                  <AccessTime />
                                  <span>{currentStep.duration}</span>
                                </div>
                              </Status>
                            </div>
                          )}
                        </div>
                        {!Array.isArray(currentStep.content) && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(currentStep.content),
                            }}
                          />
                        )}
                        {currentStep?.hasUploadField && (
                          <>
                            <div>
                              {!isSummary && (
                                <UploadBox
                                  url={`${apiBaseUrl}/v1/exams/${exam.id}/uploads`}
                                  headers={{ ...apiHeaders }}
                                  onAfterUpload={handleQueueComplete}
                                />
                              )}
                            </div>
                            <div className={cx(styles.uploadedFiles)}>
                              {fileUploads?.map((file) => (
                                <UploadedFile
                                  key={file.id}
                                  src={file.contentAccessUrl}
                                  name={file.fileName}
                                />
                              ))}
                            </div>
                          </>
                        )}
                        <div className={cx('element-container')}>
                          {isSummary ? (
                            <Button
                              onClick={() => {
                                router.back();
                              }}
                            >
                              {t('views.exam.leave')}
                            </Button>
                          ) : (
                            <>
                              {!(
                                currentCategoryIndex === 0 &&
                                currentStepIndex === 0
                              ) ? (
                                <Button outline onClick={handlePreviousStep}>
                                  <ArrowBack />
                                  <span>{t('views.exam.previous')}</span>
                                </Button>
                              ) : (
                                <div />
                              )}
                              {!(
                                currentCategoryIndex ===
                                  categories.length - 1 &&
                                currentStepIndex === steps.length - 1
                              ) ? (
                                <Button onClick={handleNextStep}>
                                  <span>{t('views.exam.next')}</span>
                                  <ArrowForward />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() =>
                                    openModal(ConfirmModal, { exam })
                                  }
                                >
                                  <span>{t('views.exam.done')}</span>
                                  <Check />
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className={styles.content}
                    dangerouslySetInnerHTML={{ __html: sanitizer(currentStep) }}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Panel>
      </Content>
    </Container>
  );
};

export const ExamView = withBase(ExamViewWithoutBase);
