import React, { CSSProperties } from 'react';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { Search } from '@brainstud/ui/Form Input/Search';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { GridListToggle } from '../../../../Components/GridListToggle';
import styles from './SearchSortAndToggle.module.css';

const classes = classNames.bind(styles);

type Props = {
  onSearch?: (value: string) => void;
  onToggleView?: (value: boolean) => void;
  sortable?: { [key: string]: string };
  onSort?: (value?: string) => void;
  style?: CSSProperties;
  className?: string;
  toggleValue?: boolean;
};

/**
 * Shows a bar with configuration options for the view below
 */
export const SearchSortAndToggle = ({
  onSearch,
  onToggleView,
  onSort,
  className,
  style,
  sortable,
  toggleValue,
}: Props) => {
  const [t] = useTranslator();
  return (
    <div className={classes(styles.base, className)} style={style}>
      {onSearch && (
        <Search
          className={styles.search}
          placeholder={t('search')}
          onChange={(event) => onSearch(event.target.value)}
          onClose={() => onSearch('')}
        />
      )}
      {onSort && sortable && Object.keys(sortable).length && (
        <Dropdown
          placeholder={t('components.search_filter.sort_on')}
          onChange={onSort}
        >
          {Object.keys(sortable).map((sortKey) => (
            <Dropdown.Option key={sortKey} value={sortKey}>
              {sortable[sortKey]}
            </Dropdown.Option>
          ))}
        </Dropdown>
      )}
      {onToggleView && (
        <GridListToggle onChange={onToggleView} value={toggleValue} />
      )}
    </div>
  );
};
