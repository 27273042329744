import React from 'react';
import { Panel } from '@brainstud/ui/Static/Panel';
import classNames from 'classnames/bind';
import { ILearningRouteNodeWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { sanitizer } from 'Utils/Sanitizer';
import { LinearFooter } from 'Views/CollectionViews/ObjectViews/LinearView/LinearFooter';
import styles from './IntroView.module.css';

const cx = classNames.bind(styles);

type Props = {
  learningRouteNode?: ILearningRouteNodeWithResources;
};

export const IntroView = ({ learningRouteNode }: Props) => {
  const title =
    learningRouteNode?.introduction?.title || learningRouteNode?.title;
  const content = learningRouteNode?.introduction?.content;

  return (
    <div className={cx(styles.base)}>
      <Panel className={cx(styles.content)}>
        <h3>{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
        <LinearFooter />
      </Panel>
    </div>
  );
};
