import { UseMutationOptions } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { UUID } from '../Types/Utils/UUID';

interface SharePortfolioCreateInput {
  email_addresses: string[];
}

interface ISharePortfolioProps {
  enrollment?: UUID;
  answer?: UUID;
}

export function useSharePortfolio(
  { enrollment, answer }: ISharePortfolioProps = {},
  settings?: UseMutationOptions<
    DataDocument<undefined>,
    ErrorDocument,
    SharePortfolioCreateInput
  >
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.sharePortfolio',
      baseUri: `/v1/share_portfolio/${enrollment || defaultEnrollment}${answer ? `/${answer}` : ''}`,
    },
    context
  );
  return useCreate<undefined, SharePortfolioCreateInput>(request, settings);
}
