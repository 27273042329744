import React, { FC } from 'react';
import { Container } from 'Components/Container';
import { LearningRouteEditorProvider } from 'Modules/course-editor/LearningRouteEditor/Provider';
import { useSubjectEditor } from 'Modules/course-editor/SubjectEditor/Provider';
import { SubjectEditBar } from 'Modules/course-editor/SubjectEditor/SubjectEditBar';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { SubjectView } from 'Views/CollectionViews/SubjectViews/SubjectView';
import { LearningSubjectEditView } from 'Views/Courses/CollectionEditView/LearningSubjectEditView';

/**
 * The View shown when editing a learning subject
 * FIXME should be moved to views folder
 */
export const SubjectEditView: FC = () => {
  const { preview, learningSubject } = useSubjectEditor();

  const { isCoachEnvironment } = useEnvironmentProvider();

  return (
    <LearningRouteEditorProvider>
      {isCoachEnvironment && <SubjectEditBar coach />}
      <Container>
        <>
          {preview ? (
            <SubjectView learningSubject={learningSubject} />
          ) : (
            <LearningSubjectEditView />
          )}
        </>
      </Container>
    </LearningRouteEditorProvider>
  );
};
