import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Exam,
  ExamCreateInput,
  ExamFilters,
  ExamIncludes,
  ExamSorts,
  ExamUpdateInput,
} from '../Types/Resources/Exam';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IExamProps
  extends HookParams<
    typeof ExamIncludes,
    typeof ExamFilters,
    typeof ExamSorts
  > {
  exam?: UUID;
}

export function useExam(
  { exam, ...queryParameters }: IExamProps = {},
  settings?: IUseQueryOptions<DataDocument<Exam>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(exam || defaultExam),
      baseName: 'v1.exams',
      baseUri: '/v1/exams',
      uri: `/${exam || defaultExam}`,
      invalidate: ['exam', 'exams'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Exam, ExamCreateInput, ExamUpdateInput>(
    request,
    settings
  );
}

export function useExams(
  { ...queryParameters }: Omit<IExamProps, 'exam'> = {},
  settings?: IUseQueryOptions<DataDocument<Exam[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.exams',
      baseUri: '/v1/exams',
      invalidate: ['exam', 'exams'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Exam, ExamCreateInput>(request, settings);
}
