import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLearningSubject } from '@brainstud/academy-api/Hooks/useLearningSubjects';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { Button } from '@brainstud/ui/Buttons/Button';
import { UploadBox } from '@brainstud/ui/FileHandling/UploadBox';
import { Input } from '@brainstud/ui/Form Input/Input';
import { SidebarModal } from '@brainstud/ui/Modals/SidebarModal';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames';
import { FlexContainer } from 'Components/FlexContainer';
import { RichTextEditor } from 'Components/RichTextEditor';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { PreviewLearningSubject } from '../Previews';
import styles from '../ContentModals.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodeId: UUID;
  learningSubject?: ILearningSubjectWithResources;
  /** To append a subject to another subject, open the modal with the parent subject ID */
  subjectId?: UUID;
  layout?: string;
};

type FormData = {
  features: string[];
  labels: string[];
  title: string;
  introduction: string;
};

export const SubjectModal = ({
  nodeId,
  learningSubject,
  subjectId: parentSubjectId,
  layout,
}: Props) => {
  const [t] = useTranslator();
  const apiConfig = useApi();
  const { closeModal } = useModals();
  const router = useRouter();
  const { collectionId } = router.query as { collectionId: string };

  const [title, setTitle] = useState(learningSubject?.title);
  const [introduction, setIntroduction] = useState(
    learningSubject?.introduction
  );

  const subjectLayout = layout || learningSubject?.layout || 'action-cards';

  const titleInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);
  const [fileUrl, setFileUrl] = useState(learningSubject?.poster?.src);

  const [{ createOrUpdate: createOrUpdateSubject }] = useLearningSubject(
    {
      learningSubject: learningSubject?.id,
    },
    { enabled: false }
  );

  const makeSingular = (input: string) => {
    if (input.endsWith('s')) {
      return input.slice(0, -1);
    }
    return input;
  };

  const parentElement = useMemo(() => {
    if (learningSubject?.parent) {
      const key = makeSingular(learningSubject?.parent?.resourceType as string);
      const value = learningSubject?.parent?.id;
      return { [key]: value };
    }
    if (nodeId) {
      return { learning_route_node: nodeId };
    }
    if (parentSubjectId) {
      return { learning_subject: parentSubjectId };
    }
  }, [nodeId, parentSubjectId, learningSubject?.parent]);

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      await createOrUpdateSubject.mutateAsync({
        poster_url: fileUrl,
        scheme: [],
        layout: subjectLayout,
        ...(nodeId || collectionId || parentSubjectId
          ? {
              relationships: {
                ...(collectionId
                  ? { learning_object_collection: collectionId }
                  : {}),
                ...(parentElement && parentElement),
              },
            }
          : {}),
        ...formData,
      });
      closeModal();
    },
    [
      createOrUpdateSubject,
      fileUrl,
      subjectLayout,
      nodeId,
      collectionId,
      parentSubjectId,
      parentElement,
      closeModal,
    ]
  );

  return (
    <SidebarModal onClose={closeModal} onClickOutside size="medium">
      <Form<FormData> onSubmit={handleSubmit}>
        <SidebarModal.Header>
          <FlexContainer row justifyContent="space-between" alignItems="center">
            <h3>
              {t(
                `views.courses.collection_edit.subject_modal.title.${!learningSubject ? 'create' : 'edit'}`
              )}
            </h3>
            <Button type="submit" loading={createOrUpdateSubject.isPending}>
              {t(`modals.submit.${!learningSubject ? 'create' : 'edit'}`)}
            </Button>
          </FlexContainer>
        </SidebarModal.Header>
        <FlexContainer gapSize="medium">
          <Input
            label={t('views.courses.collection_edit.subject_modal.form.title')}
            name="title"
            defaultValue={learningSubject?.title}
            placeholder={t(
              'views.courses.collection_edit.subject_modal.form.title'
            )}
            onChange={(e) => setTitle(e.target.value)}
            ref={titleInputRef}
          />
          <RichTextEditor
            id="introduction"
            label={t('views.courses.collection_edit.subject_modal.form.intro')}
            defaultValue={learningSubject?.introduction}
            name="introduction"
            onChange={(val) => setIntroduction(val)}
          />
          <strong>
            {t('views.courses.collection_edit.subject_modal.form.preview')}
          </strong>
          <PreviewLearningSubject
            title={title}
            introduction={introduction}
            poster={fileUrl}
          />
          <div className={cx(styles.uploadBox)}>
            <UploadBox
              url={`${apiConfig.baseUrl}/v1/services/media_upload`}
              headers={{ ...apiConfig.headers }}
              paramName="files[0]"
              maxFiles={1}
              label={t('views.courses.collection_edit.form.image')}
              onAfterFileUpload={(file, response) => {
                setFileUrl(response?.data?.[0]?.attributes?.file_url);
              }}
            />
          </div>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(
                t('views.courses.collection_edit.form.image_instructions')
              ),
            }}
          />
        </FlexContainer>
      </Form>
    </SidebarModal>
  );
};
