import React, { useCallback, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useLearningObject } from '@brainstud/academy-api/Hooks/useLearningObjects';
import { useLearningSubject } from '@brainstud/academy-api/Hooks/useLearningSubjects';
import { DuplicateModal } from '@brainstud/coach-panel/Modals/DuplicateModal';
import { ActionMenu } from '@brainstud/ui/Buttons/ActionMenu';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { Status } from '@brainstud/ui/Static/Status';
import {
  DeleteForever,
  DragIndicator,
  Edit,
  Lock,
  Settings,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { useLearningRouteEditorProvider } from '../../../../LearningRouteEditor/Provider/useLearningRouteEditorProvider';
import { AssignmentConditionsModal } from '../../../AssignmentConditionsModal';
import styles from './LearningSubjectEditRow.module.css';

const cx = classNames.bind(styles);

interface GroupProps {
  indent?: number;
  item: ILearningSubjectWithResources;
  index: number;
}

/**
 * LearningSubjectEditRow.
 *
 * Renders a draggable `subject` or `group`, which might render its children using components `LearningObject` or another `Group`
 */
export const LearningSubjectEditRow = ({
  indent = 0,
  item,
  index,
}: GroupProps) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const { openModal, closeModal } = useModals();
  const { nodes } = useLearningRouteEditorProvider();
  const { learningRoute, isCustomCollection } = useLearningRouteProvider();
  const { isAdminEnvironment, environment } = useEnvironmentProvider();
  const subjectLayout = item?.layout;
  const subjectBaseType = subjectLayout === 'quiz' ? 'quiz' : 'subject';

  const [{ destroy: destroySubject }] = useLearningSubject(
    {
      learningSubject: item.id,
    },
    { enabled: false }
  );
  const handleDeleteSubject = useCallback(() => {
    openModal(ConfirmationModal, {
      question: t(
        'course-editor.learning_route_editor.confirmation.question_delete'
      ),
      yes: t('course-editor.learning_route_editor.confirmation.confirm_delete'),
      no: t('course-editor.learning_route_editor.confirmation.cancel'),
      handleConfirm: () => {
        destroySubject.mutate();
        closeModal();
      },
    });
  }, [openModal, closeModal, destroySubject, t]);

  const [pendingDelete, setPendingDelete] = useState<string>();
  const [{ destroy: destroyObject }] = useLearningObject(
    {
      learningObject: pendingDelete,
    },
    { enabled: false }
  );
  useEffect(() => {
    if (!pendingDelete) return;
    openModal(ConfirmationModal, {
      question: t(
        'course-editor.learning_route_editor.confirmation.question_delete'
      ),
      yes: t('course-editor.learning_route_editor.confirmation.confirm_delete'),
      no: t('course-editor.learning_route_editor.confirmation.cancel'),
      handleConfirm: () => {
        destroyObject.mutate();
        setPendingDelete(undefined);
        closeModal();
      },
    });
  }, [closeModal, destroyObject, openModal, pendingDelete, t]);

  const isIndented = indent > 0;

  const handleSubjectEdit = useCallback(
    () =>
      isCustomCollection || isAdminEnvironment
        ? router.push(
            `/${environment}/courses/${courseId}/collections/${collectionId}/content/subjects/${item.id}/edit`
          )
        : openModal(DuplicateModal, { collectionId, courseId }),
    [
      collectionId,
      courseId,
      environment,
      isAdminEnvironment,
      isCustomCollection,
      item.id,
      openModal,
      router,
    ]
  );

  const handleObjectEdit = useCallback(
    (learningObjectId) =>
      isCustomCollection || isAdminEnvironment
        ? router.push(
            `/${environment}/courses/${courseId}/collections/${collectionId}/content/detail/${learningObjectId}/edit`
          )
        : openModal(DuplicateModal, { collectionId, courseId }),
    [
      collectionId,
      courseId,
      environment,
      isAdminEnvironment,
      isCustomCollection,
      openModal,
      router,
    ]
  );

  const assignmentStatusColor = (baseType?: string) => {
    const statusColor = {
      assignment: 'purple',
      theory: 'blue',
      quiz: 'red',
      subject: 'yellow',
    };
    if (baseType) {
      return statusColor[baseType];
    }
    return 'grey';
  };

  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={isIndented}
    >
      {(provided, snapshot) => (
        <div
          className={cx(
            styles.item,
            styles.base,
            {
              dragging: !isIndented && snapshot.isDragging,
              indented: isIndented,
            },
            `indent-${indent}`
          )}
          ref={provided.innerRef}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...(!isIndented ? provided.draggableProps : {})}
        >
          <div className={cx(styles.heading)}>
            <div className={cx(styles.badge)}>
              <Status
                scheme={assignmentStatusColor(subjectBaseType)}
                className={cx(styles.status)}
              >
                {t(
                  `course-editor.learning_route_editor.learning_subject.${subjectBaseType}`
                )}
              </Status>
            </div>
            <Button
              className={cx(styles['course-title'])}
              quiet
              onClick={handleSubjectEdit}
            >
              {item.title}
            </Button>
            <ActionMenu
              id={`action-menu-${index}`}
              icon={<Settings />}
              className={cx(styles['action-menu'])}
            >
              <button role="menuitem" type="button" onClick={handleSubjectEdit}>
                <Edit />
                <span>
                  {t(
                    `course-editor.learning_route_editor.learning_subject.${subjectLayout !== 'quiz' ? 'edit' : 'quiz_edit'}`
                  )}
                </span>
              </button>
              {item.scheme.filter(isPresent).length === 0 && (
                <button
                  role="menuitem"
                  type="button"
                  onClick={handleDeleteSubject}
                >
                  <DeleteForever />
                  <span>
                    {t(
                      `course-editor.learning_route_editor.learning_subject.${subjectLayout !== 'quiz' ? 'delete' : 'quiz_delete'}`
                    )}
                  </span>
                </button>
              )}
            </ActionMenu>
            {!isIndented && (
              <span
                className={cx(styles.dragHandle)}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...provided.dragHandleProps}
              >
                <DragIndicator />
              </span>
            )}
          </div>

          <div className={cx(styles.content)}>
            {item.scheme
              .filter(isPresent)
              .filter((object) => object.id !== pendingDelete)
              .map((object, idx) => (
                <React.Fragment key={object.id}>
                  {object.resourceType === 'learning_subjects' ? (
                    <LearningSubjectEditRow
                      item={object}
                      index={idx}
                      indent={indent + 1}
                    />
                  ) : (
                    <div className={cx(styles.contentItem)}>
                      <div className={cx(styles.badge)}>
                        <Status
                          scheme={assignmentStatusColor(object.baseType)}
                          className={cx(styles.status)}
                        >
                          {t(
                            `course-editor.learning_route_editor.learning_object.${object.baseType}`
                          )}
                        </Status>
                      </div>
                      <Button
                        className={cx(styles['course-title'])}
                        quiet
                        // FIXME these redirect urls should be build up with router.asPath
                        onClick={() => handleObjectEdit(object.id)}
                      >
                        {object.title}
                      </Button>
                      <Lock
                        className={cx(
                          !object.conditions?.() && styles['hide-lock']
                        )}
                      />
                      <ActionMenu
                        id={`action-menu-${index}`}
                        icon={<Settings />}
                        className={cx(styles['action-menu'])}
                      >
                        <button
                          role="menuitem"
                          type="button"
                          onClick={() =>
                            openModal(AssignmentConditionsModal, {
                              baseObject: object,
                              nodes,
                              learningRouteId: learningRoute?.id,
                            })
                          }
                        >
                          <Lock />
                          <span>
                            {t(
                              `course-editor.learning_route_editor.action_menu.assignment_conditions.${object.conditions ? 'edit' : 'add'}`
                            )}
                          </span>
                        </button>
                        <button
                          role="menuitem"
                          type="button"
                          onClick={() => handleObjectEdit(object.id)}
                        >
                          <Edit />
                          <span>
                            {t(
                              'course-editor.learning_route_editor.learning_object.edit'
                            )}
                          </span>
                        </button>
                        {(isCustomCollection || isAdminEnvironment) && (
                          <button
                            role="menuitem"
                            type="button"
                            onClick={() => setPendingDelete(object.id)}
                          >
                            <DeleteForever />
                            <span>
                              {t(
                                'course-editor.learning_route_editor.learning_object.delete'
                              )}
                            </span>
                          </button>
                        )}
                      </ActionMenu>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
      )}
    </Draggable>
  );
};
