import { TShareableAccessType } from '@brainstud/academy-api/Types/Resources/Shareable';
import { useAnswerReview } from 'Modules/coach-panel/Providers/AnswerReviewProvider/useAnswerReview';
import { AnswerReviewTabs } from 'Modules/coach-panel/Views/Review/Tabs/AnswerReviewTabs';
import { useAnswerProvider } from 'Providers/AnswerProvider';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider';
import { CommentForm } from '../CommentForm';

type TProps = {
  accessLevel?: TShareableAccessType;
  onSuccess?: () => void;
};

/**
 * ActivityFeedFooter.
 *
 * Renders a comment field or review tabs based on the current environment
 */
export const ActivityFeedFooter = ({ accessLevel, onSuccess }: TProps) => {
  const { baseUri = '' } = useAnswerReview() || {};
  const { currentAnswer } = useAnswerProvider();
  const { isStudentEnvironment, isCoachEnvironment } = useEnvironmentProvider();
  const currentAnswerId = currentAnswer?.id;
  const canComment = accessLevel === 'comment';

  if (isCoachEnvironment)
    return (
      <AnswerReviewTabs
        answerId={currentAnswerId}
        baseUri={baseUri}
        onSuccess={onSuccess}
      />
    );

  if (isStudentEnvironment && canComment) {
    return (
      <CommentForm id={currentAnswerId} type="answer" onSuccess={onSuccess} />
    );
  }

  return null;
};
