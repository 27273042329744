import { useMemo } from 'react';
import {
  useEnrollment,
  useEnrollments,
} from '@brainstud/academy-api/Hooks/useEnrollments';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { useRouter } from 'next/router';
import { ApiException } from '../Exceptions';

export function useEnrollmentByCourseId(givenCourseId?: string) {
  const router = useRouter();
  const { courseId: routeCourseId } = router.query as { courseId: string };
  const courseId = givenCourseId || routeCourseId;

  const [me, { isLoading: isLoadingMe, isError: isErrorMe }] = useMe();
  const account = useMemo(() => me?.account?.(), [me]);
  const [{ data: enrollments }, { isLoading: isLoadingEnrollment, error }] =
    useEnrollments(
      {
        filter: {
          account: account?.id,
          course: courseId,
        },
        include: [
          'exam',
          'exam.assessment',
          'education_level',
          'course',
          'learning_object_collection',
          'account',
          'coach_mandates',
          'schedule',
          'feedback',
        ],
      },
      {
        enabled: !!courseId && !!account,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );
  const enrollment =
    enrollments?.find((item) => item.isValid) || enrollments[0];
  // To update the enrolment last-seen date
  useEnrollment(
    { enrollment: enrollment?.id },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const course = useMemo(() => enrollment?.course?.(), [enrollment]);

  if (error) {
    throw new ApiException(error);
  }

  return {
    enrollments,
    enrollment,
    course,
    isLoading: isLoadingMe || isLoadingEnrollment,
    isError: isErrorMe || !!error,
  };
}
