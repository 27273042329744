import React from 'react';
import { EventCalendar } from '@brainstud/academy-api/Types/Resources/EventCalendar';
import { EventCalendarItem } from '@brainstud/academy-api/Types/Resources/EventCalendarItem';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, Td, Th, Tr } from '@brainstud/ui/Static/Table';
import { Check } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './EventsTable.module.css';

const cx = classNames.bind(styles);

interface IEventsTable {
  calendar: EventCalendar;
  events: EventCalendarItem[];
}

export const EventsTable = ({ calendar, events }: IEventsTable) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };

  return (
    <Panel style={{ marginBottom: '2rem' }}>
      <Table>
        <Table.THead>
          <Tr>
            <Th>{t('views.event.date_time')}</Th>
            <Th>{t('views.event.events')}</Th>
            <Th>{t('views.event.type')}</Th>
            <Th>
              <div className={cx('table-check')}>{t('subscribed')}</div>
            </Th>
          </Tr>
        </Table.THead>
        <Table.TBody>
          {events.map((event) => (
            <Tr to={`/courses/${courseId}/events/${calendar.id}/${event.id}`}>
              <Td to={`/courses/${courseId}/events/${calendar.id}/${event.id}`}>
                {DateFormatter.toDateTime(
                  event.startDate,
                  'dd MMMM yyyy [at] hh:mm'
                )}
              </Td>
              <Td>{event.title}</Td>
              <Td>{event.category}</Td>
              <Td>
                {event.eventCalendarItemEnrollment?.() && (
                  <div className={cx('table-check')}>
                    <Check fontSize="large" />
                  </div>
                )}
              </Td>
            </Tr>
          ))}
        </Table.TBody>
      </Table>
    </Panel>
  );
};
