import React from 'react';
import { Td, Tr } from '@brainstud/ui/Static/Table';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { LearningObjectRow } from './LearningObjectRow';

type Props = {
  subject: ILearningSubjectWithResources;
  indent?: number;
};

export const LearningSubjectRow = ({ subject, indent = 0 }: Props) => {
  const { environment } = useEnvironmentProvider();
  const router = useRouter();
  const { courseId } = router.query as {
    learningRouteId: string;
    courseId: string;
  };
  const isEditPreview = router.pathname.includes('/edit');

  const subjectUrl =
    {
      coach: `${router.asPath}/subjects/${subject.id}`,
      admin: isEditPreview
        ? router.asPath.replace('/edit', `/subjects/${subject.id}`)
        : `${router.asPath}/subjects/${subject.id}`,
    }[environment as 'coach' | 'admin'] ||
    `/courses/${courseId}/subjects/${subject.id}`;

  return (
    <>
      <Tr to={!isEditPreview ? subjectUrl : undefined} indent={indent}>
        <Td to={!isEditPreview ? subjectUrl : undefined} colSpan={4}>
          <strong>{subject.title}</strong>
        </Td>
      </Tr>
      {subject.scheme.map((item) => (
        <React.Fragment key={item.id}>
          {item.resourceType === 'learning_objects' && (
            <LearningObjectRow object={item} indent={indent + 1} />
          )}
          {/* for now only 1 nested subject will show the correct indent */}
          {item.resourceType === 'learning_subjects' && (
            <LearningSubjectRow subject={item} indent={1} />
          )}
        </React.Fragment>
      ))}
    </>
  );
};
