import React from 'react';
import { isObject } from '@brainstud/course-editor/Support/is';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Badge } from '@brainstud/ui/Static/Badge';
import { Add, AddRounded } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { FlexContainer } from 'Components/FlexContainer';
import { useProgressCalculator } from 'Hooks/ProgressCalculator/useProgressCalculator';
import { BackButton } from 'Modules/action-based-learning-panel/Components/BackButton';
import {
  LearningObjectEditRow,
  NodeOrderEditor,
} from 'Modules/course-editor/Components/NodeOrderEditor';
import { LearningSubjectEditRow } from 'Modules/course-editor/Components/NodeOrderEditor/DraggableNodes/LearningSubject/LearningSubjectEditRow';
import { useSubjectEditor } from 'Modules/course-editor/SubjectEditor/Provider';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { HIDDEN_LAYOUT_NAME } from 'Views/CollectionViews/Layouts';
import { QuizModal } from '../../LearningRouteEditorView/ContentModals/QuizModal';
import { SubjectModal } from '../../LearningRouteEditorView/ContentModals/SubjectModal';
import { ContentSelectionModal } from '../../LearningRouteEditorView/ContentSelectionModal';
import styles from './QuizEditView.module.css';

const cx = classNames.bind(styles);

/**
 * Edit the contents of a learning subject
 */
export const QuizEditView = () => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const { setNestedItems, nestedItems, learningSubject } = useSubjectEditor();
  const { learningRoute, learningObjectCollection } =
    useLearningRouteProvider();
  const { layout: routeLayout } = useLearningRouteProvider();
  const { totalObjects, xpAvailable } = useProgressCalculator(learningSubject);

  return (
    <div className={cx(styles.base)}>
      <BackButton />
      <FlexContainer className={cx(styles.subjectHeader)}>
        <FlexContainer row justifyContent="space-between" alignItems="center">
          <h2>{learningSubject.title}</h2>
          <Button
            outline
            onClick={() =>
              openModal(QuizModal, { learningSubject, routeLayout })
            }
          >
            {t('views.courses.collection_edit.quiz_edit_view.edit')}
          </Button>
        </FlexContainer>
        <FlexContainer
          className={cx(styles.details)}
          row
          justifyContent="space-between"
          gapSize="large"
        >
          <div
            className={cx(styles.intro)}
            dangerouslySetInnerHTML={{
              __html: sanitizer(learningSubject?.introduction),
            }}
          />
          <FlexContainer>
            <strong>
              {t(
                'views.courses.collection_edit.quiz_edit_view.amount_questions'
              )}
            </strong>
            <strong>{totalObjects}</strong>
          </FlexContainer>
          <FlexContainer>
            <strong>
              {t('views.courses.collection_edit.quiz_edit_view.amount_xp')}
            </strong>
            <Badge color="grey">{`${xpAvailable} XP`}</Badge>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <div className={cx(styles.content)}>
        <NodeOrderEditor nodes={nestedItems} onChange={setNestedItems}>
          {nestedItems.map((item, index) =>
            isObject(item) ? (
              <LearningObjectEditRow key={item.id} item={item} index={index} />
            ) : (
              <LearningSubjectEditRow key={item.id} item={item} index={index} />
            )
          )}
          <Button
            type="button"
            quiet
            link
            disabled={!learningSubject}
            onClick={() =>
              openModal(ContentSelectionModal, {
                collectionId: learningObjectCollection?.id,
                learningRouteId: learningRoute?.id,
                subjectId: learningSubject.id,
                learningRoute,
                quizSubjectId: learningSubject.id,
              })
            }
          >
            <AddRounded />
            <span>
              {t('views.courses.collection_edit.quiz_edit_view.add_content')}
            </span>
          </Button>
        </NodeOrderEditor>
      </div>
      {/* Add new subject */}
      <div className={styles.addNewContent}>
        <Button
          type="button"
          onClick={() =>
            openModal(SubjectModal, {
              subjectId: learningSubject.id,
              layout: HIDDEN_LAYOUT_NAME,
            })
          }
          quiet
        >
          <Add className={styles.addNewContentIcon} />
          <span>
            {t('views.courses.collection_edit.quiz_edit_view.add_subject')}
          </span>
        </Button>
      </div>
    </div>
  );
};
