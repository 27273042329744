import { useIndex } from '../Factories/useIndex';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { Answer } from '../Types/Resources/Answer';
import { AnswerFeedback } from '../Types/Resources/AnswerFeedback';
import { Comment } from '../Types/Resources/Comment';
import { Shareable } from '../Types/Resources/Shareable';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';
import { UUID } from '../Types/Utils/UUID';

interface Props {
  enrollment: UUID;
  learningObject: UUID;
}

export interface IActivity extends IResource<'activities'> {
  activityAt: string;
  answer?: () => Answer & { resourceType: 'answers' };
  answerFeedback?: () => AnswerFeedback & { resourceType: 'answer_feedback' };
  comment?: () => Comment & { resourceType: 'comments' };
  shareable?: () => Shareable & { resourceType: 'shareables' };
  type: TActivityType;
}

export type TActivityType =
  | 'answer_feedback'
  | 'answer'
  | 'comment'
  | 'shareable';

/**
 * useEnrollmentLearningObjectActivity.
 *
 * Gets an activity 'timeline' for a learning object on which a participant is enrolled in.
 * Things like turned in date, comments and grading are fetched here.
 */
export function useEnrollmentLearningObjectActivity(
  { enrollment, learningObject, ...queryParameters }: Props,
  settings?: IUseQueryOptions<DataDocument<IActivity[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollment.learningObject.activities',
      baseUri: `/v1/enrollments/${enrollment}/learning_objects/${learningObject}/activities`,
      queryParameters,
    },
    context
  );
  return useIndex<IActivity>(request, settings);
}
