import { UseMutationOptions } from '@tanstack/react-query';
import { useCreate } from '../Factories/useCreate';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { Shareable, ShareableCreateInput } from '../Types/Resources/Shareable';
import { UUID } from '../Types/Utils/UUID';

interface IShareEnrollmentLearningObjectProps {
  enrollment: UUID;
  learningObject: UUID;
}

/**
 * useShareEnrollmentLearningObject.
 *
 * Create a shareable for a EnrollmentlearningObject.
 */
export function useShareEnrollmentLearningObject(
  { enrollment, learningObject }: IShareEnrollmentLearningObjectProps,
  settings?: UseMutationOptions<
    DataDocument<Shareable>,
    ErrorDocument,
    ShareableCreateInput
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollment.learning_object.share',
      baseUri: `/v1/enrollments/${enrollment}/learning_objects/${learningObject}/actions/share`,
      invalidate: [
        'v1.enrollments.learningObject',
        'v1.enrollments.learningObjects',
      ],
    },
    context
  );
  return useCreate<Shareable, ShareableCreateInput>(request, settings);
}
