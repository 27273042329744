import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  LearningObjectVariety,
  LearningObjectVarietyCreateInput,
  LearningObjectVarietyUpdateInput,
} from '../Types/Resources/LearningObjectVariety';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ILearningObjectVarietyProps {
  learningObject?: UUID;
  variety?: UUID;
}

export function useLearningObjectVariety(
  {
    learningObject,
    variety,
    ...queryParameters
  }: ILearningObjectVarietyProps = {},
  settings?: IUseQueryOptions<
    DataDocument<LearningObjectVariety>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: {
      variety: defaultLearningObjectVariety,
      learningObject: defaultLearningObject,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(variety || defaultLearningObjectVariety) &&
        !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects.learningObjectVarieties',
      baseUri: `/v1/learning_objects/${learningObject || defaultLearningObject}/varieties`,
      uri: `/${variety || defaultLearningObjectVariety}`,
      invalidate: [
        'learning_object_variety',
        'learning_object_varieties',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningObjectVariety,
    LearningObjectVarietyCreateInput,
    LearningObjectVarietyUpdateInput
  >(request, settings);
}

export function useLearningObjectVarieties(
  {
    learningObject,
    ...queryParameters
  }: Omit<ILearningObjectVarietyProps, 'variety'> = {},
  settings?: IUseQueryOptions<
    DataDocument<LearningObjectVariety[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObject: defaultLearningObject },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects.learningObjectVarieties',
      baseUri: `/v1/learning_objects/${learningObject || defaultLearningObject}/varieties`,
      invalidate: [
        'variety',
        'varieties',
        'learning_object_variety',
        'learning_object_varieties',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningObjectVariety, LearningObjectVarietyCreateInput>(
    request,
    settings
  );
}
