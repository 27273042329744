import React, { useCallback } from 'react';
import { useLearningSubject } from '@brainstud/academy-api/Hooks/useLearningSubjects';
import { isObject } from '@brainstud/course-editor/Support/is';
import { ActionMenu } from '@brainstud/ui/Buttons/ActionMenu';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ConfirmationModal } from '@brainstud/ui/Modals/ConfirmationModal';
import { AddRounded, DeleteForever, Edit, Settings } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { BackButton } from 'Modules/action-based-learning-panel/Components/BackButton';
import { NodeOrderEditor } from 'Modules/course-editor/Components/NodeOrderEditor';
import { LearningObjectEditRow } from 'Modules/course-editor/Components/NodeOrderEditor/DraggableNodes/LearningObject/LearningObjectEditRow';
import { LearningSubjectEditRow } from 'Modules/course-editor/Components/NodeOrderEditor/DraggableNodes/LearningSubject/LearningSubjectEditRow';
import { useSubjectEditor } from 'Modules/course-editor/SubjectEditor/Provider';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { sanitizer } from 'Utils/Sanitizer';
import { SubjectModal } from '../../LearningRouteEditorView/ContentModals/SubjectModal';
import { ContentSelectionModal } from '../../LearningRouteEditorView/ContentSelectionModal';
import styles from './SubjectEditView.module.css';

const cx = classNames.bind(styles);

/**
 * Edit the contents of a learning subject
 */
export const SubjectEditView = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const { openModal, closeModal } = useModals();
  const { setNestedItems, nestedItems, learningSubject } = useSubjectEditor();
  const { learningRoute, learningObjectCollection } =
    useLearningRouteProvider();
  // @ts-ignore FIXME When poster is added
  const subjectImage = learningSubject?.poster;
  const { layout: routeLayout } = useLearningRouteProvider();
  const subjectLayout = learningSubject?.layout;

  const { hierarchy } = useLearningRouteProvider();

  const quizSubject = hierarchy.find((item) =>
    (item as ILearningSubjectWithResources).quiz?.()
  );

  const [{ destroy: destroySubject }] = useLearningSubject(
    {
      learningSubject: learningSubject.id,
    },
    { enabled: false }
  );
  const handleDeleteSubject = useCallback(() => {
    openModal(ConfirmationModal, {
      question: t(
        'course-editor.learning_route_editor.confirmation.question_delete'
      ),
      yes: t('course-editor.learning_route_editor.confirmation.confirm_delete'),
      no: t('course-editor.learning_route_editor.confirmation.cancel'),
      handleConfirm: () => {
        destroySubject.mutate();
        closeModal();
        router.push(
          router.asPath?.replace(
            `/subjects/${learningSubject.id}/edit`,
            '/edit'
          )
        );
      },
    });
  }, [openModal, t, destroySubject, closeModal, router, learningSubject.id]);

  return (
    <div className={cx(styles.base)}>
      <BackButton />
      <div className={cx(styles.subjectTitleEdit)}>
        <img
          src={
            !subjectImage ? '/media/abstract-thumbnail.jpg' : subjectImage.src
          }
          alt="Placeholder"
          className={cx(styles.image)}
        />
        <div className={cx(styles.details)}>
          <h2>{learningSubject.title}</h2>
          <div
            className={cx(styles.intro)}
            dangerouslySetInnerHTML={{
              __html: sanitizer(learningSubject?.introduction),
            }}
          />
        </div>

        <ActionMenu
          id={`action-menu-${learningSubject.id}`}
          icon={<Settings />}
          className={cx(styles.actionMenu)}
        >
          <button
            role="menuitem"
            type="button"
            onClick={() =>
              openModal(SubjectModal, { learningSubject, routeLayout })
            }
          >
            <Edit />
            <span>
              {t(
                `course-editor.learning_route_editor.learning_subject.${subjectLayout !== 'quiz' ? 'edit' : 'quiz_edit'}`
              )}
            </span>
          </button>
          {learningSubject.scheme.filter(isPresent).length === 0 && (
            <button role="menuitem" type="button" onClick={handleDeleteSubject}>
              <DeleteForever />
              <span>
                {t(
                  `course-editor.learning_route_editor.learning_subject.${subjectLayout !== 'quiz' ? 'delete' : 'quiz_delete'}`
                )}
              </span>
            </button>
          )}
        </ActionMenu>
      </div>
      <NodeOrderEditor nodes={nestedItems} onChange={setNestedItems}>
        {nestedItems.map((item, index) =>
          isObject(item) ? (
            <LearningObjectEditRow key={item.id} item={item} index={index} />
          ) : (
            <LearningSubjectEditRow key={item.id} item={item} index={index} />
          )
        )}
        <Button
          type="button"
          quiet
          disabled={!learningSubject}
          onClick={() =>
            openModal(ContentSelectionModal, {
              collectionId: learningObjectCollection?.id,
              learningRouteId: learningRoute?.id,
              subjectId: learningSubject.id,
              learningRoute,
              quizSubjectId: quizSubject?.id,
            })
          }
        >
          <AddRounded />
          <span>{t('course-editor.subject_editor.add_item')}</span>
        </Button>
      </NodeOrderEditor>
    </div>
  );
};
