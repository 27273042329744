import React, { CSSProperties } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ArrowForward, CloudDownload } from '@mui/icons-material';
import classNames from 'classnames/bind';
import Image from 'next/image';
import { useTranslator } from 'Providers/Translator';
import VideoLink from '../../Utils/VideoLink';
import styles from './ToolkitItem.module.css';

type Props = {
  title: string;
  url: string;
  itemType: string;
  fileName?: string;
  style?: CSSProperties;
  className?: string;
};

const classes = classNames.bind(styles);

function url_domain(value: string) {
  const regex = /^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i;
  const matches = value.match(regex);
  return matches ? matches[1].replace('www.', '') : value;
}

/**
 * ToolkitItem.
 *
 * Shows a specific item of the toolkit
 */
export const ToolkitItem = ({
  title,
  url,
  itemType,
  fileName,
  className,
  style,
}: Props) => {
  const [t] = useTranslator();
  const isImage = /(jpg|jpeg|png|webp|gif)$/i.test(url);
  switch (itemType) {
    case 'video': {
      return (
        <figure className={classes(styles.base, styles.figure)}>
          <iframe
            title={title}
            width="100%"
            height="100%"
            src={new VideoLink(url).getEmbedLink()}
            frameBorder="0"
            allow="autoplay; autoplay"
            allowFullScreen
          />
          <figcaption>{`${title} (${url})`}</figcaption>
        </figure>
      );
    }
    case 'link': {
      return (
        <>
          <p>{`${t('views.toolkit.full_url')} ${url}`}</p>
          <Button className={styles.base} href={url} rel="noopener noreferrer">
            <span>{`${t('views.toolkit.go_to')} ${url_domain(url)}`}</span>
            <ArrowForward />
          </Button>
        </>
      );
    }
    default: {
      return (
        <div className={classes(styles.base, className)} style={style}>
          {isImage ? (
            <figure className={styles.figure}>
              <Image src={url} alt={title} />
              <figcaption>{title}</figcaption>
            </figure>
          ) : (
            <dl>
              {fileName && (
                <>
                  <dt>{t('views.toolkit.file_name')}</dt>
                  <dd>{fileName}</dd>
                </>
              )}
              <dt>{t('views.toolkit.file_url')}</dt>
              <dd>{url}</dd>
            </dl>
          )}
          <Button download={fileName} href={url}>
            <CloudDownload />
            <span>{t('views.toolkit.download_file')}</span>
          </Button>
        </div>
      );
    }
  }
};
