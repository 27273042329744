import React, { useState } from 'react';
import { AnswerGroup } from '@brainstud/academy-api/Types/Resources/AnswerGroups';
import { Status } from '@brainstud/ui/Static/Status';
import classNames from 'classnames/bind';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { QuestionResult } from './QuestionResult';
import styles from './QuizResult.module.css';

const cx = classNames.bind(styles);

type QuizCategoryProps = {
  learningSubject: ILearningSubjectWithResources;
  latest?: boolean;
};
const QuizCategory = ({ learningSubject, latest }: QuizCategoryProps) => (
  <div className={cx(styles.learningSubject)} key={learningSubject.id}>
    <strong>{learningSubject.title}</strong>
    <div className={cx(styles.questionWrapper)}>
      {learningSubject.scheme.map((nestedItem) => {
        if (nestedItem.resourceType === 'learning_objects') {
          return (
            <QuestionResult
              key={nestedItem.id}
              id={latest ? nestedItem.id : undefined}
              title={nestedItem.title}
              status={nestedItem.progress?.status}
            />
          );
        }
        if (nestedItem.resourceType === 'learning_subjects') {
          return <QuizCategory learningSubject={nestedItem} />;
        }
        return null;
      })}
    </div>
  </div>
);

type Props = {
  subject: ILearningSubjectWithResources;
  answerGroup: AnswerGroup;
  latest?: boolean;
};

export const QuizResult = ({ subject, answerGroup, latest }: Props) => {
  const [t] = useTranslator();
  const answers = answerGroup.answers?.() || [];
  const [isOpen, setIsOpen] = useState(latest);

  return (
    <div className={cx(styles.base)}>
      <button
        type="button"
        onClick={() => setIsOpen((prevOpen) => !prevOpen)}
        className={cx(styles.header)}
      >
        <div className={cx(styles.headerItem)}>
          <span>{t('views.collection.quiz.quiz_result.score')}</span>
          <strong className={styles.score}>
            {answerGroup.status === 'TURNED_IN'
              ? '--'
              : `${Number(answerGroup.score)?.toFixed(1).replace('.', ',')}%`}
          </strong>
        </div>
        <div className={cx(styles.headerItem)}>
          <span>{t('date')}</span>
          <strong>
            {DateFormatter.toDateTime(answerGroup?.completedAt, 'dd MMMM yyyy')}
          </strong>
        </div>
        <div className={cx(styles.headerItem)}>
          <span>{t('views.collection.quiz.quiz_result.time_spent')}</span>
          <strong>
            {DateFormatter.toDuration(
              answerGroup.createdAt,
              answerGroup.completedAt
            ) ||
              DateFormatter.toDuration(
                answerGroup.createdAt,
                answerGroup.completedAt,
                [
                  'years',
                  'months',
                  'weeks',
                  'days',
                  'hours',
                  'minutes',
                  'seconds',
                ]
              )}
          </strong>
        </div>
      </button>

      {isOpen &&
        (answers.filter((item) => item.status === 'TURNED_IN').length === 0 ? (
          <div className={cx(styles.questionsOverview)}>
            <div className={cx(styles.resultIndicator)}>
              <h3>{t('views.collection.quiz.quiz_result.results')}</h3>
              <Status scheme="green" inline>
                {`${t('views.collection.quiz.quiz_result.correct')} ${answers.filter((item) => item.status === 'ACCEPTED').length}`}
              </Status>
              <Status scheme="red" inline>
                {`${t('views.collection.quiz.quiz_result.incorrect')} ${answers.filter((item) => item.status === 'REJECTED').length}`}
              </Status>
            </div>
            <div className={cx(styles.questionWrapper)}>
              {subject.scheme.map((item) => {
                switch (item.resourceType) {
                  case 'learning_objects': {
                    const answer = answerGroup
                      .answers?.()
                      .find(
                        (answerItem) =>
                          answerItem.learningObject?.().id === item.id
                      );
                    return (
                      <QuestionResult
                        key={item.id}
                        id={latest ? item.id : undefined}
                        title={item.title}
                        status={answer?.status || 'REJECTED'}
                      />
                    );
                  }
                  case 'learning_subjects': {
                    return (
                      <QuizCategory
                        key={item.id}
                        learningSubject={item}
                        latest={latest}
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        ) : (
          <div className={styles.questionsOverview}>
            <h3>{t('views.collection.quiz.quiz_result.no_score')}</h3>
            <p>{t('views.collection.quiz.quiz_result.in_review')}</p>
          </div>
        ))}
    </div>
  );
};
