import { lazy } from 'react';

export const ToolArrayBlocks = {
  AccordionContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/AccordionContainer').then(
      (mod) => ({ default: mod.AccordionContainer })
    )
  ),
  ColumnContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/ColumnContainer').then(
      (mod) => ({ default: mod.ColumnContainer })
    )
  ),
  GroupContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/GroupContainer').then(
      (mod) => ({ default: mod.GroupContainer })
    )
  ),
  PanelContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/PanelContainer').then(
      (mod) => ({ default: mod.PanelContainer })
    )
  ),
  SliderContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/SliderContainer').then(
      (mod) => ({ default: mod.SliderContainer })
    )
  ),
  TabbarContainer: lazy(() =>
    import('Modules/course-blocks/Blocks/Container/TabbarContainer').then(
      (mod) => ({ default: mod.TabbarContainer })
    )
  ),
  CardsWidgetQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/CardsWidgetQuestion').then(
      (mod) => ({ default: mod.CardsWidgetQuestion })
    )
  ),
  DragAndDropQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/DragAndDropQuestion').then(
      (mod) => ({ default: mod.DragAndDropQuestion })
    )
  ),
  DragToSortQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/DragToSortQuestion').then(
      (mod) => ({ default: mod.DragToSortQuestion })
    )
  ),
  FileUploadQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/FileUploadQuestion').then(
      (mod) => ({ default: mod.FileUploadQuestion })
    )
  ),
  InputQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/InputQuestion').then((mod) => ({
      default: mod.InputQuestion,
    }))
  ),
  MultipleChoiceQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/MultipleChoiceQuestion').then(
      (mod) => ({ default: mod.MultipleChoiceQuestion })
    )
  ),
  SingleChoiceQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/SingleChoiceQuestion').then(
      (mod) => ({ default: mod.SingleChoiceQuestion })
    )
  ),
  TextareaQuestion: lazy(() =>
    import('Modules/course-blocks/Blocks/Input/TextareaQuestion').then(
      (mod) => ({ default: mod.TextareaQuestion })
    )
  ),
  AccordionBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/AccordionBlock').then(
      (mod) => ({ default: mod.AccordionBlock })
    )
  ),
  AudioBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/AudioBlock').then((mod) => ({
      default: mod.AudioBlock,
    }))
  ),
  CalloutBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/CalloutBlock').then((mod) => ({
      default: mod.CalloutBlock,
    }))
  ),
  FormulaBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/FormulaBlock').then((mod) => ({
      default: mod.FormulaBlock,
    }))
  ),
  HTMLCodeBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/HTMLCodeBlock').then((mod) => ({
      default: mod.HTMLCodeBlock,
    }))
  ),
  ImageBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/ImageBlock').then((mod) => ({
      default: mod.ImageBlock,
    }))
  ),
  LtiResourceLinkBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/LtiResourceLinkBlock').then(
      (mod) => ({ default: mod.LtiResourceLinkBlock })
    )
  ),
  ReactWidget: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/ReactWidget').then((mod) => ({
      default: mod.ReactWidget,
    }))
  ),
  RichListBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/RichListBlock').then((mod) => ({
      default: mod.RichListBlock,
    }))
  ),
  RichMediaBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/RichMediaBlock').then(
      (mod) => ({ default: mod.RichMediaBlock })
    )
  ),
  RichTitleBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/RichTitleBlock').then(
      (mod) => ({ default: mod.RichTitleBlock })
    )
  ),
  ScormBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/ScormBlock').then((mod) => ({
      default: mod.ScormBlock,
    }))
  ),
  VideoBlock: lazy(() =>
    import('Modules/course-blocks/Blocks/Static/VideoBlock').then((mod) => ({
      default: mod.VideoBlock,
    }))
  ),
};
