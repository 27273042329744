import React, { Suspense, useMemo } from 'react';
import { Block } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { LoadingDots } from '@brainstud/ui/Loaders/LoadingDots';
import { Callout } from '@brainstud/ui/Static/Callout';
import { COACH_ROLES, UNLIMITED_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { useAnswerGroupProvider } from 'Providers/AnswerGroupProvider';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import { useTranslator } from 'Providers/Translator';
import { ToolObject } from '../../../ToolArray';
import { ExtraAnswerMatches } from '../ExtraAnswerMatches/ExtraAnswerMatches';
import { SimpleContainer } from '../SimpleContainer/SimpleContainer';
import { Spacing } from '../Spacing/Spacing';
import { SubmitAssignment } from '../SubmitAssignment/SubmitAssignment';

type Props = {
  id: string;
  block: Block;
  spacing?: boolean;
  explicit?: boolean;
};

/**
 * BlockRenderer.
 *
 * This component transforms a JSON content block to the appropriate block component. It uses the ToolsObject to do so.
 */
export const BlockRenderer = ({
  block,
  spacing = true,
  explicit = false,
}: Props) => {
  const [t] = useTranslator();
  const { blocks, isStatic } = useContentBlocksProvider();
  const isLastBlock =
    blocks.length > 0 && blocks[blocks.length - 1]?.id === block?.id;
  const tool = ToolObject?.[block.type];
  const isInappropriateBlock =
    tool?.hidden === true || (tool?.hidden && tool.hidden.includes(block.type));
  const appropriateTool =
    isInappropriateBlock && tool?.fallback
      ? ToolObject?.[tool.fallback] || tool
      : tool;
  const { currentAnswerGroup } = useAnswerGroupProvider(true);
  const hasAnswerGroup = !!currentAnswerGroup;

  const isEditor = useHasAnyRole([...COACH_ROLES, ...UNLIMITED_ROLES]);

  const BlockComponent = useMemo(
    () =>
      appropriateTool?.block?.() ||
      (({ type }: { type: string }) => (
        <pre>{`${type} has not yet been implemented!`}</pre>
      )),
    [appropriateTool]
  );

  const isSubmitButtonVisible = !isStatic
    ? !hasAnswerGroup && isLastBlock
    : false;

  const { paddingTop, paddingBottom, ...restStyle } = block.style || {};
  const Wrapper = spacing ? Spacing : SimpleContainer;
  const wrapperProps = useMemo(
    () => ({
      ...(spacing
        ? { type: block.type, wideness: block.wideness, explicit }
        : {}),
      ...(block.style ? { style: spacing ? block.style : restStyle } : {}),
    }),
    [block.style, block.type, block.wideness, explicit, restStyle, spacing]
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Wrapper {...wrapperProps}>
      <ErrorBoundary>
        {appropriateTool?.verifyBlockConfig &&
        !appropriateTool?.verifyBlockConfig?.(block) &&
        isEditor ? (
          <Callout error>{t('components.blocks.empty.error')}</Callout>
        ) : (
          <>
            <Suspense fallback={<LoadingDots />}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <BlockComponent {...block} />
            </Suspense>
            {'extraAnswerIds' in block && (
              <ExtraAnswerMatches identifiers={block.extraAnswerIds} />
            )}
          </>
        )}
        {isSubmitButtonVisible && <SubmitAssignment />}
      </ErrorBoundary>
    </Wrapper>
  );
};
