import React, { useMemo } from 'react';
import { useCertificationDossierCoreTasks } from '@brainstud/academy-api/Hooks/useCertificationDossierCoreTasks';
import { CertificationDossier } from '@brainstud/academy-api/Types/Resources/CertificationDossier';
import { LearningObjectVariety } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { Checkbox } from '@brainstud/ui/Form Input/Checkbox';
import { useTranslator } from 'Providers/Translator';

type TProps = {
  dossier: CertificationDossier;
  variety?: LearningObjectVariety;
};

export const Dossier = ({ dossier, variety }: TProps) => {
  const selectedWorkProcesses = useMemo(
    () => variety?.dossierWorkprocesses?.() || [],
    [variety]
  );
  const selectedWorkProcessBehaviours = useMemo(
    () => variety?.dossierWorkprocessBehaviours?.() || [],
    [variety]
  );
  const selectedKnowledgeAndSkills = useMemo(
    () => variety?.dossierKnowledgeSkills?.() || [],
    [variety]
  );
  const [t] = useTranslator();
  const [{ data: coreTasks }, { isLoading }] = useCertificationDossierCoreTasks(
    {
      dossier: dossier.id,
      include: [
        'workprocesses',
        'workprocesses.behaviours',
        'knowledge_skills',
      ],
    }
  );

  return isLoading ? (
    <p>{t('views.courses.collection_edit.assignment_modal.loading')}</p>
  ) : (
    <div>
      <h2>
        <span>{dossier?.identifierLabel}</span>
        {` ${dossier?.title}`}
      </h2>
      {coreTasks.map((coreTask) => (
        <div key={coreTask.id}>
          <h3>{`${coreTask.code} ${coreTask.title}`}</h3>
          <h4>
            {t(
              'views.courses.collection_edit.assignment_modal.qualifications.workprocesses'
            )}
          </h4>
          {coreTask.workprocesses?.().map((workprocess) => (
            <React.Fragment key={workprocess.id}>
              <Checkbox
                id={workprocess.id}
                quiet
                small
                defaultChecked={selectedWorkProcesses.some(
                  (item) => item.id === workprocess.id
                )}
                name="workprocesses[]"
                defaultValue={workprocess.id}
              >
                {`${workprocess.code} ${workprocess.title}`}
              </Checkbox>
              <div style={{ marginLeft: '2rem' }}>
                {workprocess.behaviours?.().map((behaviour) => (
                  <Checkbox
                    key={behaviour.id}
                    id={behaviour.id}
                    quiet
                    small
                    defaultChecked={selectedWorkProcessBehaviours.some(
                      (item) => item.id === behaviour.id
                    )}
                    name="workprocess_behaviours[]"
                    defaultValue={behaviour.id}
                  >
                    {`${behaviour.code || ''} ${behaviour.description}`.trim()}
                  </Checkbox>
                ))}
              </div>
            </React.Fragment>
          ))}
          <h4>
            {t(
              'views.courses.collection_edit.assignment_modal.qualifications.knowledge_and_skills'
            )}
          </h4>
          {coreTask.knowledgeSkills?.().map((knowledgeSkill) => (
            <Checkbox
              key={knowledgeSkill.id}
              id={knowledgeSkill.id}
              quiet
              small
              defaultChecked={selectedKnowledgeAndSkills.some(
                (item) => item.id === knowledgeSkill.id
              )}
              name="knowledge_skills[]"
              defaultValue={knowledgeSkill.id}
            >
              {knowledgeSkill.description}
            </Checkbox>
          ))}
        </div>
      ))}
    </div>
  );
};
