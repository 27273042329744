import React from 'react';
import { Block } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { Panel } from '@brainstud/ui/Static/Panel';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { BlockRenderer } from 'Modules/course-blocks/Components/BlockRenderer';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useLearningSubjectProvider } from 'Providers/LearningSubjectProvider/useLearningSubjectProvider';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import { QuestionHeaderNavigation } from './QuestionHeaderNavigation/QuestionHeaderNavigation';
import { QuestionFooter } from './QuestionFooter';
import { useQuestionNavigation } from './useQuestionNavigation';
import styles from './QuestionView.module.css';

const cx = classNames.bind(styles);

type Props = {
  blocks: Block[];
};

const QuestionViewHeader = () => {
  const { learningSubject } = useLearningSubjectProvider();

  return (
    <Container>
      <div className={cx(styles.header)}>
        <h3>{learningSubject?.title}</h3>
      </div>
      <QuestionHeaderNavigation />
    </Container>
  );
};

const QuestionView: LayoutComponent<Props> = ({ blocks }) => {
  const router = useRouter();
  const { learningObjectId } = router.query as { learningObjectId: string };
  const { currentItem } = useQuestionNavigation();

  const parent =
    currentItem && 'parent' in currentItem ? currentItem.parent : null;
  const isInSubCategoryOfQuiz = !(
    parent as ILearningSubjectWithResources
  )?.quiz?.();

  return (
    <Container className={cx(styles.base)}>
      <Panel className={cx(styles.questionHeader)}>
        <div>
          {isInSubCategoryOfQuiz && parent?.title && (
            <strong className={cx(styles.subCategory)}>{parent.title}</strong>
          )}
          <h3>{currentItem?.title}</h3>
        </div>
      </Panel>
      <div>
        {blocks.map((block) => (
          <BlockRenderer key={block.id} id={learningObjectId} block={block} />
        ))}
      </div>
      <QuestionFooter />
    </Container>
  );
};

QuestionView.Header = QuestionViewHeader;
export { QuestionView };
