/* eslint-disable class-methods-use-this */
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { LearningObjectVariety } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { flattenBlockList } from '../Provider/BlockActions';
import { UnknownBlock } from '../Types';

/**
 * A migration is a class handling the conversion of a certain block structure to a new
 * block structure.
 */
export abstract class Migration {
  protected beforeDate?: Date;

  public tree: UnknownBlock[];

  public list: UnknownBlock[];

  public name?: string;

  public learningObject: LearningObject;

  public variety: LearningObjectVariety;

  constructor(
    tree: UnknownBlock[],
    learningObject: LearningObject,
    variety: LearningObjectVariety
  ) {
    this.tree = tree;
    this.list = flattenBlockList(this.tree);
    this.learningObject = learningObject;
    this.variety = variety;
  }

  /**
   * Function to check whether the migration is still necessary or not. By default, it checks the
   * created at date of a learning object to determine whether to run.
   */
  shouldRun(): boolean {
    return this.beforeDate
      ? new Date(this.learningObject.createdAt) < this.beforeDate
      : true;
  }

  /**
   * Should return whether the migration is applicable for a specific block,
   * or for the learning object as a whole.
   */
  abstract isLegacy(block: UnknownBlock): boolean;

  /**
   * This method is run on every learning object loaded in the course editor. It should
   * output the migrated tree of blocks.
   */
  abstract run(): UnknownBlock[];

  protected forEachLegacyBlock(
    fn: (block: UnknownBlock) => UnknownBlock,
    subtree?: UnknownBlock[]
  ): UnknownBlock[] {
    return (subtree || this.tree).map((block) => {
      const convertedBlock = this.isLegacy(block) ? fn(block) : block;

      return {
        ...convertedBlock,
        ...('blocks' in convertedBlock
          ? { blocks: this.forEachLegacyBlock(fn, convertedBlock.blocks) }
          : {}),
      };
    });
  }
}
