import React, { useState } from 'react';
import { TableViewRow } from '@brainstud/action-based-learning-panel/Components/TableViewRow';
import { SubjectTopView } from '@brainstud/action-based-learning-panel/Views';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Table, Th, Tr } from '@brainstud/ui/Static/Table';
import classNames from 'classnames/bind';
import { ObjectCard } from 'Components/Cards/ObjectCard';
import { SubjectCard } from 'Components/Cards/SubjectCard';
import { Container } from 'Components/Container';
import { GridListToggle } from 'Components/GridListToggle';
import { Loading } from 'Components/Loading';
import { useProgressCalculator } from 'Hooks/ProgressCalculator/useProgressCalculator';
import { useBreakpoints } from 'Hooks/useBreakpoints';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useTranslator } from 'Providers/Translator';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import styles from './SubjectActionCardsView.module.css';

const cx = classNames.bind(styles);

type Props = {
  subject: ILearningSubjectWithResources;
};

export const SubjectActionCardsView: LayoutComponent<Props> = ({ subject }) => {
  const [t] = useTranslator();
  const [gridMode, setGridMode] = useState(true);

  const items = subject.scheme;

  const { madeObjects: done, totalObjects: total } =
    useProgressCalculator(subject);

  const { smUp, xsDown } = useBreakpoints();

  return !subject ? (
    <Loading />
  ) : (
    <Container>
      <SubjectTopView subject={subject} progress={{ done, total }} />
      <div className={cx(styles.assignmentsBar)}>
        {smUp && (
          <div className={cx(styles.assignmentsAmount)}>
            {t('views.collection.subject.assignments', {
              number: items?.length || '',
            })}
          </div>
        )}
        {xsDown && (
          <p className={cx(styles.assignmentsDone)}>
            {`${done}/${total} ${t('views.collection.subject.assignments_finished')}`}
          </p>
        )}
        <GridListToggle onChange={(mode) => setGridMode(mode)} />
      </div>
      {gridMode ? (
        <div className={cx(styles.objectCardWrapper)}>
          {items.map((item) => {
            switch (item.resourceType) {
              case 'learning_objects': {
                return <ObjectCard key={item.id} object={item} />;
              }
              case 'learning_subjects': {
                return (
                  <div
                    key={item.id}
                    className={cx(styles.item, styles['span-2'])}
                  >
                    <SubjectCard key={item.id} subject={item} />
                  </div>
                );
              }
              default:
                return null;
            }
          })}
        </div>
      ) : (
        <Panel>
          <Table className={cx(styles.table)}>
            <Table.THead>
              <Tr>
                <Th>{t('views.collection.subject.assignment')}</Th>
                <Th />
                <Th>{t('views.collection.subject.status')}</Th>
              </Tr>
            </Table.THead>
            <Table.TBody>
              {subject.scheme.map((learningItem) => (
                <TableViewRow object={learningItem} />
              ))}
            </Table.TBody>
          </Table>
        </Panel>
      )}
    </Container>
  );
};
