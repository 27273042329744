import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  EventCalendarItemEnrollment,
  EventCalendarItemEnrollmentCreateInput,
  EventCalendarItemEnrollmentFilters,
  EventCalendarItemEnrollmentIncludes,
  EventCalendarItemEnrollmentSorts,
  EventCalendarItemEnrollmentUpdateInput,
} from '../Types/Resources/EventCalendarItemEnrollment';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IEventCalendarItemEnrollmentProps
  extends HookParams<
    typeof EventCalendarItemEnrollmentIncludes,
    typeof EventCalendarItemEnrollmentFilters,
    typeof EventCalendarItemEnrollmentSorts
  > {
  eventCalendarItem?: UUID;
  eventCalendarItemEnrollment?: UUID;
}

export function useEventCalendarItemEnrollment(
  {
    eventCalendarItem,
    eventCalendarItemEnrollment,
    ...queryParameters
  }: IEventCalendarItemEnrollmentProps,
  settings?: IUseQueryOptions<
    DataDocument<EventCalendarItemEnrollment>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: {
      eventCalendarItem: defaultEventCalendarItem,
      eventCalendarItemEnrollment: defaultEventCalendarItemEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(eventCalendarItemEnrollment || defaultEventCalendarItemEnrollment) &&
        !!(eventCalendarItem || defaultEventCalendarItem),
      baseName: 'v1.eventCalendars.items.enrollments',
      baseUri: `/v1/event_calendar_items/${eventCalendarItem || defaultEventCalendarItem}/enrollments`,
      uri: `/${eventCalendarItemEnrollment || defaultEventCalendarItemEnrollment}`,
      invalidate: ['current_user_enrollment', 'items.current_user_enrollment'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendarItemEnrollment,
    EventCalendarItemEnrollmentCreateInput,
    EventCalendarItemEnrollmentUpdateInput
  >(request, settings);
}
