import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  ExternalAssessment,
  ExternalAssessmentCreate,
  ExternalAssessmentIncludes,
  ExternalAssessmentsFilters,
  ExternalAssessmentsSorts,
  ExternalAssessmentUpdate,
} from '../Types/Resources/ExternalAssessment';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IExternalAssessmentsProps
  extends HookParams<
    typeof ExternalAssessmentIncludes,
    ExternalAssessmentsFilters,
    typeof ExternalAssessmentsSorts
  > {
  externalAssessment?: UUID;
}

export function useExternalAssessment(
  { externalAssessment, ...queryParameters }: IExternalAssessmentsProps = {},
  settings?: IUseQueryOptions<DataDocument<ExternalAssessment>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { externalAssessment: defaultExternalAssessment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(externalAssessment || defaultExternalAssessment),
      baseName: 'v1.externalAssessments',
      baseUri: '/v1/external_assessments',
      uri: `/${externalAssessment || defaultExternalAssessment}`,
      invalidate: ['external_assessment', 'external_assessments'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ExternalAssessment,
    ExternalAssessmentCreate,
    ExternalAssessmentUpdate
  >(request, settings);
}

export function useExternalAssessments(
  {
    ...queryParameters
  }: Omit<IExternalAssessmentsProps, 'externalAssessment'> = {},
  settings?: IUseQueryOptions<DataDocument<ExternalAssessment[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.externalAssessments',
      baseUri: '/v1/external_assessments',
      invalidate: ['external_assessment', 'external_assessments'],
      queryParameters,
    },
    context
  );
  return useIndexCR<ExternalAssessment, ExternalAssessmentCreate>(
    request,
    settings
  );
}
