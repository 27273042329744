import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  DossierCoreTask,
  DossierCoreTaskCreateInput,
  DossierCoreTaskFilters,
  DossierCoreTaskIncludes,
  DossierCoreTaskSorts,
  DossierCoreTaskUpdateInput,
} from '../Types/Resources/DossierCoreTask';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface ICertificationDossierCoreTaskProps
  extends HookParams<
    typeof DossierCoreTaskIncludes,
    typeof DossierCoreTaskFilters,
    typeof DossierCoreTaskSorts
  > {
  dossier?: UUID;
  coreTask?: UUID;
}

export function useCertificationDossierCoreTask(
  {
    dossier,
    coreTask,
    ...queryParameters
  }: ICertificationDossierCoreTaskProps = {},
  settings?: IUseQueryOptions<DataDocument<DossierCoreTask>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { dossier: defaultDossier },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(dossier || defaultDossier) && !!coreTask,
      baseName: 'v1.dossiers.core_tasks',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks`,
      uri: `/${coreTask}`,
      invalidate: ['core_tasks', 'core_task'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    DossierCoreTask,
    DossierCoreTaskCreateInput,
    DossierCoreTaskUpdateInput
  >(request, settings);
}

export function useCertificationDossierCoreTasks(
  {
    dossier,
    ...queryParameters
  }: Omit<ICertificationDossierCoreTaskProps, 'coreTask'> = {},
  settings?: IUseQueryOptions<DataDocument<DossierCoreTask[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { dossier: defaultDossier },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(dossier || defaultDossier),
      baseName: 'v1.dossiers.core_task',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks`,
      invalidate: ['core_tasks', 'core_task'],
      queryParameters,
    },
    context
  );
  return useIndexCR<DossierCoreTask, DossierCoreTaskCreateInput>(
    request,
    settings
  );
}
