import React, { useCallback, useMemo } from 'react';
import { useCourse } from '@brainstud/academy-api/Hooks/useCourses';
import { useLearningObjectVariety } from '@brainstud/academy-api/Hooks/useLearningObjectVarieties';
import ErrorDocument from '@brainstud/academy-api/Support/Documents/ErrorDocument/ErrorDocument';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Form } from '@brainstud/universal-components/Components/Form';
import classNames from 'classnames/bind';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers/LearningObjectProvider/useLearningObjectProvider';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { Dossier } from './Dossier';
import styles from './AssignmentQualification.module.css';

const cx = classNames.bind(styles);

type FormData = {
  workprocesses: string[];
  workprocess_behaviours: string[];
  knowledge_skills: string[];
};

type TProps = {
  setIsLoading: (isLoading: boolean) => void;
};

export const AssignmentQualification = ({ setIsLoading }: TProps) => {
  const router = useRouter();
  const [setToast] = useToaster();
  const { learningObject, variety } = useLearningObjectProvider();

  const { courseId } = router.query as { courseId: string };
  const [{ data: course }, { isLoading }] = useCourse({
    course: courseId,
    include: ['certifications', 'certifications.dossiers'],
  });

  const [t] = useTranslator();

  const [{ update }] = useLearningObjectVariety(
    {
      variety: variety?.id,
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  const handleSubmit = useCallback(
    async ({
      workprocesses,
      workprocess_behaviours,
      knowledge_skills,
    }: FormData) => {
      setIsLoading(true);
      update.mutateAsync(
        {
          _method: 'patch',
          relationships: {
            workprocesses,
            workprocess_behaviours,
            knowledge_skills,
          },
        },
        {
          onSuccess: () => {
            setToast(
              'views.courses.collection_edit.assignment_modal.qualifications.update.success',
              'success'
            );
            setIsLoading(false);
          },
          onError: (errorDocument: ErrorDocument) => {
            setToast(
              errorDocument.errors.map((error) => error.detail).join('<br />'),
              'error'
            );
            setIsLoading(false);
          },
        }
      );
    },
    [update, setToast, setIsLoading]
  );

  const certifications = useMemo(() => course?.certifications?.(), [course]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {certifications !== undefined ? (
        <Form<FormData> onSubmit={handleSubmit} id="assignment-qualifications">
          {isLoading && (
            <p>{t('views.courses.collection_edit.assignment_modal.loading')}</p>
          )}
          {certifications?.map((certification) =>
            certification
              .certificationDossiers?.()
              .map((dossier) => (
                <Dossier key={dossier.id} dossier={dossier} variety={variety} />
              ))
          )}
        </Form>
      ) : (
        <Callout attention className={cx(styles.callout)}>
          <h4>
            {t(
              'views.courses.collection_edit.assignment_modal.no_certifications_title'
            )}
          </h4>
          <p>
            {t(
              'views.courses.collection_edit.assignment_modal.no_certifications_description'
            )}
          </p>
        </Callout>
      )}
    </>
  );
};
