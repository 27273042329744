import React, { useCallback, useMemo } from 'react';
import { useExam } from '@brainstud/academy-api/Hooks/useExams';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Callout } from '@brainstud/ui/Static/Callout';
import { ArrowForward } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { useBreakpoints } from 'Hooks/useBreakpoints';
import { useEnrollmentByCourseId } from 'Hooks/useEnrollmentByCourseId';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { Card, CardContent, CardMedia } from '../../../../Components/Card';
import { ExamAssessmentModal } from '../../../../Modals/ExamAssessmentModal';
import { ExamModal } from '../../../Exams';
import styles from './ExamBlock.module.css';

const cx = classNames.bind(styles);

export const ExamBlock = () => {
  const { enrollment } = useEnrollmentByCourseId();
  const [{ data: examWithContent }] = useExam({
    exam: enrollment?.exam?.().id,
  });

  const exam = useMemo(
    () => examWithContent || enrollment?.exam?.(),
    [enrollment, examWithContent]
  );
  const { openModal } = useModals();
  const [t] = useTranslator();
  const { currentStep } = exam || {};
  const hasExamVisible =
    [
      'IN_PRE_ASSESSMENT',
      'PRE_ASSESSMENT_APPROVED',
      'PRE_ASSESSMENT_REJECTED',
      'IN_PROGRESS',
      'HANDED_IN',
      'COMPLETED_APPROVED',
      'COMPLETED_REJECTED',
    ].indexOf(currentStep || '') > -1;
  const isCompleted = useMemo(
    () =>
      ['COMPLETED_APPROVED', 'COMPLETED_REJECTED'].includes(exam!.currentStep),
    [exam]
  );
  const contentVariety = useMemo(() => exam?.examContentVariety?.(), [exam]);
  const currentMessage = useMemo(
    () => contentVariety?.messages?.find((msg) => msg.step === currentStep),
    [contentVariety, currentStep]
  );
  const { xsDown, smUp } = useBreakpoints();

  const handleStartButton = useCallback(() => {
    if (exam?.currentStep === 'IN_PROGRESS') {
      return true;
    }
    openModal(ExamModal, { exam, enrollment });
    return false;
  }, [exam, openModal, enrollment]);

  return !hasExamVisible ? null : (
    <Container className={cx(styles.base)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {currentMessage ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(currentMessage.content),
              }}
            />
          ) : (
            <Callout
              info
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t(
                    `views.collection.exam_block.states.${isCompleted ? 'COMPLETED' : exam?.currentStep}`,
                    {
                      title: enrollment?.course?.().title,
                    }
                  )
                ),
              }}
            />
          )}
          {exam?.currentStep.startsWith('PRE_ASSESSMENT') && (
            <Button
              to={`/courses/${enrollment?.course?.().id}/exam/${exam?.id}/feedback`}
              outline
              className={cx(styles['exam-check-feedback'])}
            >
              <span>{t('views.collection.exam_block.check_feedback')}</span>
              <ArrowForward />
            </Button>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {['COMPLETED_APPROVED', 'COMPLETED_REJECTED', 'HANDED_IN'].includes(
            exam!.currentStep
          ) && (
            <div style={{ marginTop: '4rem' }}>
              <Button
                to={`/courses/${enrollment?.course?.().id}/exam/${exam?.id}/summary`}
                outline
              >
                {t('views.collection.exam_block.view_deliverables')}
              </Button>

              <Button
                type="button"
                disabled={!exam?.assessment}
                style={{ marginLeft: '1rem' }}
                onClick={() => {
                  openModal(ExamAssessmentModal, { exam });
                }}
              >
                {t('views.collection.exam_block.view_assessment')}
              </Button>
            </div>
          )}
          {exam?.currentStep.startsWith('PRE_ASSESSMENT') && (
            <Card>
              <CardContent className={cx('exam-start-card-title')}>
                <h3>{t('views.collection.exam_block.exam')}</h3>
              </CardContent>
              {smUp && (
                <div className={cx('start-exam-button-container')}>
                  {['PRE_ASSESSMENT_APPROVED', 'IN_PROGRESS'].includes(
                    exam.currentStep
                  ) && (
                    <Button
                      className={cx('start-exam-button')}
                      to={
                        exam?.currentStep === 'IN_PROGRESS'
                          ? `/exam/${exam.id}`
                          : undefined
                      }
                      onClick={handleStartButton}
                    >
                      <span>
                        {t(
                          `views.collection.exam_block.actions.${exam.currentStep}`
                        )}
                      </span>
                      <ArrowForward />
                    </Button>
                  )}
                </div>
              )}
              <CardMedia
                className={cx('start-exam-media')}
                ratio={100 / (16 / 9)}
                image={
                  enrollment?.course?.().metadata?.media?.thumbnailUrl ||
                  enrollment?.course?.().thumbnailUrl ||
                  ''
                }
              />
              {xsDown && (
                <CardContent
                  className={cx('start-exam-button-container-not-possible')}
                >
                  <p>
                    {t('views.collection.exam_block.not_possible_from_phone')}
                  </p>
                </CardContent>
              )}
            </Card>
          )}
        </Grid>
      </Grid>
      <hr className={styles.divider} />
    </Container>
  );
};
