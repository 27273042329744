import React, { useMemo } from 'react';
import { useEnrollmentLearningObject } from '@brainstud/academy-api/Hooks/useEnrollmentLearningObjects';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { Banner } from '@brainstud/ui/Static/Banner';
import { GetApp } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './ExportModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  id?: string;
  closeModal: () => void;
};

export const ExportModal = ({ id, closeModal }: Props) => {
  const [{ data: assignment }, { isLoading }] = useEnrollmentLearningObject({
    learningObject: id,
  });
  const answer = useMemo(() => assignment?.answers?.()?.[0], [assignment]);
  const downloadLink = answer?.links?.portfolioExport?.href;
  const [t] = useTranslator();

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <h3>{t('menu.export_assignment')}</h3>
      <p>{t('views.portfolio.export_assignment_description')}</p>
      {!downloadLink && !isLoading && (
        <Banner error>{t('views.portfolio.export_impossible')}</Banner>
      )}
      <div className={cx('buttons')}>
        <Button type="button" link onClick={closeModal}>
          {t('close')}
        </Button>
        <Button
          href={downloadLink}
          loading={isLoading}
          disabled={!isLoading && !downloadLink}
        >
          {t('download')}
          <GetApp />
        </Button>
      </div>
    </ContentModal>
  );
};
