import React, { useMemo, useState } from 'react';
import { ArrowForward, Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useProgressCalculator } from 'Hooks/ProgressCalculator/useProgressCalculator';
import { ConditionsModal } from 'Modals';
import { useRouter } from 'next/router';
import { ILearningRouteNodeWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { getKeyWithTrue } from 'Utils/getKeyWithTrue';
import { CircularProgressIndicator } from '../../CircularProgressIndicator';
import styles from './NodeCard.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  learningRouteNode: ILearningRouteNodeWithResources;
};

/**
 * NodeCard.
 *
 * NodeCard is used to show LearningRouteNodes in card form
 */
export const NodeCard = ({ className, learningRouteNode }: Props) => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };

  const [isUnlocked, setIsUnlocked] = useState(false);
  const { madeObjects: currentProgress, totalObjects: total } =
    useProgressCalculator(learningRouteNode);

  const statusText = getKeyWithTrue({
    start: currentProgress === 0,
    continue: currentProgress > 0 && currentProgress < total,
    restart: currentProgress === total,
  });

  const buttonText = t(`components.cards.node_card.${statusText || 'start'}`);

  const thumbnailUrl = learningRouteNode?.image || null;
  const hasUnCompletedConditions = useMemo(
    () =>
      !!learningRouteNode.conditions?.() && learningRouteNode.meta?.isLocked,
    [learningRouteNode]
  );

  const isEditPreview = router.pathname.includes('/edit');
  const redirectUrl = `/courses/${courseId}/chapters/${learningRouteNode.id}/`;

  const isCompleted = currentProgress === total;

  return (
    <button
      type="button"
      className={cx(styles.base, className, {
        locker: hasUnCompletedConditions && !isUnlocked,
        isCompleted,
      })}
      onClick={!isEditPreview ? () => router.push(redirectUrl) : undefined}
    >
      {hasUnCompletedConditions && !isUnlocked && (
        <div
          onClick={() =>
            openModal(ConditionsModal, {
              object: learningRouteNode,
              setIsUnlocked,
            })
          }
          className={cx('locker')}
          aria-hidden="true"
        >
          <Lock />
        </div>
      )}
      <div
        className={cx(styles.media)}
        title={learningRouteNode.title}
        style={{ backgroundImage: `url(${thumbnailUrl})` }}
      >
        {!thumbnailUrl && (
          <h3 className={cx(styles.noMediaHeader)}>
            {learningRouteNode.title}
          </h3>
        )}
      </div>

      <div className={cx(styles.cardContent)}>
        <div className={styles.progress}>
          <CircularProgressIndicator
            current={currentProgress}
            total={total}
            completed={isCompleted}
          />
        </div>
        <div className={cx(styles.buttonText)}>
          {thumbnailUrl ? learningRouteNode.title : buttonText}
        </div>
        <div className={cx(styles.cardActions)}>
          <div className={cx(styles.round)}>
            <ArrowForward fontSize="large" />
          </div>
        </div>
      </div>
    </button>
  );
};
