import { createContext, Dispatch, SetStateAction } from 'react';
import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';

export interface ISubjectEditorContext {
  /** The given learning subject */
  learningSubject: ILearningSubjectWithResources;
  /** The sorted LearningObjects to send to the API */
  nestedItems: Array<LearningObject | ILearningSubjectWithResources>; // TODO this also should include Subject
  /** Updates the nodes state */
  setNestedItems: Dispatch<
    SetStateAction<Array<LearningObject | ILearningSubjectWithResources>>
  >;
  /** The loading state of the update request to the API */
  isLoading: boolean;
  /** Sends the current node order to the API to save */
  handleSave: () => void;
  setPreview: Dispatch<SetStateAction<ISubjectEditorContext['preview']>>;
  preview: boolean;
}

export const SubjectEditorContext = createContext<null | ISubjectEditorContext>(
  null
);
