import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  EventCalendar,
  EventCalendarCreateInput,
  EventCalendarFilters,
  EventCalendarIncludes,
  EventCalendarSorts,
  EventCalendarUpdateInput,
} from '../Types/Resources/EventCalendar';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IEventCalendarProps
  extends HookParams<
    typeof EventCalendarIncludes,
    typeof EventCalendarFilters,
    typeof EventCalendarSorts
  > {
  course?: UUID;
  eventCalendar?: UUID;
}

export function useCourseEventCalendar(
  { course, eventCalendar, ...queryParameters }: IEventCalendarProps = {},
  settings?: IUseQueryOptions<DataDocument<EventCalendar>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { eventCalendar: defaultEventCalendar, course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(eventCalendar || defaultEventCalendar) &&
        !!(course || defaultCourse),
      baseName: 'v1.eventCalendars',
      baseUri: `/v1/courses/${course || defaultCourse}/event_calendars`,
      invalidate: ['event_calendars', 'event_calendars'],
      uri: `/${eventCalendar || defaultEventCalendar}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendar,
    EventCalendarCreateInput,
    EventCalendarUpdateInput
  >(request, settings);
}

export function useCourseEventCalendars(
  {
    course,
    ...queryParameters
  }: Omit<IEventCalendarProps, 'eventCalendar'> = {},
  settings?: IUseQueryOptions<DataDocument<EventCalendar[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.eventCalendars',
      baseUri: `/v1/courses/${course || defaultCourse}/event_calendars`,
      invalidate: ['event_calendars', 'event_calendars'],
      queryParameters,
    },
    context
  );
  return useIndexCR<EventCalendar, EventCalendarCreateInput>(request, settings);
}
