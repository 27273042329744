import { Comment as IComment } from '@brainstud/academy-api/Types/Resources/Comment';
import classNames from 'classnames/bind';
import { Avatar } from 'Components/Avatar';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './Comment.module.css';

const cx = classNames.bind(styles);

export type TProps = {
  comment: IComment;
};

export const Comment = ({ comment }: TProps) => {
  const author = comment.account?.();

  return (
    <div className={cx(styles.base)}>
      {author && (
        <div className={cx(styles.author)}>
          <Avatar
            className={cx(styles.avatar)}
            name={author.fullName || author.firstName}
            size="extra-small"
          />
          <span className={cx(styles.name)}>
            {author.fullName || author.firstName}
          </span>
          <span className={cx(styles.timestamp)}>
            {DateFormatter.toTime(comment.createdAt)}
          </span>
        </div>
      )}
      <div className={cx(styles.message)}>
        <p dangerouslySetInnerHTML={{ __html: sanitizer(comment.content) }} />
      </div>
    </div>
  );
};
