import React, { useMemo } from 'react';
import { withBase } from '@brainstud/action-based-learning-panel/Layouts';
import { Loading } from 'Components/Loading';
import { ILearningSubjectWithResources } from 'Providers/LearningRouteProvider/LearningRouteContext';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { LearningSubjectProvider } from 'Providers/LearningSubjectProvider';
import { getLayout } from '../../../Utils/getLayout';

type Props = {
  learningSubject?: ILearningSubjectWithResources;
};

export const SubjectView = withBase(({ learningSubject }: Props) => {
  const { layout: routeLayout } = useLearningRouteProvider();
  const {
    pageTree: { currentItem },
  } = useLearningRouteProvider();
  const subject = useMemo(
    () =>
      learningSubject ||
      (currentItem as undefined | ILearningSubjectWithResources),
    [learningSubject, currentItem]
  );

  const Layout = getLayout(routeLayout, 'subject', subject?.layout)?.view;

  return !subject ? (
    <Loading />
  ) : (
    <LearningSubjectProvider learningSubject={subject}>
      {Layout && <Layout subject={subject} />}
    </LearningSubjectProvider>
  );
});
