import React, { LazyExoticComponent, ReactNode } from 'react';
import {
  AccordionIcon,
  AudioIcon,
  CalloutIcon,
  CardsWidgetIcon,
  ColumnIcon,
  DragAndDropIcon,
  DragToSortIcon,
  FileUploadIcon,
  FunctionsIcon,
  GroupIcon,
  HtmlBlockIcon,
  InputIcon,
  MultipleChoiceIcon,
  PanelIcon,
  ReactWidgetIcon,
  RichImageIcon,
  RichListIcon,
  RichMediaIcon,
  RichTitleIcon,
  SingleChoiceIcon,
  SliderIcon,
  TabbarIcon,
  TextareaIcon,
  VideoIcon,
} from '@brainstud/course-editor/ContentEditor/Icons';
import { FolderZipOutlined, Link } from '@mui/icons-material';
import { createEmptyContainerBlock } from 'Modules/course-editor/Support/createEmptyContainerBlock';
import { UnknownBlock } from './course-editor/ContentEditor/Types';
import { ToolArrayBlocks } from './ToolArrayBlocks';
import { ToolArrayForms } from './ToolArrayForms';

type TBlockDefaultProps = {
  [key: string]: any;
};

export interface ITool {
  /** The tool type, corresponding to the block type */
  type: string;
  /** Type of content: container, open, closed, static  */
  contentType: 'container' | 'open' | 'closed' | 'static';
  /** The View component of the block */
  block?: () => LazyExoticComponent<any>;
  /** The functional component to render for this block in edit state */
  form: () => LazyExoticComponent<any>;
  /** Whether the block should always be in the editable state, useful for blocks that are identical in edit state vs view state. */
  isEditable?: boolean;
  /** The icon to display in the editor to indicate this type of block */
  icon?: ReactNode | string;
  /** Indicates whether an author should be able to add this block as a new block, useful for depreciation of a block */
  hidden?: boolean | string[];
  /** Use another block when this block should not be rendered */
  fallback?: string;
  /** An optional function to check if the configuration for a block is invalid. Invalid means there will be a Callout visibile stating that the block is not configured properly! */
  verifyBlockConfig?: (data: UnknownBlock) => boolean;
  /** Props added on the block component */
  props?: TBlockDefaultProps | (() => TBlockDefaultProps);
  /** Settings for accessing this content block */
  access?: {
    /** Roles that can create this content block */
    create?: string[];
  };
}

/**
 * ToolArray.
 *
 * This gives opportunity to config to a content block. It also links together the Block and the Form part of the
 * content block. In the end, the reducer makes an object out of this array. With this, you can either choose to
 * use a specific block (using the object) or to loop over all blocks (using the array).
 */
export const ToolArray: ITool[] = [
  {
    type: 'rich_media',
    contentType: 'static',
    form: () => ToolArrayForms.RichMediaForm,
    block: () => ToolArrayBlocks.RichMediaBlock,
    isEditable: true,
    icon: <RichMediaIcon />,
  },
  {
    type: 'rich_title',
    contentType: 'static',
    form: () => ToolArrayForms.RichTitleForm,
    block: () => ToolArrayBlocks.RichTitleBlock,
    icon: <RichTitleIcon />,
  },
  {
    type: 'rich_image',
    contentType: 'static',
    form: () => ToolArrayForms.RichImageForm,
    block: () => ToolArrayBlocks.ImageBlock,
    icon: <RichImageIcon />,
  },
  {
    type: 'formula',
    contentType: 'static',
    form: () => ToolArrayForms.FormulaForm,
    block: () => ToolArrayBlocks.FormulaBlock,
    icon: <FunctionsIcon />,
  },
  {
    type: 'rich_video',
    contentType: 'static',
    form: () => ToolArrayForms.VideoForm,
    block: () => ToolArrayBlocks.VideoBlock,
    icon: <VideoIcon />,
    verifyBlockConfig: (data) => !!data.src,
  },
  {
    type: 'audio',
    contentType: 'static',
    form: () => ToolArrayForms.AudioForm,
    block: () => ToolArrayBlocks.AudioBlock,
    icon: <AudioIcon />,
    verifyBlockConfig: (data) => !!data.src,
  },
  {
    type: 'callout',
    contentType: 'static',
    form: () => ToolArrayForms.CalloutForm,
    block: () => ToolArrayBlocks.CalloutBlock,
    icon: <CalloutIcon />,
  },
  {
    type: 'rich_list',
    contentType: 'static',
    form: () => ToolArrayForms.RichListForm,
    block: () => ToolArrayBlocks.RichListBlock,
    icon: <RichListIcon />,
  },
  {
    type: 'accordion_container',
    isEditable: true,
    contentType: 'container',
    form: () => ToolArrayForms.AccordionForm,
    block: () => ToolArrayBlocks.AccordionContainer,
    icon: <AccordionIcon />,
  },
  {
    type: 'tabbar_container',
    isEditable: true,
    contentType: 'container',
    form: () => ToolArrayForms.TabbarContainerForm,
    block: () => ToolArrayBlocks.TabbarContainer,
    icon: <TabbarIcon />,
  },
  {
    type: 'group_container',
    contentType: 'container',
    form: () => ToolArrayForms.GroupForm,
    block: () => ToolArrayBlocks.GroupContainer,
    isEditable: true,
    icon: <GroupIcon />,
    props: {
      blocks: [],
    },
  },
  {
    type: 'panel_container',
    contentType: 'container',
    form: () => ToolArrayForms.PanelForm,
    block: () => ToolArrayBlocks.PanelContainer,
    icon: <PanelIcon />,
    isEditable: true,
    fallback: 'group_container',
    hidden: ['panel'],
    props: {
      blocks: [],
    },
  },
  {
    type: 'column_container',
    contentType: 'container',
    form: () => ToolArrayForms.ColumnForm,
    block: () => ToolArrayBlocks.ColumnContainer,
    icon: <ColumnIcon />,
    isEditable: true,
    props: () => ({
      blocks: [createEmptyContainerBlock(), createEmptyContainerBlock()],
    }),
  },
  {
    type: 'slider_container',
    contentType: 'container',
    form: () => ToolArrayForms.SliderForm,
    block: () => ToolArrayBlocks.SliderContainer,
    isEditable: true,
    icon: <SliderIcon />,
    props: () => ({
      blocks: [createEmptyContainerBlock(), createEmptyContainerBlock()],
    }),
  },
  {
    type: 'react_widget',
    contentType: 'static',
    form: () => ToolArrayForms.ReactWidgetForm,
    block: () => ToolArrayBlocks.ReactWidget,
    icon: <ReactWidgetIcon />,
    access: {
      create: ['author', 'academy author'],
    },
  },
  {
    type: 'drag_and_drop',
    contentType: 'closed',
    form: () => ToolArrayForms.DragAndDropForm,
    block: () => ToolArrayBlocks.DragAndDropQuestion,
    icon: <DragAndDropIcon />,
    verifyBlockConfig: (data) =>
      !!data.question && data.options.length > 0 && data.inputs.length > 0,
  },
  {
    type: 'drag_to_sort',
    contentType: 'closed',
    form: () => ToolArrayForms.DragToSortForm,
    block: () => ToolArrayBlocks.DragToSortQuestion,
    icon: <DragToSortIcon />,
    verifyBlockConfig: (data) => !!data.question && data.options.length > 0,
  },
  {
    type: 'file_upload',
    contentType: 'open',
    form: () => ToolArrayForms.FileUploadForm,
    block: () => ToolArrayBlocks.FileUploadQuestion,
    icon: <FileUploadIcon />,
  },
  {
    type: 'single_choice',
    contentType: 'closed',
    form: () => ToolArrayForms.SingleChoiceForm,
    block: () => ToolArrayBlocks.SingleChoiceQuestion,
    icon: <SingleChoiceIcon />,
    verifyBlockConfig: (data) => !!data.question && data.options.length > 0,
  },
  {
    type: 'multiple_choice',
    contentType: 'closed',
    form: () => ToolArrayForms.MultipleChoiceForm,
    block: () => ToolArrayBlocks.MultipleChoiceQuestion,
    icon: <MultipleChoiceIcon />,
    verifyBlockConfig: (data) => !!data.question && data.options.length > 0,
  },
  {
    type: 'input',
    contentType: 'open',
    form: () => ToolArrayForms.InputForm,
    block: () => ToolArrayBlocks.InputQuestion,
    icon: <InputIcon />,
    verifyBlockConfig: (data) => !!data.question,
  },
  {
    type: 'textarea',
    contentType: 'open',
    form: () => ToolArrayForms.TextareaForm,
    block: () => ToolArrayBlocks.TextareaQuestion,
    icon: <TextareaIcon />,
    verifyBlockConfig: (data) => !!data.question,
  },
  {
    type: 'cards_widget',
    contentType: 'open',
    form: () => ToolArrayForms.CardsWidgetForm,
    block: () => ToolArrayBlocks.CardsWidgetQuestion,
    icon: <CardsWidgetIcon />,
    verifyBlockConfig: (data) => !!data.question && !!data.label,
  },
  {
    type: 'html_code_block',
    contentType: 'static',
    form: () => ToolArrayForms.HTMLCodeForm,
    block: () => ToolArrayBlocks.HTMLCodeBlock,
    icon: <HtmlBlockIcon />,
    access: {
      create: ['author', 'academy author'],
    },
    verifyBlockConfig: (data) => !!data.content,
  },
  {
    type: 'scorm_package',
    contentType: 'static',
    form: () => ToolArrayForms.ScormForm,
    block: () => ToolArrayBlocks.ScormBlock,
    icon: <FolderZipOutlined />,
    access: {
      create: ['author', 'academy author'],
    },
  },
  {
    type: 'lti_resource_link',
    contentType: 'static',
    form: () => ToolArrayForms.LtiResourceLinkBlockForm,
    block: () => ToolArrayBlocks.LtiResourceLinkBlock,
    icon: <Link />,
    access: {
      create: ['author', 'academy author'],
    },
  },
  {
    type: 'subtitle',
    contentType: 'static',
    form: () => ToolArrayForms.SubtitleForm,
    icon: 'S',
    hidden: true,
  },
  {
    // DEPRECATED. Use accordion_container instead
    type: 'accordion',
    contentType: 'container',
    hidden: true,
    form: () => ToolArrayForms.AccordionForm,
    block: () => ToolArrayBlocks.AccordionBlock,
    icon: <AccordionIcon />,
  },
  {
    // DEPRECATED. Use group_container instead
    type: 'block_container',
    contentType: 'container',
    form: () => ToolArrayForms.GroupForm,
    block: () => ToolArrayBlocks.GroupContainer,
    isEditable: true,
    icon: <GroupIcon />,
    hidden: true,
  },
];

type TToolObject = { [tool: string]: ITool };
export const ToolObject = ToolArray.reduce<TToolObject>(
  (output, tool) => ({
    ...output,
    [tool.type]: tool,
  }),
  {}
);
