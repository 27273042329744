import { LearningObject } from '@brainstud/academy-api/Types/Resources/LearningObject';
import { LearningObjectVariety } from '@brainstud/academy-api/Types/Resources/LearningObjectVariety';
import { IBlock } from '@brainstud/course-blocks/Blocks/Types';
import { v4 } from 'uuid';
import { createEmptyContainerBlock } from '../../Support/createEmptyContainerBlock';
import { flattenBlockList } from '../Provider/BlockActions';

/**
 * In versions 1-3 the thumbnail of a learning object was automatically added
 * to the first block. This default is removed, however, to be backwards compatible, it is automatically added
 * to the learning object variety.
 */
export function migrateToVersion4(
  content: IBlock[],
  learningObject?: LearningObject,
  variety?: LearningObjectVariety
): IBlock[] {
  const CONTENT_BLOCKS = ['rich_media', 'rich_title'];
  const CONTAINER_BLOCKS = [
    'group_container',
    'block_container',
    'slider_container',
    'column_container',
    'panel_container',
  ];
  const posterUrl =
    learningObject?.metadata?.media?.videoUrl ||
    learningObject?.metadata?.media?.posterUrl ||
    learningObject?.poster?.src;

  if (
    !learningObject ||
    // Only convert items that are created before 11 DECEMBER 2021
    new Date(learningObject.createdAt) > new Date(2021, 11, 12) ||
    // Only convert items that are not yet converted to version 4
    (variety?.version || 3) >= 4 ||
    !posterUrl
  ) {
    return content;
  }

  const flatBlockList = flattenBlockList(content);
  const firstContentBlockId = flatBlockList.findIndex(
    (item) => !CONTAINER_BLOCKS.includes(item.type)
  );
  const requiresPosterPrefix = CONTENT_BLOCKS.includes(
    flatBlockList[firstContentBlockId]?.type
  );

  // First contentful block is something else than rich_media or rich_title, no migration necessary.
  if (!requiresPosterPrefix) {
    return content;
  }

  const posterIsVideo =
    ((posterUrl?.includes('embed') || posterUrl?.includes('v=')) &&
      posterUrl?.includes('youtube')) || // link is youtube video
    posterUrl?.includes('vimeo'); // link is vimeo video

  const prefixBlock = (posterIsVideo
    ? {
        id: v4(),
        type: 'rich_video',
        src: posterUrl,
        title: 'Intro video',
      }
    : {
        id: v4(),
        type: 'rich_image',
        images: [
          {
            src: posterUrl,
            alt: '',
          },
        ],
      }) as unknown as IBlock;

  // Check if first block is without group_container. If so, it should group the prefix and first rich_media
  if (firstContentBlockId === 0) {
    return [
      createEmptyContainerBlock([prefixBlock, content[0]]),
      ...content.slice(1),
    ];
  }

  // Lastly, if the first contentful block is rich_media, and it is already in a container block, then it should add the prefix to the container
  const previousBlock = flatBlockList[firstContentBlockId - 1];
  if (CONTAINER_BLOCKS.includes(previousBlock.type)) {
    previousBlock.blocks = [prefixBlock, ...(previousBlock.blocks || [])];
  }

  return content;
}
